import { BoxProps, HStack, TextProps } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import React from "react";

interface ViewControlProps {
  label?: string;
  leftElement?: React.ReactNode;
  value?: React.ReactNode | null;
  children?: React.ReactNode;
  labelProps?: TextProps;
  valueProps?: TextProps;
}
export const ViewControl = ({
  label,
  labelProps = { flex: 1 },
  leftElement,
  value,
  children,
  valueProps = { flex: 3 },
  ...props
}: ViewControlProps & BoxProps) => {
  return (
    <HStack w="100%" color="cherryGrey.900" {...props}>
      {(label && (
        <Text alignSelf="flex-start" variant="view-label" {...labelProps}>
          {label}
        </Text>
      )) ||
        leftElement}
      {(value && (
        <Text variant="view-value" {...valueProps}>
          {value}
        </Text>
      )) ||
        children}
    </HStack>
  );
};
