import { useCallback, useEffect, useState } from "react";
import { ApiClient, ApiResult } from "../api/apiClient";
import { useApiClient } from "./useApiClient";
import { useDidUnmount } from "./useDidUnmount";

export type ApiRequestState<T> = {
  data: T | null;
  isLoading: boolean;
  error: string | null;
  statusCode: number | null;
  continuationToken?: string;
  result: ApiResult<T> | null;
};

export const useApiUpdateRequest = <TResponse, TArgs extends any[]>(
  fn: (
    apiClient: ApiClient,
    ...args: TArgs
  ) => Promise<ApiResult<TResponse>> | null,
  deps: any[]
) => {
  const didUnmount = useDidUnmount();
  const apiClient = useApiClient();

  const [apiState, setApiState] = useState<ApiRequestState<TResponse>>({
    data: null,
    isLoading: false,
    error: null,
    statusCode: null,
    result: null,
  });

  const request = useCallback(
    (...args: TArgs) => {
      setApiState((state) => ({ ...state, isLoading: true }));
      const requestPromise = fn(apiClient, ...args);

      if (!requestPromise) {
        return;
      }

      requestPromise
        .then((result) => {
          if (didUnmount.current) {
            return;
          }
          if (result.ok) {
            setApiState({
              isLoading: false,
              data: result.data,
              error: null,
              statusCode: result.statusCode,
              continuationToken: result.continuationToken,
              result,
            });
          } else {
            setApiState({
              isLoading: false,
              data: null,
              error: `HTTP ${result.statusCode} error`,
              statusCode: result.statusCode ?? null,
              result,
            });
          }
        })
        .catch((error) => {
          if (didUnmount.current) {
            return;
          }
          setApiState({
            isLoading: false,
            data: null,
            error: "Unknown error.",
            statusCode: null,
            result: null,
          });
        });
    },
    [didUnmount, apiClient, setApiState, ...deps]
  );

  return { ...apiState, request };
};
