import {
  Box,
  Stack,
  VStack,
  Divider,
  Center,
  Spinner,
  Image,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import DateUtil from "../../util/DateUtil";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { ViewControl } from "../ViewControl/ViewControl";

export const DigitalMembershipCardList = ({
  businessId,
  memberId,
}: {
  businessId: string;
  memberId: string;
}) => {
  const {
    items: cardList,
    isLoading,
    isLoadingPage,
    fetchNextPage,
    error,
  } = usePaginatedApiRequest(
    (apiClient) => apiClient.getDigitalMembershipCardList(businessId, memberId),
    (apiClient, continuationToken) =>
      apiClient.getDigitalMembershipCardList(
        businessId,
        memberId,
        continuationToken
      ),
    [businessId, memberId]
  );

  const cardListView = useMemo(() => {
    return cardList?.map((card, cardIdx) => (
      <>
        <Stack
          w="100%"
          direction={["column", "row"]}
          p={4}
          spacing={12}
          key={cardIdx}
        >
          <Box alignSelf="center" flexShrink={1}>
            {card?.PassPreviewImageUrl && (
              <Image
                maxH="200px"
                maxW="350px"
                objectFit="contain"
                src={card?.PassPreviewImageUrl}
                borderRadius="lg"
                boxShadow="lg"
              />
            )}
          </Box>
          <Box alignSelf="center" flex={1}>
            <VStack align="start" w="100%" spacing={6}>
              <ViewControl label="Pass Type:" value={card.PassType} />

              <ViewControl
                label="Invitation Id:"
                value={card.InvitationId ?? "-"}
              />

              <ViewControl
                label="Created:"
                value={DateUtil.getLocalDateFormatFromString(
                  card.Meta?.DateCreated
                )}
              />

              {card.PromotionId && (
                <ViewControl label="Promotion Id:" value={card.PromotionId} />
              )}

              {card.CouponCode && (
                <ViewControl label="Coupon Code:" value={card.CouponCode} />
              )}
            </VStack>
          </Box>
        </Stack>
        {cardIdx != cardList.length - 1 && (
          <Divider key={`${cardIdx}-divider`} />
        )}
      </>
    ));
  }, [cardList, isLoading]);

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (!cardList.length) {
    return (
      <Center>
        <Text fontStyle="italic">No Digital Membership Cards found</Text>
      </Center>
    );
  }

  return (
    <>
      {cardListView}
      {fetchNextPage && (
        <Box p={4}>
          {!isLoadingPage && (
            <Button
              colorScheme="cherryButton"
              onClick={fetchNextPage}
              size="sm"
            >
              Load more...
            </Button>
          )}
          {isLoadingPage && <Spinner />}
        </Box>
      )}
      {error != null && (
        <ErrorMessage>
          An error was encountered while getting the cards.
        </ErrorMessage>
      )}
    </>
  );
};
