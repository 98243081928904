import { Box, Text } from "@chakra-ui/react";
import React from "react";

type StepCircleProps = {
  seq: number;
  title: string;
  isActive: boolean;
  isCompleted: boolean;
  isLast: boolean;
};

export const StepCircle = ({
  seq,
  title,
  isActive,
  isCompleted,
  isLast,
}: StepCircleProps) => {
  return (
    <Box position="relative" textAlign="center">
      <Box
        width="30px"
        height="30px"
        borderRadius="full"
        bg={isActive || isCompleted ? "cherryUi.600" : "white"}
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        color={isActive || isCompleted ? "white" : "cherryUi.600"}
        fontWeight="semibold"
        fontSize="sm"
        transition="all 0.2s"
        borderColor="cherryUi.600"
        borderWidth="1px"
      >
        {seq}
      </Box>
      {!isLast && (
        <Box
          position="absolute"
          top="calc(20% + 3px)"
          right="calc(-100% + 3px)"
          height="2px"
          width="calc(100% + 30px)"
          bg={
            isCompleted
              ? "cherryUi.600"
              : isActive
              ? "cherryUi.600"
              : "gray.300"
          }
        ></Box>
      )}
      <Text fontSize="xs" color="cherryUi.600" mt="0.25rem">
        {title}
      </Text>
    </Box>
  );
};
