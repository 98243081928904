import {
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Box,
  Image,
  Heading,
  Center,
  Spinner,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tooltip,
  Stack,
  VStack,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiClient } from "../../hooks/useApiClient";
import { CherryPayApi } from "../../api/models";
import DateUtil from "../../util/DateUtil";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { BoxShareIcon, CardIcon, ZapIcon } from "../../styles/icons";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { Link as ReactRouterLink } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ViewControl } from "../../components/ViewControl/ViewControl";

enum CardAccountStatus {
  Active = 1,
  Inactive,
  LostStolen,
  Unknown,
}

export const CardDetail = () => {
  const business = useBusinessContext();
  const [cardDetail, setCardDetail] =
    useState<CherryPayApi.CherryPayCardCompact>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCardStatusLoading, setIsCardStatusLoading] =
    useState<boolean>(false);
  const [cardStatus, setCardStatus] =
    useState<CherryPayApi.CherryPayCardAccountStatus>();
  const [cardStatusError, setCardStatusError] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [accStatus, setAccStatus] = useState<CardAccountStatus>();
  const [actionClicked, setActionClicked] = useState<number>(0);
  const [changeStatusError, setChangeStatusError] = useState<string>();
  const params = useParams();
  var apiClient = useApiClient();

  const {
    items: transactions,
    isLoading: isLoadingTransaction,
    isLoadingPage: isLoadingTransactionPage,
    error: isTransactionError,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (client) =>
      client.getCardTransactionSummary(
        business.BusinessId,
        params.cardId ?? "",
        10
      ),
    (client, continuationToken) =>
      client.getCardTransactionSummary(
        business.BusinessId,
        params.cardId ?? "",
        10,
        continuationToken
      ),
    []
  );

  const getCardDetail = async (cardId: string) => {
    setIsLoading(true);
    const card = await apiClient.getCardDetailById(business.BusinessId, cardId);
    if (card.ok) {
      setCardDetail(card.data);
    } else {
      setErrorMessage(card.message);
    }
    setIsLoading(false);
  };

  const setCardAccStatus = (
    status: CherryPayApi.CherryPayCardAccountStatus
  ) => {
    setCardStatus(status);
    if (status.Status === "Active") {
      setAccStatus(CardAccountStatus.Active);
    } else if (status.Status === "Inactive") {
      setAccStatus(CardAccountStatus.Inactive);
    } else if (status.Status === "LostStolen") {
      setAccStatus(CardAccountStatus.LostStolen);
    } else {
      setAccStatus(CardAccountStatus.Unknown);
    }
  };

  const convertStatusDisplay = (cardAccStatus: CardAccountStatus): string => {
    if (cardAccStatus === CardAccountStatus.Active) {
      return "Active";
    } else if (cardAccStatus === CardAccountStatus.Inactive) {
      return "Inactive";
    } else if (cardAccStatus === CardAccountStatus.LostStolen) {
      return "Lost or Stolen";
    } else {
      return "Unknown";
    }
  };

  const convertStatusToString = (cardAccStatus: CardAccountStatus): string => {
    switch (cardAccStatus) {
      case CardAccountStatus.Active:
        return "active";
      case CardAccountStatus.Inactive:
        return "inactive";
      case CardAccountStatus.LostStolen:
        return "lost-stolen";
      default:
        return "unknown";
    }
  };

  const changeStatusToActive = () => {
    setAccStatus(CardAccountStatus.Active);
    setActionClicked((prev) => prev + 1);
  };

  const changeStatusToInactive = () => {
    setAccStatus(CardAccountStatus.Inactive);
    setActionClicked((prev) => prev + 1);
  };

  const changeStatusToLostStolen = () => {
    setAccStatus(CardAccountStatus.LostStolen);
    setActionClicked((prev) => prev + 1);
  };

  const getCardAccountStatus = async (cardId: string) => {
    setIsCardStatusLoading(true);
    const statusResult = await apiClient.getCardAccountStatus(
      business.BusinessId,
      cardId
    );
    if (statusResult.ok) {
      setCardStatus(statusResult.data);
      setCardAccStatus(statusResult.data);
    } else {
      setCardStatusError(statusResult.message);
    }
    setIsCardStatusLoading(false);
  };

  useEffect(() => {
    if (params.cardId) {
      getCardDetail(params.cardId);
      getCardAccountStatus(params.cardId);
    }
  }, []);

  useEffect(() => {
    const postStatus = async () => {
      setChangeStatusError(undefined);
      if (params.cardId && accStatus) {
        if (accStatus === CardAccountStatus.Unknown) return;
        setIsCardStatusLoading(true);
        const result = await apiClient.changeCardAccountStatus(
          business.BusinessId,
          params.cardId,
          convertStatusToString(accStatus)
        );
        if (result.ok) {
          setCardAccStatus(result.data);
        } else {
          setChangeStatusError(result.message);
          getCardAccountStatus(params.cardId);
        }
        setIsCardStatusLoading(false);
      }
    };

    postStatus();
  }, [actionClicked]);

  const transactionRows = useMemo(
    () =>
      transactions?.map((transaction) => {
        const balanceCurrency = `Currency: ${transaction.RunningBalanceAmountCurrency}`;
        const amountCurrency = `Currency: ${transaction.AmountCurrency}`;
        const typeTooltip = `Type: ${transaction.Type}`;
        const balance = transaction.RunningBalanceAmount.toLocaleString(
          "en-AU",
          {
            style: "currency",
            currency: transaction.RunningBalanceAmountCurrency ?? "AUD",
          }
        );
        const amount = transaction.Amount.toLocaleString("en-AU", {
          style: "currency",
          currency: transaction.AmountCurrency ?? "AUD",
        });
        return (
          <Tr key={transaction.TransactionId}>
            <Td>{transaction.TransactionId}</Td>
            <Td>
              <Tooltip label={typeTooltip} aria-label="typeTooltip">
                {transaction.TypeDescription}
              </Tooltip>
            </Td>
            <Td>
              {DateUtil.getLocalDateFormatFromString(transaction.OccurredAt)}
            </Td>
            <Td>{transaction.ParentId ?? "-"}</Td>
            <Td>
              <Tooltip label={amountCurrency} aria-label="amountTooltip">
                {amount}
              </Tooltip>
            </Td>
            <Td>
              <Tooltip label={balanceCurrency} aria-label="balanceTooltip">
                {balance}
              </Tooltip>
            </Td>
            <Td>{transaction.Reference}</Td>
          </Tr>
        );
      }),
    [transactions]
  );

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={ReactRouterLink}
              to={`/businesses/${business.BusinessId}/cards`}
            >
              Cards
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Card Detail</PageHeading.Title>
      </PageHeading>

      <PageContent>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {!errorMessage && (
          <Card w="100%">
            <Stack
              w="100%"
              alignItems="start"
              spacing={{ base: 4, xl: 24 }}
              bgColor="gray.600"
              py={5}
              px={8}
              borderTopStartRadius="3xl"
              borderTopEndRadius="3xl"
              direction={{ base: "column", xl: "row" }}
              boxShadow="md"
            >
              <VStack spacing="1" alignItems="flex-start">
                <Text fontSize="2xl" color="white" fontWeight="bold">
                  {cardDetail?.ExternalAccountId}
                </Text>
                <Text variant="small-label">
                  {cardDetail?.CardProgramDescription}
                </Text>
              </VStack>

              <VStack
                spacing="1"
                alignSelf={{ base: "flex-start", xl: "flex-end" }}
                alignItems={{ base: "flex-start", xl: "center" }}
              >
                <Text fontSize="xl" color="white" fontWeight="bold">
                  {(cardDetail?.FirstName && cardDetail.FirstName + " ") || ""}{" "}
                  {cardDetail?.LastName ?? ""}
                </Text>
                <Text variant="small-label">Card Holder</Text>
              </VStack>

              <VStack
                spacing="1"
                alignSelf={{ base: "flex-start", xl: "flex-end" }}
                alignItems={{ base: "flex-start", xl: "center" }}
              >
                <Text fontSize="xl" color="white" fontWeight="bold">
                  {cardDetail?.MobileNumber ?? "-"}
                </Text>
                <Text variant="small-label">Mobile No</Text>
              </VStack>

              <HStack
                flex="1"
                justifyContent="end"
                position="relative"
                zIndex={100}
                alignSelf="center"
              >
                {isCardStatusLoading && <Spinner />}
                {!isCardStatusLoading && (
                  <Menu placement="bottom-end">
                    <MenuButton
                      leftIcon={<ZapIcon />}
                      colorScheme="cherryButton"
                      as={Button}
                      aria-label="Card Actions"
                    >
                      Card Actions
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        isDisabled={
                          !(
                            accStatus === CardAccountStatus.Inactive ||
                            accStatus === CardAccountStatus.LostStolen
                          )
                        }
                        icon={<CardIcon />}
                        onClick={changeStatusToActive}
                      >
                        Set to Active
                      </MenuItem>
                      <MenuItem
                        isDisabled={accStatus !== CardAccountStatus.Active}
                        icon={<CardIcon />}
                        onClick={changeStatusToInactive}
                      >
                        Set to Inactive
                      </MenuItem>
                      <MenuItem
                        isDisabled={accStatus !== CardAccountStatus.Active}
                        icon={<CardIcon />}
                        onClick={changeStatusToLostStolen}
                      >
                        Set to Lost/Stolen
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </HStack>
            </Stack>

            <Box
              boxShadow="md"
              borderRadius="lg"
              px={8}
              pt={8}
              pb={10}
              bgColor="white"
              w="100%"
            >
              <Stack
                w="100%"
                align="start"
                spacing={2}
                direction={["column", "row"]}
              >
                <VStack align="start" w="100%" flexGrow={1}>
                  <Heading variant="view-heading">Card Detail</Heading>
                  <VStack align="start" w="100%" spacing={6} pb={10}>
                    <ViewControl
                      label="Card Type:"
                      value={cardDetail?.CardProgramType ?? "-"}
                    />

                    <ViewControl
                      label="Program Name:"
                      value={cardDetail?.CardProgramDescription ?? "-"}
                    />

                    <ViewControl label="Member:">
                      <Box flex={3}>
                        {cardDetail?.MemberId && (
                          <Link
                            as={ReactRouterLink}
                            to={`/businesses/${business.BusinessId}/members/${cardDetail?.MemberId}`}
                            target="_blank"
                          >
                            <Text variant="view-value">
                              View Member <BoxShareIcon />
                            </Text>
                          </Link>
                        )}
                        {!cardDetail?.MemberId && (
                          <Text variant="view-value">-</Text>
                        )}
                      </Box>
                    </ViewControl>

                    <ViewControl
                      label="Email Address:"
                      value={cardDetail?.EmailAddress ?? "-"}
                    />

                    <ViewControl
                      label="Offer Expiry Date:"
                      value={
                        cardDetail?.OfferExpiryDate
                          ? DateUtil.getLocalDateFormatFromString(
                              cardDetail?.OfferExpiryDate
                            )
                          : "-"
                      }
                    />

                    <ViewControl
                      label="Redeemed Date:"
                      value={
                        cardDetail?.RedeemedDate
                          ? DateUtil.getLocalDateFormatFromString(
                              cardDetail?.RedeemedDate
                            )
                          : "-"
                      }
                    />
                  </VStack>
                </VStack>

                <VStack align="start" w="100%" flexGrow={1}>
                  <Heading variant="view-heading">Account Status</Heading>
                  <VStack align="start" w="100%" spacing={6} pb={10}>
                    <ViewControl label="Balance:">
                      <Box flex={3}>
                        {isCardStatusLoading && <Spinner />}
                        {cardStatusError && (
                          <Box w="400px">
                            <ErrorMessage>{cardStatusError}</ErrorMessage>
                          </Box>
                        )}
                        {!isCardStatusLoading && !cardStatusError && (
                          <Text variant="view-value">
                            {(cardStatus?.Balance.Amount != null &&
                              "$" + cardStatus.Balance.Amount) ||
                              "-"}
                          </Text>
                        )}
                      </Box>
                    </ViewControl>

                    <ViewControl
                      label="Card Number:"
                      value={cardStatus?.MaskedCardNumber}
                    />

                    <ViewControl
                      label="Expiry Date:"
                      value={DateUtil.getLocalDateFormatFromString(
                        cardStatus?.PlasticExpiryDate
                      )}
                    />

                    <ViewControl
                      label="Status:"
                      value={
                        accStatus && accStatus !== CardAccountStatus.Unknown
                          ? convertStatusDisplay(accStatus)
                          : cardStatus?.Status
                      }
                    />
                  </VStack>
                </VStack>

                <VStack align="start" w="100%" flexShrink={2}>
                  {cardDetail?.CardArtUrl && (
                    <Image
                      maxH="200px"
                      maxW="350px"
                      objectFit="contain"
                      src={cardDetail.CardArtUrl}
                      borderRadius="lg"
                      boxShadow="lg"
                    />
                  )}
                </VStack>
              </Stack>
            </Box>
          </Card>
        )}
        <Box w="100%" pt={5}>
          {!transactions && isLoadingTransaction && (
            <Center>
              <Spinner margin="4" />
            </Center>
          )}
          {transactions && transactions.length > 0 && (
            <>
              <Heading as="h2" size="md" mb={3}>
                Recent Transactions
              </Heading>
              <TableContainer alignSelf="start" width="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Transaction ID</Th>
                      <Th>Type</Th>
                      <Th>Occurred At</Th>
                      <Th>Parent ID</Th>
                      <Th>Value</Th>
                      <Th>Balance</Th>
                      <Th>Reference</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{transactionRows}</Tbody>
                </Table>
              </TableContainer>
            </>
          )}
          {fetchNextPage && (
            <Box mt={4}>
              {!isLoadingTransactionPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {isLoadingTransactionPage && <Spinner />}
            </Box>
          )}
          {isTransactionError != null && (
            <ErrorMessage>{isTransactionError}</ErrorMessage>
          )}
        </Box>
      </PageContent>
    </>
  );
};
