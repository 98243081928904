import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/form-control";
import { Tooltip } from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { InfoIcon } from "../../../styles/icons";

export interface BaseFieldProps {
  name: string;
  label: string;
  helperText?: string;
  id?: string;
  infoText?: string;
}

export interface FieldWrapperProps extends BaseFieldProps {
  children: (props: FieldProps<any, any>) => JSX.Element;
}

const getNestedFormError = (errors: any, name: string) => {
  const nameParts = name.split(".");

  const error: string | undefined = nameParts.reduce((error, namePart) => {
    if (typeof error === "object" && error[namePart]) {
      return error[namePart];
    } else {
      if (typeof error === "string") {
        return error;
      } else {
        return undefined;
      }
    }
  }, errors);

  return error;
};

/** Provides label and error message rendering */
export const FieldWrapper = ({
  name,
  label,
  id: idProp,
  helperText,
  infoText,
  children,
}: FieldWrapperProps) => {
  const id = idProp ?? `${name}-field`;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<any, any>) => (
        <FormControl
          isInvalid={!!getNestedFormError(fieldProps.form.errors, name)}
        >
          {infoText && (
            <FormLabel htmlFor={id}>
              {label}{" "}
              <Tooltip label={infoText} placement="right">
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>
            </FormLabel>
          )}
          {!infoText && <FormLabel htmlFor={id}>{label}</FormLabel>}
          {!!helperText && (
            <FormHelperText marginBottom="2">{helperText}</FormHelperText>
          )}
          {children(fieldProps)}
          <FormErrorMessage>
            {getNestedFormError(fieldProps.form.errors, name)}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
