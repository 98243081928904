import {
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  VStack,
  Text,
  Center,
  Spinner,
  Box,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Card } from "../../components/Card/Card";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { CheckCircleIcon, XCircleIcon } from "../../styles/icons";
import DateUtil from "../../util/DateUtil";

export const MembershipRequestDetail = () => {
  const business = useBusinessContext();
  const { sessionId } = useParams();
  const {
    data: membershipRequest,
    error,
    isLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getMembershipRequest(business.BusinessId, sessionId!),
    [sessionId]
  );
  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/membership-requests`}
            >
              Membership Requests
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/membership-requests/${sessionId}`}
            >
              Membership Request Detail
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Membership Request Detail</PageHeading.Title>
      </PageHeading>
      <PageContent>
        {isLoading && !membershipRequest && (
          <Center>
            <Spinner />
          </Center>
        )}
        {/* {!isLoading && membershipRequest && (
          <VStack w="100%" alignItems="start">
            <Card>
              <Heading as="h4" size="md">
                Session ID
              </Heading>
              <Text fontSize="md">{membershipRequest.SessionId ?? "-"}</Text>
            </Card>
            <Card>
              <Heading as="h4" size="md">
                Request Submitted Date
              </Heading>
              <Text fontSize="md">
                {DateUtil.getLocalDateFormatFromString(
                  membershipRequest.RequestSubmittedDateTime
                ) ?? "-"}
              </Text>
            </Card>
            <Card>
              <Heading as="h4" size="md">
                Membership Creation Date
              </Heading>
              <Text fontSize="md">
                {DateUtil.getLocalDateFormatFromString(
                  membershipRequest.BackendMembershipCreationDateTime
                ) ?? "-"}
              </Text>
            </Card>
            {membershipRequest.FinishMessage && (
              <Card>
                <Heading as="h4" size="md">
                  Finish Message
                </Heading>
                <Text fontSize="md">
                  {membershipRequest.FinishMessage ?? "-"}
                </Text>
              </Card>
            )}
            {membershipRequest.ErrorMessage && (
              <Card>
                <Heading as="h4" size="md">
                  Error Message
                </Heading>
                <Text fontSize="md">
                  {membershipRequest.ErrorMessage ?? "-"}
                </Text>
              </Card>
            )}
            {membershipRequest.PaymentErrorMessage && (
              <Card>
                <Heading as="h4" size="md">
                  Payment Error Message
                </Heading>
                <Text fontSize="md">
                  {membershipRequest.PaymentErrorMessage ?? "-"}
                </Text>
              </Card>
            )}
            <Divider mt={2} />
            <Heading as="h3" size="lg" mt={3}>
              Submission Finalisation
            </Heading>
            <Card>
              <Heading as="h4" size="md">
                Submission Accepted
              </Heading>
              {membershipRequest.SubmissionFinalisation?.SubmissionAccepted ===
                null ||
              membershipRequest.SubmissionFinalisation?.SubmissionAccepted ===
                undefined ? (
                "-"
              ) : membershipRequest.SubmissionFinalisation
                  ?.SubmissionAccepted ? (
                <CheckCircleIcon color="whatsapp.500" />
              ) : (
                <XCircleIcon color="red" />
              )}
            </Card>
            {membershipRequest.SubmissionFinalisation?.AcceptanceDetails && (
              <Card>
                <Heading as="h4" size="md">
                  Acceptance Details
                </Heading>
                {membershipRequest.SubmissionFinalisation?.AcceptanceDetails ??
                  "-"}
              </Card>
            )}
            {membershipRequest.SubmissionFinalisation?.RejectionNoteByUser && (
              <Card>
                <Heading as="h4" size="md">
                  Rejection Note By User
                </Heading>
                {membershipRequest.SubmissionFinalisation
                  ?.RejectionNoteByUser ?? "-"}
              </Card>
            )}
            <Card>
              <Heading as="h4" size="md">
                Is Finalised
              </Heading>
              {membershipRequest.SubmissionFinalisation?.IsFinalised ? (
                <CheckCircleIcon color="whatsapp.500" />
              ) : (
                <XCircleIcon color="red" />
              )}
            </Card>
            {membershipRequest.SubmissionFinalisation?.IsFinalised ? (
              <>
                <Card>
                  <Heading as="h4" size="md">
                    Finalised By User
                  </Heading>
                  <Text fontSize="md">
                    {membershipRequest.SubmissionFinalisation
                      ?.FinalisedByUser ?? "-"}
                  </Text>
                </Card>
                <Card>
                  <Heading as="h4" size="md">
                    Finalised By User ID
                  </Heading>
                  <Text fontSize="md">
                    {membershipRequest.SubmissionFinalisation
                      ?.FinalisedByUserId ?? "-"}
                  </Text>
                </Card>
                <Card>
                  <Heading as="h4" size="md">
                    Finalised Date
                  </Heading>
                  <Text fontSize="md">
                    {DateUtil.getLocalDateFormatFromString(
                      membershipRequest.SubmissionFinalisation?.FinalisedDate
                    ) ?? "-"}
                  </Text>
                </Card>
              </>
            ) : (
              ""
            )}
          </VStack>
        )} */}
      </PageContent>
    </>
  );
};
