import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@chakra-ui/layout";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";

import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useApiClient } from "../../hooks/useApiClient";
import { sortBy } from "lodash-es";
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useToast } from "../../hooks/useToast";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { GoogleWalletEditor } from "../../components/PassEditor/GoogleWalletEditor";

export interface PassConfig {
  passImages: any[];
  data: any;
}

export const PassEditor = () => {
  const apiClient = useApiClient();
  const { showErrorToast, showSuccessToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const { businessId, passConfigurationId } = useParams();

  const [passConfig, setPassConfig] = useState<PassConfig | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSoftLoading, setIsSoftLoading] = useState<boolean>(false);

  const [allTokens, setAllTokens] = useState<string[]>([]);

  const loadTokens = async () => {
    try {
      const tokens = await apiClient.getPassTokens(
        businessId!,
        passConfigurationId!
      );
      setAllTokens(tokens);
    } catch (e) {
      console.error(e);
    }
  };

  const loadPass = useCallback(
    async (skipImages = false) => {
      setIsSoftLoading(true);

      let newConfig: PassConfig = {
        passImages: passConfig?.passImages || [],
        data: passConfig?.data || {},
      };

      const configData = await apiClient.getPassConfig(
        businessId!,
        passConfigurationId!
      );
      console.log(configData);

      if (!configData.ok) {
        showErrorToast("Unable to load pass.");
        return;
      } else {
        newConfig.data = configData.data;
      }

      if (!skipImages) {
        const results = await apiClient.getPassImages(
          businessId!,
          passConfigurationId!
        );
        console.log(results);
        newConfig.passImages = results;
      }

      setPassConfig(processPassConfig(newConfig));
      setIsLoading(false);
      setIsSoftLoading(false);
    },
    [passConfig]
  );

  const processPassConfig = (config: PassConfig): PassConfig => {
    const passData = { ...config.data };

    if (passData.GooglePayPassCardFrontLabels) {
      passData.ProcessedGooglePayPassCardFrontLabels = Object.keys(
        passData.GooglePayPassCardFrontLabels
      ).map((position) => {
        return {
          ...passData.GooglePayPassCardFrontLabels[position],
          position,
        };
      });
    }

    if (passData.GooglePassMessages) {
      const eachMemberFields = passData.GooglePassMessages?.EachMember || [];
      const allMembersFields = passData.GooglePassMessages?.AllMembers || [];
      eachMemberFields.map((f: any) => {
        f.isEachMember = true;
      });
      allMembersFields.map((f: any) => {
        f.isEachMember = false;
      });
      passData.ProcessedGooglePassMessages = [
        ...allMembersFields,
        { isDivider: true },
        ...eachMemberFields,
      ];
    }

    if (passData.GooglePassLinks) {
      const eachMemberFields = passData.GooglePassLinks?.EachMember || [];
      const allMembersFields = passData.GooglePassLinks?.AllMembers || [];
      passData.ProcessedGooglePassLinks = [
        ...allMembersFields,
        // { isDivider: true },
        // ...eachMemberFields,
      ];

      passData.ProcessedGooglePassLinks = sortBy(
        passData.ProcessedGooglePassLinks,
        (item: any) => {
          const sortOrder = ["tel:", "mailto:", "https:"];
          let sortIdx = 100;
          sortOrder.map((sort) => {
            if (item.UriValue.indexOf(sort) > -1) {
              sortIdx = sortOrder.indexOf(sort);
            }
          });
          return sortIdx;
        }
      );
    }

    return { data: passData, passImages: config.passImages };
  };

  const unprocessPassConfig = (config: PassConfig): PassConfig => {
    const passData = { ...config.data, isUnprocessed: true };

    if (passData.ProcessedGooglePayPassCardFrontLabels) {
      const GooglePayPassCardFrontLabels: any = {};
      passData.ProcessedGooglePayPassCardFrontLabels.map((field: any) => {
        if (field.position == "Left") {
          delete field.position;
          GooglePayPassCardFrontLabels.Left = field;
        }
        if (field.position == "Right") {
          delete field.position;
          GooglePayPassCardFrontLabels.Right = field;
        }
      });
      passData.GooglePayPassCardFrontLabels = GooglePayPassCardFrontLabels;
      delete passData.ProcessedGooglePayPassCardFrontLabels;
    }

    if (passData.ProcessedGooglePassMessages) {
      const allTokenKeys = Object.keys(allTokens);

      let eachMemberFields: any[] = [];
      let allMembersFields: any[] = [];

      passData.ProcessedGooglePassMessages.map((msg: any) => {
        if (msg.isDivider) return;

        let isHavingToken = false;
        allTokenKeys.map((tokenKey: string) => {
          isHavingToken =
            isHavingToken ||
            msg.Label.indexOf(tokenKey) > -1 ||
            msg.Value.indexOf(tokenKey) > -1;
        });

        delete msg.isEachMember;
        if (isHavingToken) {
          eachMemberFields.push(msg);
        } else {
          allMembersFields.push(msg);
        }
      });

      passData.GooglePassMessages.EachMember = eachMemberFields;
      passData.GooglePassMessages.AllMembers = allMembersFields;
      delete passData.ProcessedGooglePassMessages;
    }

    if (passData.ProcessedGooglePassLinks) {
      const allTokenKeys = Object.keys(allTokens);

      let eachMemberFields: any[] = [];
      let allMembersFields: any[] = [];

      passData.ProcessedGooglePassLinks.map((msg: any) => {
        if (msg.isDivider) return;

        let isHavingToken = false;
        allTokenKeys.map((tokenKey: string) => {
          isHavingToken =
            isHavingToken ||
            msg.Description.indexOf(tokenKey) > -1 ||
            msg.UriValue.indexOf(tokenKey) > -1;
        });
        if (isHavingToken) {
          eachMemberFields.push(msg);
        } else {
          allMembersFields.push(msg);
        }
      });

      passData.GooglePassLinks.EachMember = eachMemberFields;
      passData.GooglePassLinks.AllMembers = allMembersFields;
      delete passData.ProcessedGooglePassLinks;
    }

    return { data: passData, passImages: config.passImages };
  };

  useEffect(() => {
    loadPass();
    loadTokens();
  }, []);

  useEffect(() => {
    console.log("passConfig", passConfig);
  }, [passConfig]);

  const TabListAttributes = {
    borderBottom: "none",
    marginBottom: "2px",
    justifyContent: "center",
  };

  const TabPanelAttributes = {
    padding: 0,
  };

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          {/* <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/configuration`}
            >
              Configuration
            </BreadcrumbLink>
          </BreadcrumbItem> */}
        </BusinessBreadcrumbs>
        <PageHeading.Title>Pass Builder</PageHeading.Title>
      </PageHeading>
      {isSoftLoading && (
        <Box position="absolute" bg="rgba(0,0,0,0.4)">
          <LoadingIndicator />
        </Box>
      )}
      <PageContent>
        {!isLoading && passConfig && (
          <Box w="100%">
            <Tabs colorScheme="cherryUi">
              <TabList {...TabListAttributes}>
                <Tab _focus={{ outline: 0 }}>Apple Wallet</Tab>
                <Tab _focus={{ outline: 0 }}>Google Wallet</Tab>
              </TabList>

              <TabPanels>
                <TabPanel {...TabPanelAttributes}>
                  <Box>
                    <GoogleWalletEditor
                      unprocessPassConfig={unprocessPassConfig}
                      tokens={allTokens}
                      setIsSoftLoading={setIsSoftLoading}
                      reloadPass={loadPass}
                      style="apple"
                      config={passConfig}
                    />
                  </Box>
                </TabPanel>
                <TabPanel {...TabPanelAttributes}>
                  <Box>
                    <GoogleWalletEditor
                      unprocessPassConfig={unprocessPassConfig}
                      tokens={allTokens}
                      setIsSoftLoading={setIsSoftLoading}
                      reloadPass={loadPass}
                      style="google"
                      config={passConfig}
                    />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}

        {isLoading && <LoadingIndicator />}

        {confirmationElement}
      </PageContent>
    </>
  );
};
