import { Box, VStack, Text, Center, Spinner, HStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { CherryPayApi } from "../../api/models";
import { useApiRequest } from "../../hooks/useApiRequest";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

interface ReportSummaryProps {
  businessId: string;
}

interface FundsAvailableProps {
  status: CherryPayApi.CherryPayDisbursementAccountStatus | null;
}

interface KpiProps {
  cardsKpi: CherryPayApi.CherryPayCardsKpiAll | null;
}

const FundsAvailable = (props: FundsAvailableProps) => {
  if (!props.status) return <></>;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const fundsAvailable = props.status?.Balance.Amount.toLocaleString("en-au", {
    style: "currency",
    currency: props.status?.Balance.CurrencyCode ?? "AUD",
  });
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box
      position="relative"
      bgColor={isHovered ? "gray.200" : "white"}
      minW="320px"
      h="150px"
      pt="20px"
      borderRadius="md"
      alignContent="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <VStack>
        <Text fontSize="300%" color="cherryUi.600">
          {fundsAvailable}
        </Text>
        <Text>
          {isHovered
            ? `Account ID: ${props.status.ExternalAccountId}`
            : "Funds Available"}
        </Text>
      </VStack>
    </Box>
  );
};

const CardIssued = (props: KpiProps) => {
  if (!props.cardsKpi) return <></>;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box
      position="relative"
      bgColor={isHovered ? "gray.200" : "white"}
      minW="250px"
      h="150px"
      pt="20px"
      borderRadius="md"
      alignContent="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <VStack>
        <Text fontSize="300%" color="cherryUi.600">
          {props.cardsKpi?.CardIssuedCount}
        </Text>
        <Text>
          {isHovered
            ? `Last Issued: ${props.cardsKpi.CardLastIssuedDaysAgo} days ago`
            : "Cards Issued"}
        </Text>
      </VStack>
    </Box>
  );
};

const InitialCardsFunds = (props: KpiProps) => {
  if (!props.cardsKpi) return <></>;
  const initialCardsFunds =
    props.cardsKpi?.CardInitialFundValue?.toLocaleString("en-au", {
      style: "currency",
      currency: "AUD",
    });
  return (
    <Box
      position="relative"
      bgColor="white"
      minW="250px"
      h="150px"
      pt="20px"
      borderRadius="md"
      alignContent="center"
    >
      <VStack>
        <Text fontSize="300%" color="cherryUi.600">
          {initialCardsFunds}
        </Text>
        <Text>Initial Card Funds</Text>
      </VStack>
    </Box>
  );
};

const FundsTransferred = (props: KpiProps) => {
  if (!props.cardsKpi) return <></>;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const fundsTransferred =
    props.cardsKpi?.FundsTransferredValue?.toLocaleString("en-au", {
      style: "currency",
      currency: "AUD",
    });
  return (
    <Box
      position="relative"
      bgColor={isHovered ? "gray.200" : "white"}
      minW="250px"
      h="150px"
      pt="20px"
      borderRadius="md"
      alignContent="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <VStack>
        <Text fontSize="300%" color="cherryUi.600">
          {fundsTransferred}
        </Text>
        <Text>
          {isHovered
            ? `${props.cardsKpi?.FundsTransferredCount} transfers / ${props.cardsKpi?.FundsLastTransferredDaysAgo} days ago`
            : "Funds Transferred"}
        </Text>
      </VStack>
    </Box>
  );
};

const PointsTransferred = (props: KpiProps) => {
  if (!props.cardsKpi) return <></>;
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const pointsTransferred =
    props.cardsKpi?.PointsTransferredValue.toLocaleString("en-au", {
      style: "currency",
      currency: "AUD",
    });
  return (
    <Box
      position="relative"
      bgColor={isHovered ? "gray.200" : "white"}
      minW="250px"
      h="150px"
      pt="20px"
      borderRadius="md"
      alignContent="center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <VStack>
        <Text fontSize="300%" color="cherryUi.600">
          {pointsTransferred}
        </Text>
        <Text>
          {isHovered
            ? `${props.cardsKpi?.PointsTransferredCount} transfers / ${props.cardsKpi?.PointsLastTransferredDaysAgo} days ago`
            : "Points Transferred"}
        </Text>
      </VStack>
    </Box>
  );
};

export const ReportSummary = (props: ReportSummaryProps) => {
  const {
    data: cardsKpi,
    error: cardsKpiError,
    isLoading: cardsKpiLoading,
  } = useApiRequest(
    (apiClient) => apiClient.getCardsKpiAll(props.businessId),
    [props.businessId]
  );

  const {
    data: disbursementAccStatus,
    error: disbursementAccStatusError,
    isLoading: disbursementAccStatusLoading,
  } = useApiRequest(
    (apiClient) => apiClient.getDisbursementAccountStatus(props.businessId),
    [props.businessId]
  );

  return (
    <>
      {(cardsKpiLoading || disbursementAccStatusLoading) && (
        <Center>
          <Spinner />
        </Center>
      )}
      {cardsKpiError && disbursementAccStatusError && (
        <ErrorMessage>
          An error was encountered while getting the summary.
          {cardsKpiError}
          {disbursementAccStatusError}
        </ErrorMessage>
      )}
      {!cardsKpiLoading && !disbursementAccStatusLoading && (
        <VStack mb={5}>
          <HStack width="100%" spacing="80px" mb={5} mt={3}>
            <FundsAvailable status={disbursementAccStatus} />
            <CardIssued cardsKpi={cardsKpi} />
          </HStack>
          <HStack width="100%" spacing="80px">
            <InitialCardsFunds cardsKpi={cardsKpi} />
            <FundsTransferred cardsKpi={cardsKpi} />
            <PointsTransferred cardsKpi={cardsKpi} />
          </HStack>
        </VStack>
      )}
    </>
  );
};
