import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import cherryLogo from "assets/cherryplay_logo.svg";
import { Image } from "@chakra-ui/image";
import { useLayoutContext } from "../../context/LayoutContext";
import {
  Button,
  Checkbox,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { CogIcon } from "../../styles/icons";
import {
  FaArrowDown,
  FaArrowUp,
  FaCog,
  FaFileUpload,
  FaPalette,
  FaTrash,
  FaUpload,
} from "react-icons/fa";
import {
  appleImageDimensions,
  appleImageTypes,
  arrayMove,
  convertARGBtoHex,
  convertHexToARGB,
  getPassImage,
  googleImageTypeDimensions,
  googleImageTypes,
} from "../../util/passHelper";
import { PassConfig } from "../../pages/PassEditor/PassEditor";
import { TextField } from "../fields/TextField/TextField";
import { useApiClient } from "../../hooks/useApiClient";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks/useToast";
import { ColorPicker } from "./ColorPicker";
import { FiDelete, FiTrash, FiUpload } from "react-icons/fi";

interface DetailProps {
  selectedField: string;
  selectedFieldTitle?: string;
  title?: string;
  fieldObject?: any;
  canDelete?: boolean;
}

interface PassSettingsPanelParams {
  style: "apple" | "google";
  doClose: () => void;
  isOpen: boolean;
  config: PassConfig;
  activeTab?: number;
  tokens?: any;
  reloadPass: (skipImages: boolean) => void;
  setIsSoftLoading: (isSoftLoading: boolean) => void;
  unprocessPassConfig: (config: PassConfig) => PassConfig;
}

const TabListAttributes = {
  borderBottom: "none",
  marginBottom: "2px",
  justifyContent: "center",
};

const TabPanelAttributes = {
  padding: "9px",
};

interface SelectedFile {
  key: string;
  file: File;
}

interface FileRef {
  key: string;
  input: HTMLInputElement | null;
}

const AutoCompleteItem = (props: any) => {
  const { entity } = props;
  return <div>{`${entity}`}</div>;
};

export const PassSettingsPanel = (props: PassSettingsPanelParams) => {
  const {
    style,
    doClose,
    isOpen,
    config,
    reloadPass,
    setIsSoftLoading,
    activeTab,
    tokens,
    unprocessPassConfig,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiClient = useApiClient();
  const { businessId, passConfigurationId } = useParams();
  const { showErrorToast, showSuccessToast } = useToast();
  const [localData, setLocalData] = useState<any>({ ...config.data });

  const handleChange = (fieldName: string) => (e: ChangeEvent<any>) => {
    setLocalData({
      ...localData,
      [fieldName]:
        e.target.checked !== undefined ? e.target.checked : e.target.value,
    });
  };

  useEffect(() => {
    setLocalData({ ...config.data });
  }, [config]);

  const checkIfDirty = () => {
    if (localData === null) return false;

    const newData = { ...localData };
    let isDirty = false;

    Object.keys(newData).map((key) => {
      const val =
        key.indexOf("Colour") === -1
          ? config.data[key]
          : convertARGBtoHex(config.data[key]);
      if (val !== newData[key]) {
        isDirty = true;
      }
    });
    return isDirty;
  };

  const save = async () => {
    const currentLocalData = { ...localData };

    const updatedData: any = {
      ...config.data,
      ...currentLocalData,
    };

    const updatedPassConfig: PassConfig = {
      data: updatedData,
      passImages: config.passImages,
    };
    const newData = unprocessPassConfig(updatedPassConfig).data;

    setIsLoading(true);
    const configData = await apiClient.updatePassConfig(
      businessId!,
      passConfigurationId!,
      newData
    );
    showSuccessToast("Pass updated");
    setIsLoading(false);
    reloadPass(true);
  };

  const saveColor = useCallback(
    (key: string, color: string) => {
      console.log(key, color);
      const newData = { ...(localData || {}) };
      newData[key] = color;
      setLocalData(newData);
    },
    [localData]
  );

  if (!config) {
    return <Box></Box>;
  }

  return (
    <Box
      width="300px"
      position="absolute"
      left="0px"
      top="0px"
      height="100%"
      overflowY="auto"
      bg="#ffffff"
      boxShadow="1px 3px 5px rgba(0,0,0,0.1)"
      className={isOpen ? "slideIn" : "slideOut"}
      zIndex={1}
    >
      <Flex p="11px" borderBottom="1px solid #ddd" alignItems="center">
        <Flex flex="1" alignItems="center">
          <Box as={FaCog} fontSize="14px" mr="5px" />
          <Box>Settings</Box>
        </Flex>
        <CloseButton fontSize="12px" onClick={() => doClose()} />
      </Flex>

      <Box p="10px">
        <Box>
          <Box mx="15px" my="20px">
            {[
              {
                name: "BusinessNameOverride",
                title: "Business Name",
                tip: "Leave blank to auto-populate",
                id: "BusinessNameOverride",
              },
              {
                name: "PassUsageLimit",
                title: "Pass Usage Limit",
                tip: "Some helper text here",
              },
              {
                name: "BarcodeType",
                title: "Barcode Type",
                options: [
                  "QR_CODE",
                  "AZTEC",
                  "CODE_39",
                  "CODE_128",
                  "CODABAR",
                  "DATA_MATRIX",
                  "EAN_8",
                  "EAN_13",
                  "ITF_14",
                  "PDF_417",
                  "UPC_A",
                  "TEXT_ONLY",
                  "None",
                ],
                type: "dropdown",
                id: "BarcodeType",
              },
              {
                name: "DisplayName",
                title: "Display Name",
                tip: "Some helper text here",
              },

              {
                name: "InvitationSMSMessageBodyFormat",
                title: "Invitation SMS",
                tip: "Invite text message helper here",
                type: "multiline",
              },

              {
                name: "DependentOnRelatedTwoStepMembershipRequest",
                title: "Dependent on 2-Step",
                tip: "Some helper text here",
                type: "boolean",
              },
            ].map((field) => (
              <FormControl mb="20px" key={field.name}>
                {field.type !== "boolean" && field.type !== "dropdown" && (
                  <>
                    <FormLabel mb="2px" fontSize="15px" fontWeight="bold">
                      {field.title}
                    </FormLabel>
                    <ReactTextareaAutocomplete
                      className="my-textarea1"
                      rows={field.type === "multiline" ? 3 : 1}
                      style={{
                        height: field.type === "multiline" ? "110px" : "44px",
                        fontSize: "14px",
                        padding: "10px 15px",
                        border: "1px solid var(--chakra-colors-gray-300)",
                        borderRadius: "var(--chakra-radii-md)",
                      }}
                      id={field.id}
                      onChange={handleChange(field.name)}
                      loadingComponent={() => <span>Loading</span>}
                      minChar={0}
                      value={localData[field.name]}
                      trigger={{
                        "{": {
                          afterWhitespace: false,
                          dataProvider: (token) => {
                            return tokens ? Object.keys(tokens) : [];
                            // [
                            //   { name: "smile", char: "🙂" },
                            //   { name: "heart", char: "❤️" },
                            // ];
                          },
                          component: AutoCompleteItem,
                          output: (item, trigger) => item.toString(),
                        },
                      }}
                    />
                    {field.tip && (
                      <FormHelperText mt="0">{field.tip}</FormHelperText>
                    )}
                  </>
                )}

                {field.type === "boolean" && (
                  <Checkbox
                    value={localData[field.name]}
                    onChange={handleChange(field.name)}
                    lineHeight="18px"
                    size="lg"
                  >
                    <span style={{ fontSize: "16px" }}>
                      <strong>{field.title}</strong>
                      <br />
                      <small>{field.tip}</small>
                    </span>
                  </Checkbox>
                )}

                {field.type === "dropdown" && (
                  <>
                    <FormLabel mb="2px" fontSize="15px" fontWeight="bold">
                      {field.title}
                    </FormLabel>
                    <Select
                      value={localData[field.name]}
                      onChange={handleChange(field.name)}
                    >
                      {field.options?.map((fieldOption) => (
                        <option key={fieldOption} value={fieldOption}>
                          {fieldOption}
                        </option>
                      ))}
                    </Select>
                  </>

                  // <Checkbox
                  //   value={localData[field.name]}
                  //   onChange={handleChange(field.name)}
                  //   lineHeight="18px"
                  //   size="lg"
                  // >
                  //   <span style={{ fontSize: "16px" }}>
                  //     <strong>{field.title}</strong>
                  //     <br />
                  //     <small>{field.tip}</small>
                  //   </span>
                  // </Checkbox>
                )}
              </FormControl>
            ))}
          </Box>

          <Divider my="10px" />

          <Flex mt="20px">
            <Button
              colorScheme="cherryButton"
              minW="120px"
              isLoading={isLoading}
              disabled={isLoading || !checkIfDirty()}
              onClick={() => save()}
            >
              SAVE
            </Button>
            {checkIfDirty() && (
              <Button
                ms="10px"
                variant="unstyled"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => doClose()}
              >
                Cancel
              </Button>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
