import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Heading,
  HStack,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text,
  Grid,
  GridItem,
  Collapse,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import { CherryPayApi } from "../../api/models";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { StepCircleGroup } from "../../components/Stepper/StepCircleGroup";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { EditMemberDetailsForm } from "../../forms/EditMemberDetailsForm";
import { useApiClient } from "../../hooks/useApiClient";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import { BoxShareIcon } from "../../styles/icons";
import { Link as ReactRouterLink } from "react-router-dom";
import { MemberFilterQueryModal } from "../../components/MemberFilterQueryModal/MemberFilterQueryModal";

interface SearchMemberProps {
  selectedMember: (memberId: string) => void;
}

interface MemberDetailProps {
  memberId: string;
  cardProgramId: string;
  onCancelMember: () => void;
  onMemberDetailsValidated: (data: CherryPayApi.CardOrderResponse) => void;
}

interface ConfirmAndSendProps {
  businessId: string;
  cardOrderResponse: CherryPayApi.CardOrderResponse;
  onBackButtonPressed: () => void;
  onCancelButtonPressed: () => void;
}

const SearchMember = (props: SearchMemberProps) => {
  const business = useBusinessContext();
  const { query, debouncedQuery, onChange, isDebouncing, setQuery } =
    useSearchInputState("");
  const [resetBtn, setResetBtn] = useState<boolean>(false);
  const [localSelectedMember, setLocalSelectedMember] = useState<string>();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [filterQuery, setFilterQuery] = useState<string>();
  const memberFilterQueryModal = useDisclosure();

  useEffect(() => {
    if (filterQuery) {
      setResetBtn(true);
    } else {
      setResetBtn(false);
    }
  }, [filterQuery]);

  const filterReset = () => {
    setFilterQuery(undefined);
  };

  const onProceedClick = () => {
    if (localSelectedMember) {
      props.selectedMember(localSelectedMember);
    }
  };

  const onMemberClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.memberId) {
      setLocalSelectedMember(e.currentTarget.dataset.memberId);
    }
  };

  const {
    items: members,
    isLoading,
    isLoadingPage,
    error,
    fetchNextPage,
  } = usePaginatedApiRequest(
    // Request the first page
    (apiClient) =>
      apiClient.searchMembersQuery(
        business.BusinessId,
        debouncedQuery,
        filterQuery,
        undefined,
        5
      ),
    // Request subsequent pages
    (apiClient, continuationToken) =>
      apiClient.searchMembersQuery(
        business.BusinessId,
        debouncedQuery,
        filterQuery,
        continuationToken,
        5
      ),
    [business.BusinessId, debouncedQuery, filterQuery]
  );

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.style.cursor = "pointer";
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.style.cursor = "auto";
  };

  const handleHoverIn = () => {
    setIsHovered(true);
  };

  const handleHoverOut = () => {
    setIsHovered(false);
  };

  const onSuccessMemberFilterQuery = (query: string | undefined) => {
    setFilterQuery(query);
    memberFilterQueryModal.onClose();
  };

  const memberRows = useMemo(
    () =>
      members?.map((member) => {
        const isSelected = member.id === localSelectedMember;
        return (
          <Tr
            key={member.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Td data-member-id={member.id} onClick={onMemberClick}>
              {member.MembershipNumber}
            </Td>
            <Td data-member-id={member.id} onClick={onMemberClick}>
              {member.PreferredName ?? member.FirstName} {member.LastName}
            </Td>
            <Td data-member-id={member.id} onClick={onMemberClick}>
              {!member.Financial && <Tag>Unfinancial</Tag>}
              {member.Financial && <Tag colorScheme="whatsapp">Financial</Tag>}
            </Td>
            <Td>
              {isSelected ? (
                <>
                  <Box
                    onMouseEnter={handleHoverIn}
                    onMouseLeave={handleHoverOut}
                  >
                    {isHovered ? (
                      <Link
                        as={ReactRouterLink}
                        to={`/businesses/${business.BusinessId}/members/${member.id}`}
                        target="_blank"
                      >
                        <BoxShareIcon />
                      </Link>
                    ) : (
                      <CheckCircleIcon color="cherryUi.600" />
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <Link
                    as={ReactRouterLink}
                    to={`/businesses/${business.BusinessId}/members/${member.id}`}
                    target="_blank"
                  >
                    <BoxShareIcon />
                  </Link>
                </>
              )}
            </Td>
          </Tr>
        );
      }),
    [members]
  );
  return (
    <>
      <VStack alignItems="start" width="100%">
        <Heading as="h5" color="cherryUi.600" size="md">
          Find and Select Member
        </Heading>
        <HStack>
          <Box>
            <SearchInput
              value={query}
              onChange={onChange}
              isLoading={isDebouncing}
              setQuery={setQuery}
            />
          </Box>
          <Button
            variant="solid"
            colorScheme="cherryButton"
            color="#fff"
            onClick={() => memberFilterQueryModal.onOpen()}
          >
            Filters
          </Button>
          {resetBtn && <Button onClick={filterReset}>Reset</Button>}
          {!members && isLoading && (
            <Center>
              <Spinner margin="4" />
            </Center>
          )}
        </HStack>
        {members && members.length > 0 && (
          <Box w="70%" overflowX="auto">
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Membership Number</Th>
                  <Th>Name</Th>
                  <Th>Status</Th>
                  <Th>View</Th>
                </Tr>
              </Thead>
              <Tbody>{memberRows}</Tbody>
            </Table>
          </Box>
        )}
        {!isLoading && members && members.length === 0 && (
          <Text>
            {debouncedQuery === ""
              ? "No members found."
              : `No members found matching query "${debouncedQuery}".`}
          </Text>
        )}
        <HStack align="end">
          {fetchNextPage && (
            <Box>
              {!isLoadingPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {isLoadingPage && <Spinner />}
            </Box>
          )}
          {localSelectedMember && (
            <Button colorScheme="cherryButton" onClick={onProceedClick}>
              Proceed
            </Button>
          )}
        </HStack>

        {error != null && (
          <ErrorMessage>
            An error was encountered while searching members.
          </ErrorMessage>
        )}
      </VStack>
      <MemberFilterQueryModal
        size="4xl"
        {...memberFilterQueryModal}
        businessId={business.BusinessId}
        memberQueryFilter={filterQuery ?? ""}
        onSuccess={onSuccessMemberFilterQuery}
      />
    </>
  );
};

const MemberDetail = (props: MemberDetailProps) => {
  const isMounted = useRef(true);
  const business = useBusinessContext();
  const apiClient = useApiClient();
  const [isEligible, setIsEligible] = useState(true);
  const [member, sMember] = useState<CherryPayApi.Member>();
  const [cardOrder, setCardOrder] = useState<CherryPayApi.CardOrderResponse>();
  const [formData, setFormData] = useState<{
    MembershipNumber: string | null;
    FirstName: string | null;
    LastName: string | null;
    MobileNumber: string | null;
    EmailAddress: string | null;
    FormattedAddress: string | null;
    AddressComponents: CherryPayApi.AddressComponent[];
    InitialCardValue: number;
  }>();
  const setMember = async (signal: AbortSignal) => {
    const result = await apiClient.getMember(
      business.BusinessId,
      props.memberId,
      signal
    );
    if (result.ok && isMounted.current) {
      sMember(result.data);
    }
  };
  const setOrder = async (signal: AbortSignal) => {
    const result = await apiClient.getNewCardOrder(
      business.BusinessId,
      props.memberId,
      props.cardProgramId,
      signal
    );
    if (result.ok && isMounted.current) {
      if (result.data.OrderStatus != "Incomplete") {
        setIsEligible(false);
      } else {
        setCardOrder(result.data);
      }
    }
  };

  const getContactDetails = (
    m: CherryPayApi.Member | undefined
  ):
    | {
        PhoneNumber?: string | null;
        AcceptsPhoneMarketing?: boolean;
        MobileNumber?: string | null;
        AcceptsMobileMarketing?: boolean;
        EmailAddress?: string | null;
        AcceptsEmailMarketing?: boolean;
      }
    | undefined => {
    if (m === undefined) {
      return undefined;
    }
    switch (m.DefaultAddress) {
      case "Home":
        return m.HomeContactDetails;
      case "Postal":
        return m.PostalContactDetails;
      case "Work":
        return m.WorkContactDetails;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const composeFormData = () => {
      // if member details already set on card order then pass it to form
      const contactDetails = getContactDetails(member);
      setFormData({
        FirstName:
          cardOrder?.MemberDetails?.FirstName ?? member?.FirstName ?? "",
        LastName: cardOrder?.MemberDetails?.LastName ?? member?.LastName ?? "",
        EmailAddress:
          cardOrder?.MemberDetails?.EmailAddress ??
          contactDetails?.EmailAddress ??
          "",
        FormattedAddress: cardOrder?.MemberDetails?.FormattedAddress ?? "",
        MembershipNumber:
          cardOrder?.MemberDetails?.MembershipNumber ??
          member?.MembershipNumber ??
          "",
        MobileNumber:
          cardOrder?.MemberDetails?.MobileNumber ??
          contactDetails?.MobileNumber ??
          "",
        AddressComponents: cardOrder?.MemberDetails?.AddressComponents ?? [],
        InitialCardValue: cardOrder?.InitialCardValue ?? 0,
      });
    };

    composeFormData();
  }, [cardOrder, member]);

  const onValidated = (data: CherryPayApi.CardOrderResponse) => {
    props.onMemberDetailsValidated(data);
  };
  const onCancelForm = () => {
    props.onCancelMember();
  };

  useEffect(() => {
    const abortController = new AbortController();

    setMember(abortController.signal);
    setOrder(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [isMounted]);

  return (
    <VStack width="100%" alignItems="start">
      <Heading as="h5" color="cherryUi.600" size="md">
        Find and Select Member
      </Heading>
      <Box w="70%" pb="30px">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Membership Number</Th>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th>&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {member && (
                <>
                  <Td>{member.MembershipNumber}</Td>
                  <Td>
                    {member.PreferredName ?? member.FirstName} {member.LastName}
                  </Td>
                  <Td>{member.Status}</Td>
                  <Td>
                    <CheckCircleIcon color="cherryUi.600" />
                  </Td>
                </>
              )}
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box>
        {isEligible && (
          <EditMemberDetailsForm
            businessId={business.BusinessId}
            memberId={member?.id}
            orderId={cardOrder?.OrderId}
            cardOrder={formData}
            cardProgramId={props.cardProgramId}
            dataValidated={onValidated}
            onCancel={onCancelForm}
          />
        )}
        {!isEligible && (
          <>
            <ErrorMessage>Card already ordered or sent</ErrorMessage>
            <Button mt={5} colorScheme="cherryButton" onClick={onCancelForm}>
              NEW ORDER
            </Button>
          </>
        )}
      </Box>
    </VStack>
  );
};

const ConfirmAndSend = (props: ConfirmAndSendProps) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const [submitResponse, setSubmitResponse] =
    useState<CherryPayApi.CardOrderResponse>(props.cardOrderResponse);
  const apiClient = useApiClient();

  const onConfirmButtonClick = async () => {
    setIsSubmitting(true);
    if (!props.cardOrderResponse.MemberId || !props.cardOrderResponse.OrderId) {
      setIsError(true);
    } else {
      const result = await apiClient.confirmCardOrder(
        props.businessId,
        props.cardOrderResponse.MemberId,
        props.cardOrderResponse.OrderId
      );
      if (result.ok) {
        setSubmitSuccess(true);
        setSubmitResponse(result.data);
      } else {
        setIsError(true);
        setErrorMessage(result.message);
      }
    }
    setIsSubmitting(false);
  };
  const addressToString = (
    address: CherryPayApi.ConfirmedPostalAddress | null
  ): string => {
    if (!address) return "";
    return [
      address.AddressLine1,
      address.AddressLine2,
      address.AddressLine3,
      address.Suburb,
      address.State,
      address.PostCode,
      address.Country,
    ]
      .filter((component) => component !== null)
      .join(", ");
  };
  return (
    <>
      <VStack width="100%" alignItems="start">
        <Heading as="h5" color="cherryUi.600" size="md" fontWeight="bold">
          Confirm the Order
        </Heading>
        <Box w="60%">
          <Heading size="sm">A physical card will be issued to:</Heading>
          <Grid
            templateRows="repeat(7, 1fr)"
            templateColumns="repeat(4, 1fr)"
            pb="5"
          >
            <GridItem>Membership Number:</GridItem>
            <GridItem colSpan={3}>
              {props.cardOrderResponse.MemberDetails?.MembershipNumber}
            </GridItem>
            <GridItem>First Name:</GridItem>
            <GridItem colSpan={3}>
              {props.cardOrderResponse.MemberDetails?.FirstName}
            </GridItem>
            <GridItem>Last Name:</GridItem>
            <GridItem colSpan={3}>
              {props.cardOrderResponse.MemberDetails?.LastName}
            </GridItem>
            <GridItem>Email Address:</GridItem>
            <GridItem colSpan={3}>
              {props.cardOrderResponse.MemberDetails?.EmailAddress}
            </GridItem>
            <GridItem>Mobile Number:</GridItem>
            <GridItem colSpan={3}>
              {props.cardOrderResponse.MemberDetails?.MobileNumber}
            </GridItem>
            <GridItem>&nbsp;</GridItem>
            <GridItem colSpan={3}>&nbsp;</GridItem>
            <GridItem>Initial Balance:</GridItem>
            <GridItem colSpan={3}>
              ${props.cardOrderResponse.InitialCardValue}
            </GridItem>
          </Grid>
        </Box>
        <Box w="60%">
          <Heading size="sm">The card and PIN will be posted to:</Heading>
          <Text>
            {addressToString(props.cardOrderResponse.ConfirmedPostalAddress)}
          </Text>
        </Box>
        <HStack w="60%" pt="5">
          {!submitSuccess && (
            <>
              <Button onClick={props.onBackButtonPressed}>BACK</Button>
              <Button onClick={props.onCancelButtonPressed}>CANCEL</Button>
              <Button
                colorScheme="cherryButton"
                isDisabled={isSubmitting}
                onClick={onConfirmButtonClick}
              >
                {isSubmitting && <Spinner />}
                {!isSubmitting && "PLACE ORDER"}
              </Button>
            </>
          )}
        </HStack>
        {isError && <ErrorMessage>Error: {errorMessage}</ErrorMessage>}
        <Collapse in={submitSuccess} animateOpacity>
          <Box
            p="30px"
            color="black"
            bg="whatsapp.600"
            rounded="md"
            shadow="md"
          >
            <Text>
              The card order was successfully placed for{" "}
              {submitResponse.MemberDetails?.FirstName}
            </Text>
          </Box>
        </Collapse>
        {submitSuccess && (
          <Button
            colorScheme="cherryButton"
            onClick={props.onCancelButtonPressed}
          >
            NEW ORDER
          </Button>
        )}
      </VStack>
    </>
  );
};

export const OrderPhysicalCard = () => {
  const { cardProgramId } = useParams();
  const { userBusinessId } = useUserContext();
  const business = useBusinessContext();
  const [cardOrderResponse, setCardOrderResponse] =
    useState<CherryPayApi.CardOrderResponse>({
      ConfirmedPostalAddress: null,
      InitialCardValue: null,
      MemberDetails: null,
      MemberId: null,
      OrderId: null,
      OrderStatus: null,
    });
  const regSteps = [
    {
      seq: 1,
      title: "SELECT MEMBER",
    },
    {
      seq: 2,
      title: "MEMBER DETAILS",
    },
    {
      seq: 3,
      title: "CONFIRM & ORDER",
    },
  ];
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStepIndex(currentStepIndex - 1);
  };
  const [selectedMember, setSelectedMember] = useState<string>();

  const onSelectedMember = useCallback(
    (memberId: string) => {
      setSelectedMember(memberId);
      handleNextStep();
    },
    [setSelectedMember]
  );

  const onCancelMemberDetails = () => {
    handlePreviousStep();
  };

  const onMemberDetailsValidated = (data: CherryPayApi.CardOrderResponse) => {
    setCardOrderResponse(data);
    handleNextStep();
  };

  const backButtonPressed = () => {
    handlePreviousStep();
  };

  const cancelButtonPressed = () => {
    setCurrentStepIndex(0);
  };

  const Content = () => {
    if (currentStepIndex === 0) {
      return <SearchMember selectedMember={onSelectedMember} />;
    } else if (currentStepIndex === 1) {
      if (selectedMember) {
        return (
          <MemberDetail
            memberId={selectedMember}
            cardProgramId={cardProgramId ?? ""}
            onCancelMember={onCancelMemberDetails}
            onMemberDetailsValidated={onMemberDetailsValidated}
          />
        );
      }
    } else if (currentStepIndex == 2) {
      return (
        <ConfirmAndSend
          businessId={business.BusinessId}
          onBackButtonPressed={backButtonPressed}
          onCancelButtonPressed={cancelButtonPressed}
          cardOrderResponse={cardOrderResponse}
        />
      );
    }
    return <></>;
  };
  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>Cards</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack>
          <StepCircleGroup
            steps={regSteps}
            currentStepIndex={currentStepIndex}
          />
        </VStack>
        <Content />
      </PageContent>
    </>
  );
};
