import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { Heading, VStack, Center } from "@chakra-ui/layout";
import { Link } from "react-router-dom";
import { CherryPayApi } from "../../api/models";
import { Card } from "../../components/Card/Card";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Button, HStack, Spinner } from "@chakra-ui/react";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { PlusIcon } from "../../styles/icons";
import { RoleModal } from "../../components/RoleModal/RoleModal";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useToast } from "../../hooks/useToast";
import { RoleDiff } from "../../components/RolesDiff/RolesDiff";
import { useRoles } from "../../hooks/useRoles";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { PageContent } from "../../components/PageContent/PageContent";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { RolesTable } from "../../components/RolesTable/RolesTable";

export const BusinessRoles = () => {
  const business = useBusinessContext();
  const { showSuccessToast, showErrorToast } = useToast();

  const [roleModalState, setRoleModalState] = useState<{
    isOpen: boolean;
    existingRole: CherryPayApi.Role | null;
  }>({ isOpen: false, existingRole: null });

  const onCloseModal = useCallback(
    () => setRoleModalState({ isOpen: false, existingRole: null }),
    [setRoleModalState]
  );

  const onOpenRoleModal = useCallback(
    (role: CherryPayApi.Role | null) =>
      setRoleModalState({ isOpen: true, existingRole: role }),
    [setRoleModalState]
  );

  const onClickNewRole = useCallback(
    () => onOpenRoleModal(null),
    [onOpenRoleModal]
  );

  const {
    roles,
    roleDiff,
    isLoading: rolesLoading,
    error: rolesError,
    addRole,
    removeRole,
    updateRole,
    refresh,
    resetRoles,
  } = useRoles(business);

  const {
    data: permissions,
    isLoading: permissionsLoading,
    error: permissionsError,
  } = useApiRequest(
    (apiClient) => apiClient.getBusinessPermissions(business.BusinessId),
    [business]
  );

  const saveRolesRequest = useApiUpdateRequest(
    (apiClient) => apiClient.updateBusinessRoles(business.BusinessId, roles),
    [business, roles]
  );

  useEffect(() => {
    if (saveRolesRequest.isLoading) {
      return;
    }

    if (saveRolesRequest.result?.ok) {
      showSuccessToast("Roles updated.");
      refresh();
    } else if (
      saveRolesRequest.result?.ok === false ||
      saveRolesRequest.error
    ) {
      showErrorToast(
        saveRolesRequest.result?.message ?? "Roles update failed."
      );
    }
  }, [refresh, saveRolesRequest.statusCode, saveRolesRequest.isLoading]);

  const isLoading = permissionsLoading || rolesLoading;
  const error = !!rolesError || !!permissionsError;

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/roles`}
            >
              Roles
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Roles</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card p="2" width="100%">
          <VStack w="100%" spacing="2" alignItems="end">
            <HStack w="100%" justifyContent="end">
              <Button
                leftIcon={<PlusIcon />}
                onClick={onClickNewRole}
                colorScheme="cherryButton"
              >
                New Role
              </Button>
            </HStack>
            {isLoading && (
              <Center>
                <Spinner />
              </Center>
            )}
            {error && (
              <ErrorMessage>
                An error was encountered while loading the page.
              </ErrorMessage>
            )}
            {permissions && roles && !isLoading && (
              <>
                {roleDiff.hasChanges && (
                  <RoleDiff
                    diff={roleDiff}
                    onSave={saveRolesRequest.request}
                    onCancel={resetRoles}
                    isLoading={saveRolesRequest.isLoading}
                  />
                )}
                <RolesTable
                  roles={roles}
                  availablePermissions={permissions}
                  onClickEdit={onOpenRoleModal}
                  onClickRemove={removeRole}
                />
              </>
            )}
          </VStack>
        </Card>
      </PageContent>
      {permissions && (
        <RoleModal
          {...roleModalState}
          availablePermissions={permissions}
          onClose={onCloseModal}
          onUpdateRole={updateRole}
          onCreateRole={addRole}
        />
      )}
    </>
  );
};
