import { Box, Divider, VStack, HStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { IntegerField } from "../components/fields/IntegerField/IntegerField";
import { ChakraReactSelectField } from "../components/fields/SelectField/ChakraReactSelectField";
import { TextArea } from "../components/fields/TextArea/TextArea";
import { FormStack } from "../components/FormStack/FormStack";
import { ApiEnumConfigurations } from "../enums/ApiEnumConfigurations";
import { ApiMemberDataFieldName } from "../enums/ApiMemberDataFieldName";
import { useApiRequest } from "../hooks/useApiRequest";

interface MemberFilterQueryFormValues {
  Query: {
    BirthMonth: string[];
    NotBirthMonth: string[];
    Financial?: boolean | undefined;
    Gender: string[];
    NotGender: string[];
    MemberLevel: string[];
    NotMemberLevel: string[];
    MemberLevelDescription: string[];
    NotMemberLevelDescription: string[];
    MembershipType: string[];
    NotMembershipType: string[];
    MembershipTypeDescription: string[];
    NotMembershipTypeDescription: string[];
    PointsBalanceEqualTo?: number | undefined;
    PointsBalanceGreaterThan?: number | undefined;
    PointsBalanceLessThan?: number | undefined;
    PointsBalanceGreaterThanOrEqualTo?: number | undefined;
    PointsBalanceLessThanOrEqualTo?: number | undefined;
    Status: string[];
    NotStatus: string[];
  };
  result?: string | undefined;
}

interface MemberFilterQueryFormProps {
  businessId: string;
  query: string;
  onCancel: () => void;
  onSuccess: (query?: string) => void;
  onFailure?: (message?: string) => void;
}

const InnerForm = ({
  isSubmitting,
  submitForm,
  isValid,
  onCancel,
  values,
  businessId,
  setFieldValue,
}: MemberFilterQueryFormProps & FormikProps<MemberFilterQueryFormValues>) => {
  const { data: birthMonthOptions, isLoading: birthMonthOptionsLoading } =
    useApiRequest(
      (apiClient) =>
        apiClient.getEnumConfigurations(
          businessId,
          ApiEnumConfigurations.CalendarMonths
        ),
      []
    );

  const { data: genderOptions, isLoading: genderOptionsLoading } =
    useApiRequest(
      (apiClient) =>
        apiClient.getMemberDataOptions(
          businessId,
          ApiMemberDataFieldName.Gender
        ),
      []
    );

  const { data: statusOptions, isLoading: statusOptionsLoading } =
    useApiRequest(
      (apiClient) =>
        apiClient.getMemberDataOptions(
          businessId,
          ApiMemberDataFieldName.Status
        ),
      []
    );

  const { data: memberLevelOptions, isLoading: memberLevelOptionsLoading } =
    useApiRequest(
      (apiClient) =>
        apiClient.getMemberDataOptions(
          businessId,
          ApiMemberDataFieldName.MemberLevel
        ),
      []
    );

  const {
    data: memberLevelDescriptionOptions,
    isLoading: memberLevelDescriptionOptionsLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getMemberDataOptions(
        businessId,
        ApiMemberDataFieldName.MemberLevelDescription
      ),
    []
  );

  const {
    data: membershipTypeOptions,
    isLoading: membershipTypeOptionsLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getMemberDataOptions(
        businessId,
        ApiMemberDataFieldName.MembershipType
      ),
    []
  );

  const {
    data: membershipTypeDescriptionOptions,
    isLoading: membershipTypeDescriptionOptionsLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getMemberDataOptions(
        businessId,
        ApiMemberDataFieldName.MembershipTypeDescription
      ),
    []
  );

  useEffect(() => {
    setFieldValue("result", generateQueryResult());
  }, [values.Query]);

  function generateQueryResult() {
    var result = "";
    let firstQuery = true;
    Object.entries(values.Query).map(([key, value]) => {
      const isArray = Array.isArray(value);

      if (isArray) {
        for (var val of value) {
          if (!val) return;
          result += firstQuery ? "?" : "&";
          const isNot = key.indexOf("Not") === 0;
          result += `${isNot ? key.replace("Not", "") : key}=${
            isNot ? "!" : ""
          }${val}`;
          firstQuery = false;
        }
      } else {
        if (value == null) return;
        result += firstQuery ? "?" : "&";
        const isNot = key.indexOf("Not") === 0;
        result += `${isNot ? key.replace("Not", "") : key}=${
          isNot ? "!" : ""
        }${value}`;
        firstQuery = false;
      }
    });
    return result;
  }

  function onReset() {
    setFieldValue("Query", {
      BirthMonth: [],
      NotBirthMonth: [],
      Gender: [],
      NotGender: [],
      MemberLevel: [],
      NotMemberLevel: [],
      MemberLevelDescription: [],
      NotMemberLevelDescription: [],
      MembershipType: [],
      NotMembershipType: [],
      MembershipTypeDescription: [],
      NotMembershipTypeDescription: [],
      Status: [],
      NotStatus: [],
    });
  }
  return (
    <>
      <Box width="100%" maxW="800px" alignSelf="flex-start">
        <FormStack>
          {/* <TextArea label="Result" name="result" isReadOnly={true} />
          <Divider /> */}

          <VStack w="100%" spacing={4}>
            <HStack w="100%">
              <ChakraReactSelectField
                label="Financial"
                name="Query.Financial"
                selectedOptionColor="blue"
                options={[
                  { label: "None", value: null },
                  { label: "Yes", value: "true" },
                  { label: "No", value: "false" },
                ]}
              />
            </HStack>

            <HStack w="100%">
              <ChakraReactSelectField
                label="Status"
                name="Query.Status"
                selectedOptionColor="blue"
                isMulti
                isLoading={statusOptionsLoading}
                options={
                  statusOptions
                    ?.filter((item) => {
                      return (
                        values.Query.NotStatus.find((ns) => ns == item.Value) ==
                        null
                      );
                    })
                    .map((item) => {
                      return {
                        label: item.Value! as string as string,
                        value: item.Value! as string as string,
                      };
                    }) || []
                }
              />

              <ChakraReactSelectField
                label="Exclude Status"
                name="Query.NotStatus"
                selectedOptionColor="blue"
                isMulti
                isLoading={statusOptionsLoading}
                options={
                  statusOptions
                    ?.filter((item) => {
                      return (
                        values.Query.Status.find((ns) => ns == item.Value) ==
                        null
                      );
                    })
                    ?.map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />
            </HStack>

            <HStack w="100%">
              <ChakraReactSelectField
                label="Birth Month"
                name="Query.BirthMonth"
                selectedOptionColor="blue"
                isMulti
                isLoading={birthMonthOptionsLoading}
                options={
                  birthMonthOptions
                    ?.filter((item) => {
                      return (
                        values.Query.NotBirthMonth.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    .map((item) => {
                      return {
                        label: item.Key!,
                        value: item.Value!,
                      };
                    }) || []
                }
              />

              <ChakraReactSelectField
                label="Exclude Birth Month"
                name="Query.NotBirthMonth"
                selectedOptionColor="blue"
                isMulti
                isLoading={birthMonthOptionsLoading}
                options={
                  birthMonthOptions
                    ?.filter((item) => {
                      return (
                        values.Query.BirthMonth.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    ?.map((item) => {
                      return {
                        label: item.Key!,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />
            </HStack>

            <HStack w="100%">
              <ChakraReactSelectField
                label="Gender"
                name="Query.Gender"
                selectedOptionColor="blue"
                isMulti
                isLoading={genderOptionsLoading}
                options={
                  genderOptions
                    ?.filter((item) => {
                      return (
                        values.Query.NotGender.find((ns) => ns == item.Value) ==
                        null
                      );
                    })
                    .map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />

              <ChakraReactSelectField
                label="Exclude Gender"
                name="Query.NotGender"
                selectedOptionColor="blue"
                isMulti
                isLoading={genderOptionsLoading}
                options={
                  genderOptions
                    ?.filter((item) => {
                      return (
                        values.Query.Gender.find((ns) => ns == item.Value) ==
                        null
                      );
                    })
                    ?.map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />
            </HStack>

            <HStack w="100%">
              <ChakraReactSelectField
                label="Member Level ID"
                name="Query.MemberLevel"
                selectedOptionColor="blue"
                isMulti
                isLoading={memberLevelOptionsLoading}
                options={
                  memberLevelOptions
                    ?.filter((item) => {
                      return (
                        values.Query.NotMemberLevel.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    .map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />

              <ChakraReactSelectField
                label="Exclude Member Level ID"
                name="Query.NotMemberLevel"
                selectedOptionColor="blue"
                isMulti
                isLoading={memberLevelOptionsLoading}
                options={
                  memberLevelOptions
                    ?.filter((item) => {
                      return (
                        values.Query.MemberLevel.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    ?.map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />
            </HStack>

            <HStack w="100%">
              <ChakraReactSelectField
                label="Member Level"
                name="Query.MemberLevelDescription"
                selectedOptionColor="blue"
                isMulti
                isLoading={memberLevelDescriptionOptionsLoading}
                options={
                  memberLevelDescriptionOptions
                    ?.filter((item) => {
                      return (
                        values.Query.NotMemberLevelDescription.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    .map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />

              <ChakraReactSelectField
                label="Exclude Member Level"
                name="Query.NotMemberLevelDescription"
                selectedOptionColor="blue"
                isMulti
                isLoading={memberLevelDescriptionOptionsLoading}
                options={
                  memberLevelDescriptionOptions
                    ?.filter((item) => {
                      return (
                        values.Query.MemberLevelDescription.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    ?.map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />
            </HStack>

            <HStack w="100%">
              <ChakraReactSelectField
                label="Membership Type ID"
                name="Query.MembershipType"
                selectedOptionColor="blue"
                isMulti
                isLoading={membershipTypeOptionsLoading}
                options={
                  membershipTypeOptions
                    ?.filter((item) => {
                      return (
                        values.Query.NotMembershipType.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    .map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />

              <ChakraReactSelectField
                label="Exclude Membership Type ID"
                name="Query.NotMembershipType"
                selectedOptionColor="blue"
                isMulti
                isLoading={membershipTypeOptionsLoading}
                options={
                  membershipTypeOptions
                    ?.filter((item) => {
                      return (
                        values.Query.MembershipType.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    ?.map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />
            </HStack>

            <HStack w="100%">
              <ChakraReactSelectField
                label="Membership Type"
                name="Query.MembershipTypeDescription"
                selectedOptionColor="blue"
                isMulti
                isLoading={membershipTypeDescriptionOptionsLoading}
                options={
                  membershipTypeDescriptionOptions
                    ?.filter((item) => {
                      return (
                        values.Query.NotMembershipTypeDescription.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    .map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />

              <ChakraReactSelectField
                label="Exclude Membership Type"
                name="Query.NotMembershipTypeDescription"
                selectedOptionColor="blue"
                isMulti
                isLoading={membershipTypeDescriptionOptionsLoading}
                options={
                  membershipTypeDescriptionOptions
                    ?.filter((item) => {
                      return (
                        values.Query.MembershipTypeDescription.find(
                          (ns) => ns == item.Value
                        ) == null
                      );
                    })
                    ?.map((item) => {
                      return {
                        label: item.Value! as string,
                        value: item.Value! as string,
                      };
                    }) || []
                }
              />
            </HStack>
            <HStack w="100%">
              <IntegerField
                label="Points Balance Equal To"
                name="Query.PointsBalanceEqualTo"
              />

              <IntegerField
                label="Points Balance Not Equal To"
                name="Query.PointsBalanceNotEqualTo"
              />
            </HStack>

            <HStack w="100%">
              <IntegerField
                label="Points Balance Greater Than"
                name="Query.PointsBalanceGreaterThan"
              />

              <IntegerField
                label="Points Balance Less Than"
                name="Query.PointsBalanceLessThan"
              />
            </HStack>

            <HStack w="100%">
              <IntegerField
                label="Points Balance Greater Than Or Equal To"
                name="Query.PointsBalanceGreaterThanOrEqualTo"
              />

              <IntegerField
                label="Points Balance Less Than Or Equal To"
                name="Query.PointsBalanceLessThanOrEqualTo"
              />
            </HStack>
          </VStack>

          <HStack width="100%" justifyContent="start" spacing="3" pt={4} pb={6}>
            <Button
              colorScheme="cherryButton"
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
            >
              Save
            </Button>

            <Button
              disabled={isSubmitting}
              onClick={onReset}
              colorScheme="cherryButton"
              bgColor="cherryButtonDark.50"
            >
              Reset
            </Button>

            <Button disabled={isSubmitting} onClick={onCancel}>
              Cancel
            </Button>
          </HStack>
        </FormStack>
      </Box>
    </>
  );
};

export const MemberFilterQueryForm = withFormik<
  MemberFilterQueryFormProps,
  MemberFilterQueryFormValues
>({
  mapPropsToValues: ({ query }) => {
    let result: MemberFilterQueryFormValues = {
      Query: {
        BirthMonth: [],
        NotBirthMonth: [],
        Gender: [],
        NotGender: [],
        MemberLevel: [],
        NotMemberLevel: [],
        MemberLevelDescription: [],
        NotMemberLevelDescription: [],
        MembershipType: [],
        NotMembershipType: [],
        MembershipTypeDescription: [],
        NotMembershipTypeDescription: [],
        Status: [],
        NotStatus: [],
      },
    };
    if (!query) return result;

    const startIndex = query.indexOf("?");
    const queries = query.substring(startIndex + 1).split("&");
    for (var q of queries) {
      const qIndex = q.indexOf("=");
      const qKey = q.substr(0, qIndex);
      const qValue = q.substr(qIndex + 1);
      const isNot = qValue.indexOf("!") === 0;
      const prop = (result.Query as { [key: string]: any })[
        `${isNot ? "Not" : ""}${qKey}`
      ];
      const isArray = Array.isArray(prop);
      if (isArray) prop.push(isNot ? qValue.substr(1) : qValue);
      else
        (result.Query as { [key: string]: any })[
          `${isNot ? "Not" : ""}${qKey}`
        ] = isNot ? qValue.substr(1) : qValue;
    }

    return result;
  },

  handleSubmit: async (values, { props }) => {
    props.onSuccess(values.result);
  },

  validateOnBlur: false,
  validateOnMount: false,
  validateOnChange: false,
})(InnerForm);
