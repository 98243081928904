import { useCallback, useEffect, useState } from "react";
import { ApiClient, ApiResult } from "../api/apiClient";
import { useApiClient } from "./useApiClient";
import { useDidUnmount } from "./useDidUnmount";

export type ApiRequestState<T> = {
  data: T | null;
  isLoading: boolean;
  error: string | null;
  statusCode: number | null;
  continuationToken?: string;
  result: ApiResult<T> | null;
};

export const useApiRequest = <T>(
  fn: (apiClient: ApiClient) => Promise<ApiResult<T>> | null,
  deps: any[]
) => {
  const didUnmount = useDidUnmount();
  const apiClient = useApiClient();
  const [refreshCount, setRefrsehCount] = useState<number>(0);

  const refresh = useCallback(
    () => setRefrsehCount((state) => state + 1),
    [setRefrsehCount]
  );

  const [apiState, setApiState] = useState<ApiRequestState<T>>({
    data: null,
    isLoading: true,
    error: null,
    statusCode: null,
    result: null,
  });

  useEffect(() => {
    setApiState((state) => ({ ...state, isLoading: true }));
    const requestPromise = fn(apiClient);

    if (!requestPromise) {
      return;
    }

    requestPromise
      .then((result) => {
        if (didUnmount.current) {
          return;
        }
        if (result.ok) {
          setApiState({
            isLoading: false,
            data: result.data,
            error: null,
            statusCode: result.statusCode,
            continuationToken: result.continuationToken,
            result,
          });
        } else {
          setApiState({
            isLoading: false,
            data: null,
            error: `HTTP ${result.statusCode} error : ${result.message}`,
            statusCode: result.statusCode ?? null,
            result,
          });
        }
      })
      .catch((error) => {
        if (didUnmount.current) {
          return;
        }
        setApiState({
          isLoading: false,
          data: null,
          error: "Unknown error.",
          statusCode: null,
          result: null,
        });
      });
  }, [didUnmount, apiClient, setApiState, refreshCount, ...deps]);

  return { ...apiState, refresh };
};
