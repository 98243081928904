import React, { useEffect, useMemo } from "react";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Box,
  Heading,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/hooks";
import { Table, Tbody, Thead, Th, Td, Tr } from "@chakra-ui/table";
import { Card } from "../../components/Card/Card";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { Link } from "react-router-dom";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { PageContent } from "../../components/PageContent/PageContent";
import { Button, HStack } from "@chakra-ui/react";
import { PlusIcon } from "../../styles/icons";
import { NewBusinessModal } from "../../components/NewBusinessModal/NewBusinessModal";
import { useModalCloseEffect } from "../../hooks/useModalCloseEffect";

export const BusinessList = () => {
  const { query, debouncedQuery, onChange, isDebouncing, setQuery } =
    useSearchInputState("");

  const newBusinessModal = useDisclosure();

  const {
    data: items,
    isLoading,
    error,
    refresh,
  } = useApiRequest((client) => client.getBusinesses(), []);

  // Refresh the business list when the modal is closed.
  useModalCloseEffect(newBusinessModal, () => refresh(), [refresh]);

  // Temporarily filtering on the clientside until the API supports filtering
  const filteredItems = useMemo(() => {
    if (items === null) {
      return null;
    }
    return items.filter(
      (i) =>
        i.DisplayName?.toLowerCase().indexOf(
          debouncedQuery.toLowerCase().trim()
        ) !== -1
    );
  }, [items, debouncedQuery]);

  if (isLoading && !items) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Businesses</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            {items && items.length > 1 && (
              <HStack width="100%" justifyItems="start">
                <Box flex="1">
                  <Box width="100%" maxWidth="300px">
                    <SearchInput
                      value={query}
                      onChange={onChange}
                      isLoading={isLoading || isDebouncing}
                      placeholder="Search businesses..."
                      setQuery={setQuery}
                    />
                  </Box>
                </Box>
                <Button
                  leftIcon={<PlusIcon />}
                  onClick={newBusinessModal.onOpen}
                  colorScheme="cherryButton"
                >
                  New Business
                </Button>
              </HStack>
            )}
            {!filteredItems && isLoading && <LoadingIndicator />}
            {filteredItems && filteredItems.length > 0 && (
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Venue</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredItems?.map((business) => (
                    <Tr key={business.BusinessId}>
                      <Td>
                        <Link to={`/businesses/${business.BusinessId}`}>
                          {business.DisplayName}
                        </Link>
                      </Td>
                      <Td>
                        {business.VenueSummaries.length === 1
                          ? `${business.VenueSummaries.length} venues`
                          : business.VenueSummaries[0]?.DisplayName ?? "-"}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            {!isLoading && filteredItems && filteredItems.length === 0 && (
              <Text>No businesses found matching query '{debouncedQuery}'</Text>
            )}
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>
            An error was encountered while searching businesses.
          </ErrorMessage>
        )}
      </PageContent>
      <NewBusinessModal {...newBusinessModal} />
    </>
  );
};
