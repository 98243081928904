"use strict";

import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";

interface ColorPickerProps {
  initialColor: string;
  saveColor?: (newColor: string) => void;
  children: any;
}

export function ColorPicker(props: ColorPickerProps) {
  const { initialColor, saveColor, children } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [color, setColor] = useState<string>(initialColor ?? "#ffffff");

  const popover: any = {
    position: "absolute",
    zIndex: "2",
  };
  const cover: any = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    if (saveColor) {
      saveColor(color);
    }
  }, [color]);

  return (
    <Box>
      <div>
        <Box onClick={handleClick}>{children}</Box>
        {displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={handleClose} />
            <ChromePicker
              disableAlpha={true}
              color={color}
              onChange={(color) => setColor(color.hex)}
            />
          </div>
        ) : null}
      </div>
    </Box>
  );
}
