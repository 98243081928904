import { HStack } from "@chakra-ui/react";
import React from "react";
import { StepCircle } from "./StepCircle";

type StepSequence = {
  seq: number;
  title: string;
};

type StepCircleGroupProps = {
  steps: StepSequence[];
  currentStepIndex: number;
};

export const StepCircleGroup = ({
  steps,
  currentStepIndex,
}: StepCircleGroupProps) => {
  return (
    <HStack spacing="40px" mt="4">
      {steps.map((item, index) => (
        <StepCircle
          key={index}
          seq={item.seq}
          title={item.title}
          isActive={index === currentStepIndex}
          isCompleted={index < currentStepIndex}
          isLast={index === steps.length - 1}
        />
      ))}
    </HStack>
  );
};
