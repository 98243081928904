import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { CherryPayApi } from "../../api/models";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { BoxShareIcon, SearchIcon } from "../../styles/icons";
import DateUtil from "../../util/DateUtil";
import { Card } from "../Card/Card";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { Link as ReactRouterLink } from "react-router-dom";

interface CardSummaryProps {
  businessId: string;
  configs: CherryPayApi.CardProgram[] | undefined;
}

export const CardSummary = (props: CardSummaryProps) => {
  const [program, setProgram] = useState<string>();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>();
  const [filter, setFilter] = useState<number>(0);

  const run = () => {
    setFilter(filter + 1);
  };

  const {
    items: summaries,
    isLoading: summaryLoading,
    isLoadingPage: summaryLoadingPage,
    error: summaryError,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (apiClient) => {
      const createdFrom = DateUtil.setStartEndDate(startDate, "start");
      const createdTo = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getCardSummary(
        props.businessId,
        program,
        createdFrom,
        createdTo,
        limit,
        query
      );
    },
    (apiClient, continuationToken) => {
      const createdFrom = DateUtil.setStartEndDate(startDate, "start");
      const createdTo = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getCardSummary(
        props.businessId,
        program,
        createdFrom,
        createdTo,
        limit,
        query,
        continuationToken
      );
    },
    [filter]
  );

  const summaryRows = useMemo(
    () =>
      summaries?.map((s) => {
        const formattedDate = DateUtil.getLocalDateFormatFromString(
          s.CreatedDate
        );
        return (
          <Tr key={s.CardId}>
            <Td whiteSpace="nowrap">
              <Link
                as={ReactRouterLink}
                to={`/businesses/${props.businessId}/cards/${s.CardId}`}
              >
                {s.ExternalAccountId ?? "Pending"}
              </Link>
            </Td>
            <Td whiteSpace="nowrap">{s.CardProgramDescription}</Td>
            <Td whiteSpace="nowrap">{s.AccountStatus}</Td>
            <Td whiteSpace="nowrap">
              {s.MemberId && (
                <Link
                  as={ReactRouterLink}
                  to={`/businesses/${props.businessId}/members/${s.MemberId}`}
                  target="_blank"
                >
                  <BoxShareIcon />
                </Link>
              )}
            </Td>
            <Td whiteSpace="nowrap">{s.FirstName}</Td>
            <Td whiteSpace="nowrap">{s.LastName}</Td>
            <Td whiteSpace="nowrap">{s.EmailAddress}</Td>
            <Td whiteSpace="nowrap">{s.MobileNumber}</Td>
            <Td whiteSpace="nowrap">{s.IssuedByUser}</Td>
            <Td whiteSpace="nowrap">{formattedDate}</Td>
          </Tr>
        );
      }),
    [summaries]
  );

  return (
    <>
      <Heading size="md" alignSelf="start" pt={10}>
        Cards Issued
      </Heading>
      <VStack alignItems="start" width="100%">
        <Flex className="form-filter-wrapper">
          <Box p="4">
            <FormControl>
              <FormLabel>Program</FormLabel>
              {props.configs && props.configs.length > 0 && (
                <Select
                  placeholder="Select program"
                  onChange={(e) => setProgram(e.target.value)}
                  defaultValue={program}
                >
                  {props.configs.map((p) => {
                    return (
                      <option value={p.CardProgramId} key={p.CardProgramId}>
                        {p.Description}
                      </option>
                    );
                  })}
                </Select>
              )}
            </FormControl>
          </Box>
          <Box p="4">
            <FormControl>
              <FormLabel>Created from</FormLabel>
              <Input
                type="date"
                placeholder="Created from"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box p="4">
            <FormControl>
              <FormLabel>Created to</FormLabel>
              <Input
                type="date"
                placeholder="Created to"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box p="4">
            <FormControl>
              <FormLabel>Rows</FormLabel>
              <Select
                onChange={(e) => setLimit(parseInt(e.target.value))}
                value={limit}
                width="20"
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </Select>
            </FormControl>
          </Box>
          <Box p="4">
            <FormControl>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon />}
                />
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setQuery(e.target.value)}
                />
              </InputGroup>
            </FormControl>
          </Box>
          <Box
            p="4"
            alignSelf="flex-end"
            className="form-filter-button-wrapper"
          >
            <Button colorScheme="cherryButton" onClick={run}>
              Search
            </Button>
          </Box>
        </Flex>
        <Card width="100%">
          {!summaries && summaryLoading && (
            <Center>
              <Spinner margin="4" />
            </Center>
          )}
          {summaries && !summaryLoading && (
            <Box pl="1" width="100%">
              <TableContainer alignSelf="start" width="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th whiteSpace="nowrap">External Account ID</Th>
                      <Th whiteSpace="nowrap">Program</Th>
                      <Th whiteSpace="nowrap">Account Status</Th>
                      <Th whiteSpace="nowrap">Member</Th>
                      <Th whiteSpace="nowrap">First Name</Th>
                      <Th whiteSpace="nowrap">Last Name</Th>
                      <Th whiteSpace="nowrap">Email Address</Th>
                      <Th whiteSpace="nowrap">Mobile Number</Th>
                      <Th whiteSpace="nowrap">Issued By</Th>
                      <Th whiteSpace="nowrap">Created Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{summaryRows}</Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Card>
        {fetchNextPage && (
          <Box>
            {!summaryLoadingPage && (
              <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                Load more...
              </Button>
            )}
            {summaryLoadingPage && <Spinner />}
          </Box>
        )}
        {summaryError != null && (
          <ErrorMessage>
            An error was encountered while getting report.
          </ErrorMessage>
        )}
      </VStack>
    </>
  );
};
