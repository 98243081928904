import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from "@chakra-ui/modal";
import { ThemingProps } from "@chakra-ui/react";
import React, { useRef } from "react";
import { MemberFilterQueryForm } from "../../forms/MemberFilterQueryForm";

interface MemberFilterQueryModalProps {
  isOpen: boolean;
  memberQueryFilter: string;
  businessId: string;
  onSuccess: (query?: string) => void;
  onClose: () => void;
}

export const MemberFilterQueryModal = ({
  isOpen,
  memberQueryFilter,
  businessId,
  onSuccess,
  onClose,
  ...modalProps
}: MemberFilterQueryModalProps & UseModalProps & ThemingProps<"Modal">) => {
  const initialFocusRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Member Filter Query</ModalHeader>
        <ModalBody>
          <MemberFilterQueryForm
            businessId={businessId}
            query={memberQueryFilter}
            onSuccess={onSuccess}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
