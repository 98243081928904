import { BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { VStack, HStack, Stack } from "@chakra-ui/layout";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { PointsRequestModal } from "../../components/PointsRequestModal/PointsRequestModal";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Card } from "../../components/Card/Card";
import { InvitationModal } from "../../components/InvitationModal/InvitationModal";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import {
  useBusinessContext,
  useMemberContext,
} from "../../context/ModelContext";
import { useModalState } from "../../hooks/useModalState";
import { CardIcon, PlusIcon, TransferIcon, ZapIcon } from "../../styles/icons";
import { TransferPointsModal } from "../../components/TransferPointsModal/TransferPointsModal";
import { CherryPayCardModal } from "../../components/CherryPayCardModal/CherryPayCardModal";
import { usePermissions } from "../../hooks/usePermissions";
import { MemberDetailCards } from "../../components/MemberDetailCard/MemberDetailCard";
import DateUtil from "../../util/DateUtil";
import { MemberContactCards } from "../../components/MemberDetailCard/MemberContactCard";
import { MemberCardList } from "../../components/MemberDetailCard/MemberCardList";
import { DigitalMembershipCardList } from "../../components/MemberDetailCard/DigitalMembershipCardList";

export const MemberDetail = () => {
  const business = useBusinessContext();
  const member = useMemberContext();

  const businessId = business.BusinessId;
  const memberId = member.id;

  const [
    canAwardPoints,
    canInviteMemberToCard,
    canInviteMemberToApp,
    canTransferPointsToMoney,
  ] = usePermissions(
    "Loyalty.CreditPoints",
    "Member.InviteToCard",
    "Member.InviteToApp",
    "Cardholder.PointsToMoney"
  );

  const memberFullName = `${member?.PreferredName ?? member?.FirstName} ${
    member?.LastName
  }`;

  const lastUpdated = DateUtil.getLocalDateFormatFromString(
    member.DateUpdatedAtSource
  );

  const addPointsModalState = useModalState<{
    memberId: string;
    type: "add-points" | "transfer-points-to-cpc";
  }>();
  const invitationModalState = useModalState<{
    memberId: string;
    type: "digital-membership" | "cherry-pay-card";
    cpcType?: "instantgift" | "reloadable";
  }>();

  const onClickAddPoints = useCallback(
    () => addPointsModalState.onOpen({ memberId, type: "add-points" }),
    [addPointsModalState.onOpen, memberId]
  );

  const onClickTransferPoints = useCallback(
    () =>
      addPointsModalState.onOpen({ memberId, type: "transfer-points-to-cpc" }),
    [addPointsModalState.onOpen, memberId]
  );

  const onClickDMCInvite = useCallback(
    () => invitationModalState.onOpen({ memberId, type: "digital-membership" }),
    [invitationModalState.onOpen, memberId]
  );

  const onClickCPCInstantGift = useCallback(
    () =>
      invitationModalState.onOpen({
        memberId,
        type: "cherry-pay-card",
        cpcType: "instantgift",
      }),
    [invitationModalState.onOpen, memberId]
  );

  const onClickCPCReloadable = useCallback(
    () =>
      invitationModalState.onOpen({
        memberId,
        type: "cherry-pay-card",
        cpcType: "reloadable",
      }),
    [invitationModalState.onOpen, memberId]
  );

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${businessId}`}>
              Members
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${businessId}/members/${memberId}`}
            >
              {memberFullName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>View Member</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card w="100%">
          <Stack
            w="100%"
            alignItems="start"
            spacing={{ base: 4, xl: 24 }}
            bgColor="gray.600"
            py={5}
            px={8}
            borderTopStartRadius="3xl"
            borderTopEndRadius="3xl"
            direction={{ base: "column", xl: "row" }}
          >
            <VStack spacing="1" alignItems="flex-start">
              <Text fontSize="2xl" color="white" fontWeight="bold">
                {memberFullName}
              </Text>
              {lastUpdated && (
                <Text variant="small-label">Last Updated : {lastUpdated}</Text>
              )}
            </VStack>

            <VStack
              spacing="1"
              alignSelf={{ base: "flex-start", xl: "flex-end" }}
              alignItems={{ base: "flex-start", xl: "center" }}
            >
              <Text fontSize="xl" color="white" fontWeight="bold">
                {member.MembershipNumber ?? "-"}
              </Text>
              <Text variant="small-label">Membership No</Text>
            </VStack>

            <VStack
              spacing="1"
              alignSelf={{ base: "flex-start", xl: "flex-end" }}
              alignItems={{ base: "flex-start", xl: "center" }}
            >
              <Text fontSize="xl" color="white" fontWeight="bold">
                {member.BadgeNumber ?? "-"}
              </Text>
              <Text variant="small-label">Badge No</Text>
            </VStack>

            <VStack
              spacing="1"
              alignSelf={{ base: "flex-start", xl: "flex-end" }}
              alignItems={{ base: "flex-start", xl: "center" }}
            >
              <Text fontSize="xl" color="white" fontWeight="bold">
                {member.MemberLevelDescription ?? "-"}
              </Text>
              <Text variant="small-label">Level</Text>
            </VStack>

            <VStack
              spacing="1"
              alignSelf={{ base: "flex-start", xl: "flex-end" }}
              alignItems={{ base: "flex-start", xl: "center" }}
            >
              <Text fontSize="xl" color="white" fontWeight="bold">
                {member.Status ?? "-"}
              </Text>
              <Text variant="small-label">Status</Text>
            </VStack>

            <HStack
              flex="1"
              justifyContent="end"
              position="relative"
              zIndex={100}
              alignSelf="center"
            >
              <Menu>
                <MenuButton
                  leftIcon={<ZapIcon />}
                  colorScheme="cherryButton"
                  as={Button}
                  aria-label="Member Actions"
                >
                  Member Actions
                </MenuButton>
                <MenuList>
                  {canInviteMemberToApp && (
                    <MenuItem onClick={onClickDMCInvite} icon={<CardIcon />}>
                      Digital Membership Card
                    </MenuItem>
                  )}
                  {canInviteMemberToCard && (
                    <>
                      <MenuItem
                        onClick={onClickCPCInstantGift}
                        icon={<CardIcon />}
                      >
                        CherryPay Instant Gift Card
                      </MenuItem>
                      <MenuItem
                        onClick={onClickCPCReloadable}
                        icon={<CardIcon />}
                      >
                        CherryPay Reloadable Card
                      </MenuItem>
                    </>
                  )}
                  {canTransferPointsToMoney && (
                    <MenuItem
                      onClick={onClickTransferPoints}
                      icon={<TransferIcon />}
                    >
                      Transfer Points
                    </MenuItem>
                  )}
                  {canAwardPoints && (
                    <MenuItem onClick={onClickAddPoints} icon={<PlusIcon />}>
                      Award Points
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          </Stack>

          <Tabs variant="edit" w="100%" isLazy>
            <TabList>
              <HStack spacing={3} overflowX="auto">
                <Tab>Details</Tab>

                <Tab>Contact</Tab>

                <Tab>Cards</Tab>

                <Tab>Digital Membership Cards</Tab>
              </HStack>
            </TabList>

            <TabPanels>
              <TabPanel>
                <MemberDetailCards {...addPointsModalState} />
              </TabPanel>

              <TabPanel>
                <MemberContactCards />
              </TabPanel>

              <TabPanel>
                <MemberCardList businessId={businessId} memberId={memberId} />
              </TabPanel>

              <TabPanel>
                <DigitalMembershipCardList
                  businessId={businessId}
                  memberId={memberId}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Card>
      </PageContent>

      <TransferPointsModal
        isOpen={
          addPointsModalState.isOpen &&
          addPointsModalState.opts?.type === "transfer-points-to-cpc"
        }
        memberId={addPointsModalState.opts?.memberId}
        onClose={addPointsModalState.onClose}
      />

      <PointsRequestModal
        isOpen={
          addPointsModalState.isOpen &&
          addPointsModalState.opts?.type === "add-points"
        }
        memberId={addPointsModalState.opts?.memberId}
        pointsRequestType="add-points"
        onClose={addPointsModalState.onClose}
      />

      <InvitationModal
        isOpen={
          invitationModalState.isOpen &&
          invitationModalState.opts?.type === "digital-membership"
        }
        memberId={invitationModalState.opts?.memberId}
        invitationType={invitationModalState.opts?.type}
        onClose={invitationModalState.onClose}
      />

      <CherryPayCardModal
        isOpen={
          invitationModalState.isOpen &&
          invitationModalState.opts?.type === "cherry-pay-card"
        }
        cpcType={invitationModalState.opts?.cpcType ?? "instantgift"}
        memberId={invitationModalState.opts?.memberId}
        onClose={invitationModalState.onClose}
      />
    </>
  );
};
