import React, { useEffect, useState } from "react";

export const PassStyleApplePrimaryLabel = {
  fontSize: "50px",
  lineHeight: "48px",
  mb: "2px",
  fontWeight: "100",
};
export const PassStyleApplePrimaryValue = {
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: "100",
};

export const EditableHoverStyle = {
  bg: "rgba(0,0,0,0.02)",
  boxShadow: "0px 0px 2px rgba(196,24,54,0.9)",
};

export const PassStyleGooglePrimaryValue = {
  fontSize: "30px",
  lineHeight: "30px",
  mb: "20px",
  fontWeight: "100",
};
