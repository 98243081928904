import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AppRoutes } from "./Routes";
import { FirebaseProvider } from "./context/FirebaseContext";
import { ErrorHandler } from "./components/ErrorBoundary/ErrorBoundary";
import { UserContextProvider } from "./context/UserContext";
import { ThemeProvider } from "./components/ThemeProvider/ThemeProvider";
import { LayoutContextProvider } from "./context/LayoutContext";

interface AppProps {
  /** Optional replacement react-router implementation. */
  router?: React.ComponentType<any>;

  /** Forces app to render desktop layout */
  forceDesktopLayout?: boolean;
}

export const App = ({ router, forceDesktopLayout }: AppProps) => {
  // Allows the Router implementation to be replaced by tests.
  const Router = router ?? BrowserRouter;

  return (
    <div>
      <ErrorHandler
        renderErrorState={(onReset) => {
          return <button onClick={onReset}>Reset</button>;
        }}
      >
        <FirebaseProvider>
          <ThemeProvider>
            <LayoutContextProvider forceDesktopLayout={forceDesktopLayout}>
              <UserContextProvider>
                <Router>
                  <AppRoutes />
                </Router>
              </UserContextProvider>
            </LayoutContextProvider>
          </ThemeProvider>
        </FirebaseProvider>
      </ErrorHandler>
    </div>
  );
};
