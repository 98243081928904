import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { Box, Button, HStack, VStack, Text, Tag } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { CreditCardIcon, GiftCardIcon } from "../../styles/icons";
import { useModalState } from "../../hooks/useModalState";
import { NonMemberCardModal } from "../../components/NonMemberCardModal/NonMemberCardModal";
import { useApiClient } from "../../hooks/useApiClient";
import { CherryPayApi } from "../../api/models";
import { CardSummary } from "../../components/CardSummary/CardSummary";

export const IssueCards = () => {
  const { userBusinessId } = useUserContext();
  const business = useBusinessContext();
  const navigate = useNavigate();
  const [configs, setConfigs] = useState<CherryPayApi.CardProgram[]>();
  // load configuration for first time
  const apiClient = useApiClient();
  const fetchConfig = async () => {
    const result = await apiClient.getConfiguration(business.BusinessId);
    if (result.ok) {
      setConfigs(result.data?.CardPrograms);
    }
  };
  useEffect(() => {
    fetchConfig();
  }, []);

  const moveToCardOrder = (e: React.MouseEvent<HTMLElement>) => {
    if (
      e.currentTarget.dataset.businessId &&
      e.currentTarget.dataset.cardProgramId
    ) {
      navigate(
        `/businesses/${e.currentTarget.dataset.businessId}/cards/order/${e.currentTarget.dataset.cardProgramId}`
      );
    }
  };

  const buttons = useMemo(
    () =>
      configs?.map((item) => {
        if (item.IsPhysicalEnabled) {
          return (
            <Box
              key={item.CardProgramId}
              bgColor="cherryUi.600"
              width="250px"
              height="170px"
              pt="50px"
              borderRadius="lg"
              _hover={{ bg: "cherryUi.800" }}
              as={Button}
              data-business-id={business.BusinessId}
              data-card-program-id={item.CardProgramId}
              onClick={moveToCardOrder}
              padding={1}
            >
              <VStack spacing={3}>
                {item.ProgramType === "Reloadable" && (
                  <CreditCardIcon w={6} h={6} color="white" boxSize="40px" />
                )}
                {item.ProgramType === "InstantGift" && (
                  <GiftCardIcon w={6} h={6} color="white" boxSize="40px" />
                )}
                <Text align="center" color="white">
                  Order <br /> {item.Description}
                </Text>
                <HStack>
                  {item.DeliveryTypes?.map((d) => (
                    <Tag size="sm" key={d}>
                      {d}
                    </Tag>
                  ))}
                </HStack>
              </VStack>
            </Box>
          );
        }
        return (
          <Box
            key={item.CardProgramId}
            bgColor="cherryUi.600"
            width="250px"
            height="170px"
            pt="50px"
            borderRadius="lg"
            as={Button}
            _hover={{ bg: "cherryUi.800" }}
            padding={1}
            onClick={() => {
              if (item.ProgramType == "Reloadable") {
                invitationModalState.onOpen({
                  cpcType: "reloadable",
                });
              } else {
                invitationModalState.onOpen({
                  cpcType: "instantgift",
                });
              }
            }}
          >
            <VStack spacing={3}>
              {item.ProgramType === "Reloadable" && (
                <CreditCardIcon w={6} h={6} color="white" boxSize="40px" />
              )}
              {item.ProgramType === "InstantGift" && (
                <GiftCardIcon w={6} h={6} color="white" boxSize="40px" />
              )}
              <Text align="center" color="white">
                Send <br /> {item.Description}
              </Text>
              <HStack>
                {item.DeliveryTypes?.map((d) => (
                  <Tag size="sm" key={d}>
                    {d}
                  </Tag>
                ))}
              </HStack>
            </VStack>
          </Box>
        );
      }),
    [configs]
  );

  const invitationModalState = useModalState<{
    cpcType: "instantgift" | "reloadable";
  }>();
  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>Cards</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <HStack>{buttons}</HStack>
        <CardSummary configs={configs} businessId={business.BusinessId} />
      </PageContent>
      <NonMemberCardModal
        isOpen={invitationModalState.isOpen}
        cpcType={invitationModalState.opts?.cpcType ?? "instantgift"}
        onClose={invitationModalState.onClose}
        businessId={business.BusinessId}
        configs={configs}
      />
    </>
  );
};
