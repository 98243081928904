import React from "react";

import { Text, VStack } from "@chakra-ui/layout";
import { Button, HStack, Spinner } from "@chakra-ui/react";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { RoleDiffStats } from "../../util/roleDiff";

export const RoleDiff = ({
  diff,
  onSave,
  onCancel,
  isLoading,
}: {
  diff: RoleDiffStats;
  onSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  return (
    <Alert status="warning" alignItems="start">
      <AlertIcon />
      <VStack spacing="0" flex="1">
        <Text w="100%">There are unsaved role changes.</Text>
        {diff.added > 0 && <Text w="100%">{diff.added} role added.</Text>}
        {diff.updated > 0 && <Text w="100%">{diff.updated} role updated.</Text>}
        {diff.removed > 0 && <Text w="100%">{diff.removed} role removed.</Text>}
      </VStack>
      <HStack>
        <Button
          colorScheme="cherryButton"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onSave}
        >
          Save
        </Button>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </Alert>
  );
};
