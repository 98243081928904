import {
  Heading,
  VStack,
  Text,
  Spinner,
  IconButton,
  Box,
  Stack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import React, { useMemo } from "react";
import {
  useBusinessContext,
  useMemberContext,
} from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useModalCloseEffect } from "../../hooks/useModalCloseEffect";
import { CheckCircleIcon, RefreshIcon, XCircleIcon } from "../../styles/icons";
import DateUtil from "../../util/DateUtil";
import { ViewControl } from "../ViewControl/ViewControl";

const PointBalance = ({
  balanceStatus,
}: {
  balanceStatus: {
    label: string;
    value: string;
    onRefresh: () => void;
    isLoading?: boolean;
  };
}) => (
  <>
    <ViewControl
      label={balanceStatus.isLoading ? "Loading..." : balanceStatus.label}
    >
      <Box flex={3}>
        {balanceStatus.isLoading ? (
          <Spinner color="#344" />
        ) : (
          <Text variant="view-value">
            {balanceStatus.value}{" "}
            <IconButton
              aria-label="Refresh balance"
              title="Refresh balance"
              size="xs"
              icon={<RefreshIcon />}
              variant="ghost"
              onClick={balanceStatus.onRefresh}
            />
          </Text>
        )}
      </Box>
    </ViewControl>
  </>
);

export const MemberDetailCards = (modalState: {
  onOpen: (opts: {
    memberId: string;
    type: "add-points" | "transfer-points-to-cpc";
  }) => void;
  onClose: () => void;
  isOpen: boolean;
  opts: {
    memberId: string;
    type: "add-points" | "transfer-points-to-cpc";
  } | null;
}) => {
  const member = useMemberContext();
  const business = useBusinessContext();
  const memberId = member.id;
  const businessId = business.BusinessId;

  const pointsBalanceRequest = useApiRequest(
    (apiClient) => apiClient.getDefaultPointsBalance(businessId, memberId),
    [businessId, memberId]
  );

  useModalCloseEffect(
    modalState,
    () => {
      pointsBalanceRequest.refresh();
    },
    [pointsBalanceRequest.refresh]
  );

  const memberBalance: {
    label: string;
    value: string;
    onRefresh: () => void;
    isLoading?: boolean;
  } = useMemo(() => {
    const pointsBalanceError =
      !pointsBalanceRequest.isLoading &&
      !pointsBalanceRequest.data &&
      pointsBalanceRequest.statusCode !== null &&
      pointsBalanceRequest.statusCode > 299;
    return {
      label: pointsBalanceError
        ? "Points Balance:"
        : `${pointsBalanceRequest.data?.DisplayName ?? ""} Points Balance`,
      value: pointsBalanceError
        ? "?"
        : `$${new BigNumber(
            pointsBalanceRequest.data?.PointsBalanceDollarValue ?? 0
          ).toFormat(2)}`,
      isLoading: pointsBalanceRequest.isLoading,
      onRefresh: () => {
        pointsBalanceRequest.refresh();
      },
    };
  }, [pointsBalanceRequest]);

  const formattedDateJoined = DateUtil.getLocalDateFormatFromString(
    member.DateJoined
  );
  const formattedRenewedAt = DateUtil.getLocalDateFormatFromString(
    member.DateRenewed
  );
  const formattedFinancialDate = DateUtil.getLocalDateFormatFromString(
    member.DateFinancial
  );
  const birthDate = DateUtil.formatDate(member.BirthDate, undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <Stack w="100%" align="start" spacing={2} direction={["column", "row"]}>
      <VStack align="start" w="100%">
        <Heading variant="view-heading">Personal</Heading>
        <VStack align="start" w="100%" spacing={6} pb={10}>
          <ViewControl label="Title:" value={member.Title ?? "-"} />

          <ViewControl label="First Name:" value={member.FirstName ?? "-"} />

          <ViewControl
            label="Preferred Name:"
            value={member.PreferredName ?? "-"}
          />

          <ViewControl label="Middle Name:" value={member.MiddleName ?? "-"} />

          <ViewControl label="Last Name:" value={member.LastName ?? "-"} />

          <ViewControl label="Birth Date:" value={birthDate ?? "-"} />

          <ViewControl label="Gender:" value={member.Gender ?? "-"} />
        </VStack>

        <Heading variant="view-heading">Other</Heading>
        <VStack align="start" w="100%" spacing={6} pb={10}>
          <ViewControl label="Occupation:" value={member.Occupation ?? "-"} />

          <ViewControl label="Nationality:" value={member.Nationality ?? "-"} />
        </VStack>
      </VStack>

      <VStack align="start" w="100%">
        <Heading variant="view-heading">Membership</Heading>
        <VStack align="start" w="100%" spacing={6} pb={10}>
          <ViewControl label="Card Number:" value={member.CardNumber ?? "-"} />

          <ViewControl
            label="Financial:"
            value={
              member.Financial ? (
                <CheckCircleIcon color="whatsapp.500" />
              ) : (
                <XCircleIcon color="red" />
              )
            }
          />

          <ViewControl
            label="Member Level:"
            value={member.MemberLevelDescription ?? "-"}
          />

          <ViewControl
            label="Membership Type:"
            value={member.MembershipTypeDescription ?? "-"}
          />

          <ViewControl label="Status:" value={member.Status ?? "-"} />

          <ViewControl label="Joined At:" value={formattedDateJoined ?? "-"} />

          <ViewControl label="Renewed At:" value={formattedRenewedAt ?? "-"} />

          <ViewControl
            label="Financial To:"
            value={formattedFinancialDate ?? "-"}
          />
        </VStack>

        <Heading variant="view-heading">Loyalty</Heading>
        <VStack align="start" w="100%" spacing={6} pb={10}>
          <PointBalance balanceStatus={memberBalance} />
        </VStack>
      </VStack>
    </Stack>
  );
};
