import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import sampleQRImage from "assets/QR Code.png";
import {
  convertARGBtoHex,
  convertBufferToImage,
  getPassImage,
  isLightColor,
  getProcessedStringFn,
} from "../../util/passHelper";
import {
  EditableHoverStyle,
  PassStyleApplePrimaryLabel,
  PassStyleApplePrimaryValue,
  PassStyleGooglePrimaryValue,
} from "./styles";
import { PassConfig } from "../../pages/PassEditor/PassEditor";
import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import {
  FaEnvelope,
  FaGlobe,
  FaInfoCircle,
  FaPlusCircle,
} from "react-icons/fa";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { FiGlobe, FiMessageSquare } from "react-icons/fi";

interface PassPreviewParams {
  style: "apple" | "google";
  onSelectProps: (params: any) => void;
  config: PassConfig;
  tokens?: any;
  openThemePanel?: (idx: number) => void;
  openSettingsPanel?: () => void;
}

export const PassPreview = (props: PassPreviewParams) => {
  const {
    style,
    config,
    onSelectProps,
    tokens,
    openThemePanel,
    openSettingsPanel,
  } = props;

  const processedString = useCallback(
    (text: string) => {
      return getProcessedStringFn(tokens)(text);
    },
    [tokens]
  );

  const findAppleBg = () => {
    const imageType = "AppleStrip";
    const passImage = config.passImages.find(
      (img) => img.imageType === imageType && img.imageData.status !== 404
    );
    return passImage;
  };
  const getAppleStrip = () => {
    const imageType = "AppleStrip";
    return `url("${getPassImage(config.passImages, imageType)}")`;
  };
  const getGoogleStrip = () => {
    const imageType = "GoogleHeroImage";
    return `url("${getPassImage(config.passImages, imageType)}")`;
  };

  const getAppleBgColor = () => {
    return convertARGBtoHex(config.data.BackgroundColour);
  };
  const getGoogleBgColor = () => {
    return convertARGBtoHex(config.data.GooglePayPassBackgroundColour);
  };

  const getAppleFgColor = () => {
    return convertARGBtoHex(config.data.ForegroundColour);
  };
  const getGoogleFgColor = () => {
    const bgColor = config.data.GooglePayPassBackgroundColour;
    if (isLightColor(bgColor)) {
      return "#333333";
    } else {
      return "#FFFFFF";
    }
  };
  const getAppleLogo = () => {
    const imageType = "AppleLogo";
    return getPassImage(config.passImages, imageType);
  };
  const getGoogleLogo = () => {
    const imageType = "GoogleProgramLogo";
    return getPassImage(config.passImages, imageType);
  };
  const addAdditionalField = (fieldLocation = "Back") => {
    const newField = {
      _id: Math.random(),
      _saved: false,
      Label: "Label",
      Value: "Value",
      FieldLocation: fieldLocation,
    };

    if (fieldLocation === "Back") {
      config.data.iOSAdditionalFields.push(newField);
    } else {
      // find existing Secondary field
      const firstSecondaryFieldIdx = config.data.iOSAdditionalFields.findIndex(
        (f: any) => f.FieldLocation !== "Back"
      );
      if (firstSecondaryFieldIdx !== -1) {
        config.data.iOSAdditionalFields.splice(
          firstSecondaryFieldIdx + 1,
          0,
          newField
        );
      }
    }
    onSelectProps({
      selectedField: "iOSAdditionalFields",
      fieldObject: newField,
      selectedFieldTitle: "Field Details",
      canDelete: true,
      isDateFormatAvailable: true,
    });
  };
  const addAdditionalFieldGoogle = (position = "Back") => {
    const newField = {
      _id: Math.random(),
      _saved: false,
      Label: "Label",
      Value: "Value",
      position: position,
    };

    if (position === "Back") {
      config.data.ProcessedGooglePassMessages.push(newField);
    } else {
      config.data.ProcessedGooglePayPassCardFrontLabels.push(newField);
    }

    onSelectProps({
      selectedField:
        position === "Back"
          ? "ProcessedGooglePassMessages"
          : "ProcessedGooglePayPassCardFrontLabels",
      fieldObject: newField,
      selectedFieldTitle: "Field Details",
      canDelete: true,
      isDateFormatAvailable: true,
    });
  };

  const addAdditionalLinkGoogle = (position = "Back") => {
    const newField = {
      _id: Math.random(),
      _saved: false,
      Description: "Description",
      UriValue: "URI",
    };

    config.data.ProcessedGooglePassLinks.push(newField);

    onSelectProps({
      selectedField: "ProcessedGooglePassLinks",
      fieldObject: newField,
      selectedFieldTitle: "Link Details",
      canDelete: true,
      labelKey: "Description",
      valueKey: "UriValue",
      isTokensForbidden: true,
    });
  };

  const getFrontAdditionalFieldsApple = () => {
    const fields = config.data.iOSAdditionalFields.filter(
      (f: any) => f.FieldLocation !== "Back"
    );
    if (fields.length >= 3) {
      return fields;
    }
    const emptyField = { _isEmpty: true, Label: "New" };

    if (fields.length === 2) {
      return [fields[0], { ...emptyField }, fields[1]];
    }
    if (fields.length === 1) {
      return [fields[0], { ...emptyField }];
    }
  };

  const getFrontAdditionalFieldsGoogle = () => {
    const fields = config.data.ProcessedGooglePayPassCardFrontLabels.filter(
      (f: any) => f.FieldLocation !== "Back"
    );
    if (fields.length >= 2) {
      return fields;
    }
    const emptyField = { _isEmpty: true, Label: "New" };

    if (fields.length === 1) {
      fields[0].position = "Left";
      return [fields[0], { ...emptyField, position: "Right" }];
    }
    if (fields.length === 0) {
      return [{ ...emptyField, position: "Left" }];
    }
  };

  // const getFrontAdditionalFieldsGoogle = () => {
  //   const fields = Object.keys(config.data.GooglePayPassCardFrontLabels || {});
  //   const actualFieldsObject = config.data.GooglePayPassCardFrontLabels;

  //   const emptyField = { _isEmpty: true, Label: "New" };

  //   const returnObj: any = {};

  //   if (actualFieldsObject.Left) {
  //     returnObj.Left = actualFieldsObject.Left;
  //     returnObj.Right = actualFieldsObject.Right || emptyField;
  //   } else if (actualFieldsObject.Right) {
  //     returnObj.Left = actualFieldsObject.Right;
  //     returnObj.Right = emptyField;
  //   } else {
  //     returnObj.Left = emptyField;
  //   }

  //   return returnObj;

  // };

  return (
    <Box>
      <Box p="20px" bg="#fff">
        <Flex w="100%" flex="1" justifyContent="space-evenly">
          <Box w="100%" p="4px">
            <Text height="40px" textAlign="center" mb="10px" color="#888">
              CARD FRONT
            </Text>

            {/* Apple pass front */}
            {style === "apple" && (
              <Box
                boxShadow="1px 1px 10px rgba(0,0,0,0.1)"
                borderRadius="10px"
                overflow="hidden"
                minH="560px"
                w="400px"
                m="0 auto"
                bg={getAppleBgColor()}
                color={getAppleFgColor()}
              >
                {/* top strip */}
                <Flex m="10px" alignItems="center">
                  <Box
                    cursor="pointer"
                    onClick={(e) => (openThemePanel ? openThemePanel(1) : null)}
                    _hover={{ opacity: 0.8 }}
                  >
                    <Image maxH="50px" src={getAppleLogo()} />
                  </Box>
                  <Box
                    _hover={{
                      ...EditableHoverStyle,
                      bg: "rgba(255,255,255,0.2)",
                      boxShadow: "0px 0px 4px rgba(196,24,54,0.9)",
                    }}
                    cursor="pointer"
                    p="3px 7px"
                    ms="5px"
                    onClick={() =>
                      onSelectProps({
                        selectedField: "iOSLogoText",
                        selectedFieldTitle: "Logo Text",
                        isSingleValue: true,
                      })
                    }
                    {...(config.data.iOSLogoText
                      ? {}
                      : {
                          border: "1px dashed #777",
                          color: "#777",
                        })}
                  >
                    <Text fontSize="16px" fontWeight="400">
                      {(config.data.iOSLogoText || "Add Text").substr(0, 12)}
                    </Text>
                  </Box>
                </Flex>

                {/* middle strip */}
                <Box
                  h="157px"
                  w="400px"
                  p="6px 6px"
                  style={{
                    backgroundImage: getAppleStrip(),
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    color: findAppleBg() ? "#fff" : getAppleFgColor(),
                  }}
                  cursor="pointer"
                  onClick={() =>
                    onSelectProps({
                      selectedField: "iOSPrimaryField",
                      selectedFieldTitle: "iOS Primary Field",
                    })
                  }
                >
                  <Box
                    p="10px 10px"
                    _hover={{
                      ...EditableHoverStyle,
                      bg: "rgba(255,255,255,0.2)",
                      boxShadow: "0px 0px 4px rgba(196,24,54,0.9)",
                    }}
                  >
                    <Text
                      {...PassStyleApplePrimaryLabel}
                      dangerouslySetInnerHTML={{
                        __html: processedString(
                          config.data.iOSPrimaryField.Label
                        ),
                      }}
                    ></Text>
                    <Text
                      {...PassStyleApplePrimaryValue}
                      dangerouslySetInnerHTML={{
                        __html: processedString(
                          config.data.iOSPrimaryField.Value
                        ),
                      }}
                    ></Text>
                  </Box>
                </Box>

                {/* third strip */}
                <Box mt="10px">
                  <Flex w="100%" justifyContent="space-between">
                    {getFrontAdditionalFieldsApple().map(
                      (iOSAdditionalField: any) =>
                        !iOSAdditionalField._isEmpty ? (
                          <Box
                            key={iOSAdditionalField.Label}
                            cursor="pointer"
                            mb="16px"
                            py="5px"
                            px="7px"
                            mx="5px"
                            fontSize="13px"
                            _hover={EditableHoverStyle}
                            onClick={() =>
                              onSelectProps({
                                selectedField: "iOSAdditionalFields",
                                fieldObject: iOSAdditionalField,
                                selectedFieldTitle: "Field Details",
                                canDelete: true,
                                isDateFormatAvailable: true,
                              })
                            }
                          >
                            <Text
                              textAlign="left"
                              fontSize="12px"
                              lineHeight="13px"
                              fontWeight="bold"
                              dangerouslySetInnerHTML={{
                                __html: processedString(
                                  iOSAdditionalField.Label
                                ).toUpperCase(),
                              }}
                            ></Text>
                            <Text
                              textAlign="left"
                              fontSize="22px"
                              dangerouslySetInnerHTML={{
                                __html: processedString(
                                  iOSAdditionalField.Value
                                ).substring(0, 10),
                              }}
                            ></Text>
                          </Box>
                        ) : (
                          <Box
                            cursor="pointer"
                            mb="16px"
                            py="5px"
                            px="7px"
                            mx="5px"
                            fontSize="13px"
                          >
                            <Box
                              mt="10px"
                              as={FaPlusCircle}
                              fontSize="30px"
                              cursor="pointer"
                              onClick={() => addAdditionalField("Secondary")}
                              opacity="0.3"
                              _hover={{
                                opacity: 1,
                                color: "var(--chakra-colors-cherryButton-500)",
                              }}
                              color="#ccc"
                            />
                            {/* <IconButton
                            variant="unstyled"
                            textAlign="center"
                            icon={
                              <FaPlusCircle color="var(--chakra-colors-cherryButton-500)" />
                            }
                            title="Add New Field"
                            onClick={() => addAdditionalField()}
                            aria-label="Add"
                          /> */}
                          </Box>
                        )
                    )}
                  </Flex>
                </Box>

                {/* code strip */}
                <Box mt="80px">
                  {config.data.BarcodeType !== "None" && (
                    <Box
                      display="inline"
                      m="0 auto"
                      _hover={{ opacity: 0.8 }}
                      cursor="pointer"
                      onClick={() =>
                        onSelectProps({
                          selectedField: "BarcodeDataFormat",
                          selectedFieldTitle: "Barcode Data",
                          isSingleValue: true,
                        })
                      }
                    >
                      <Image m="0 auto" src={sampleQRImage} w="125px" />
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            {/* Google pass front */}
            {style === "google" && (
              <Box
                boxShadow="1px 1px 10px rgba(0,0,0,0.1)"
                borderRadius="10px"
                overflow="hidden"
                minH="300px"
                w="400px"
                m="0 auto"
                bg={getGoogleBgColor()}
                color={getGoogleFgColor()}
              >
                {/* top strip */}
                <Flex m="15px" alignItems="center">
                  <Box
                    cursor="pointer"
                    onClick={(e) => (openThemePanel ? openThemePanel(1) : null)}
                    _hover={{ opacity: 0.8 }}
                  >
                    <Image
                      borderRadius="99%"
                      maxH="40px"
                      src={getGoogleLogo()}
                    />
                  </Box>
                  <Box
                    mx="20px"
                    my="5px"
                    _hover={EditableHoverStyle}
                    onClick={(e) => {
                      openSettingsPanel ? openSettingsPanel() : null;
                      if (document) {
                        setTimeout(() => {
                          document
                            .getElementById("BusinessNameOverride")
                            ?.focus();

                          setTimeout(() => {
                            document
                              .getElementById("BusinessNameOverride")
                              ?.focus();
                          }, 400);
                        }, 600);
                      }
                    }}
                    cursor="pointer"
                  >
                    <Text fontSize="20px">
                      {config.data.BusinessNameOverride || "BUSINESS NAME"}
                    </Text>
                  </Box>
                </Flex>

                <Divider borderColor="#aaa" />

                <Box
                  m="5px"
                  p="10px"
                  _hover={EditableHoverStyle}
                  cursor="pointer"
                  onClick={() =>
                    onSelectProps({
                      selectedField: "PassDescription",
                      selectedFieldTitle: "Pass Description",
                      isSingleValue: true,
                    })
                  }
                >
                  <Text
                    {...PassStyleGooglePrimaryValue}
                    dangerouslySetInnerHTML={{
                      __html: processedString(config.data.PassDescription),
                    }}
                  />
                </Box>

                {/* third strip */}
                <Box mt="10px">
                  <Flex w="100%" justifyContent="space-between">
                    {getFrontAdditionalFieldsGoogle().map((field: any) =>
                      !field._isEmpty ? (
                        <Box
                          key={field.Label}
                          cursor="pointer"
                          mb="16px"
                          py="5px"
                          px="7px"
                          mx="5px"
                          fontSize="13px"
                          _hover={EditableHoverStyle}
                          onClick={() =>
                            onSelectProps({
                              selectedField:
                                "ProcessedGooglePayPassCardFrontLabels",
                              fieldObject: field,
                              selectedFieldTitle: "Field Details",
                              canDelete: true,
                              isDateFormatAvailable: true,
                            })
                          }
                        >
                          <Text
                            textAlign="left"
                            fontSize="12px"
                            lineHeight="13px"
                            fontWeight="bold"
                            dangerouslySetInnerHTML={{
                              __html: processedString(
                                field.Label
                              ).toUpperCase(),
                            }}
                          ></Text>
                          <Text
                            textAlign="left"
                            fontSize="22px"
                            dangerouslySetInnerHTML={{
                              __html: processedString(field.Value).substring(
                                0,
                                10
                              ),
                            }}
                          ></Text>
                        </Box>
                      ) : (
                        <Box
                          cursor="pointer"
                          mb="16px"
                          py="5px"
                          px="7px"
                          mx="5px"
                          fontSize="13px"
                        >
                          <Box
                            mt="10px"
                            as={FaPlusCircle}
                            fontSize="30px"
                            cursor="pointer"
                            onClick={() =>
                              addAdditionalFieldGoogle(field.position)
                            }
                            opacity="0.3"
                            _hover={{
                              opacity: 1,
                              color: "var(--chakra-colors-cherryButton-500)",
                            }}
                            color="#ccc"
                          />
                          {/* <IconButton
                            variant="unstyled"
                            textAlign="center"
                            icon={
                              <FaPlusCircle color="var(--chakra-colors-cherryButton-500)" />
                            }
                            title="Add New Field"
                            onClick={() => addAdditionalField()}
                            aria-label="Add"
                          /> */}
                        </Box>
                      )
                    )}
                  </Flex>
                </Box>

                {/* code strip */}
                <Box mt="20px">
                  {config.data.BarcodeType !== "None" && (
                    <Box
                      display="inline"
                      m="0 auto"
                      _hover={{ opacity: 0.8 }}
                      cursor="pointer"
                      onClick={() =>
                        onSelectProps({
                          selectedField: "BarcodeDataFormat",
                          selectedFieldTitle: "Barcode Data",
                          isSingleValue: true,
                        })
                      }
                    >
                      <Image m="0 auto" src={sampleQRImage} w="125px" />
                    </Box>
                  )}

                  <Box
                    p="7px"
                    m="3px"
                    _hover={EditableHoverStyle}
                    cursor="pointer"
                    onClick={() =>
                      onSelectProps({
                        selectedField: "BarcodeDataLabelFormat",
                        selectedFieldTitle: "Barcode Data Label",
                        isSingleValue: true,
                      })
                    }
                  >
                    <Text
                      textAlign="center"
                      fontSize="16px"
                      dangerouslySetInnerHTML={{
                        __html: processedString(
                          config.data.BarcodeDataLabelFormat
                        ),
                      }}
                    />
                  </Box>
                </Box>

                {/* Hero image strip */}
                {getPassImage(config.passImages, "GoogleHeroImage") && (
                  <Box
                    h="157px"
                    w="400px"
                    p="6px 6px"
                    cursor="pointer"
                    onClick={(e) => (openThemePanel ? openThemePanel(1) : null)}
                    _hover={{ opacity: 0.8 }}
                    style={{
                      backgroundImage: getGoogleStrip(),
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></Box>
                )}
              </Box>
            )}
          </Box>

          <Box w="100%" p="4px">
            <Flex alignItems="center" justifyContent="center" mb="10px">
              <Text textAlign="center" color="#888">
                DETAILS
              </Text>
              <IconButton
                variant="unstyled"
                icon={
                  <FaPlusCircle color="var(--chakra-colors-cherryButton-500)" />
                }
                ml="10px"
                title="Add New Field"
                onClick={() =>
                  style === "apple"
                    ? addAdditionalField()
                    : addAdditionalFieldGoogle()
                }
                aria-label="Add"
              />
            </Flex>

            {/* Apple pass back */}
            {style === "apple" && (
              <Box
                boxShadow="1px 1px 10px rgba(0,0,0,0.1)"
                borderRadius="10px"
                minH="400px"
                w="400px"
                m="0 auto"
                p="15px"
              >
                {config.data.iOSAdditionalFields
                  .filter((f: any) => f.FieldLocation === "Back")
                  .map((iOSAdditionalField: any) => (
                    <Flex
                      flexDir={
                        processedString(iOSAdditionalField.Label).length > 40
                          ? "column"
                          : "row"
                      }
                      key={iOSAdditionalField.Label}
                      cursor="pointer"
                      my="8px"
                      p="5px"
                      alignItems={
                        processedString(iOSAdditionalField.Label).length > 40
                          ? "start"
                          : "center"
                      }
                      fontSize="13px"
                      lineHeight="16px"
                      _hover={EditableHoverStyle}
                      borderBottom="1px solid #eee"
                      onClick={() =>
                        onSelectProps({
                          selectedField: "iOSAdditionalFields",
                          fieldObject: iOSAdditionalField,
                          selectedFieldTitle: "Field Details",
                          canDelete: true,
                        })
                      }
                    >
                      <Text
                        mb={
                          processedString(iOSAdditionalField.Label).length > 40
                            ? "8px"
                            : "0"
                        }
                        flex="1"
                        dangerouslySetInnerHTML={{
                          __html: processedString(iOSAdditionalField.Label),
                        }}
                      ></Text>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: processedString(iOSAdditionalField.Value),
                        }}
                      ></Text>
                    </Flex>
                  ))}
              </Box>
            )}

            {/* Google pass back */}
            {style === "google" && (
              <Box
                boxShadow="1px 1px 10px rgba(0,0,0,0.1)"
                borderRadius="10px"
                minH="400px"
                w="400px"
                m="0 auto"
                p="15px"
              >
                {/* static values */}
                <Box>
                  <Flex
                    flexDir="column"
                    my="10px"
                    p="5px"
                    alignItems="start"
                    fontSize="14px"
                    lineHeight="18px"
                    cursor="pointer"
                    onClick={() =>
                      onSelectProps({
                        selectedField: "GooglePayPassAccountOwnerName",
                        selectedFieldTitle: "Member Name",
                        isSingleValue: true,
                      })
                    }
                    _hover={EditableHoverStyle}
                  >
                    <Text mb="4px" fontWeight="bold" flex="1">
                      Member Name
                    </Text>
                    <Text>
                      {processedString(
                        config.data.GooglePayPassAccountOwnerName
                      )}
                    </Text>
                  </Flex>

                  <Flex
                    flexDir="column"
                    my="10px"
                    p="5px"
                    alignItems="start"
                    fontSize="14px"
                    lineHeight="18px"
                    cursor="pointer"
                    onClick={() =>
                      onSelectProps({
                        selectedField: "GooglePayPassAccountId",
                        selectedFieldTitle: "Member Name",
                        isSingleValue: true,
                      })
                    }
                    _hover={EditableHoverStyle}
                  >
                    <Text mb="4px" fontWeight="bold" flex="1">
                      Member ID
                    </Text>
                    <Text>
                      {processedString(config.data.GooglePayPassAccountId)}
                    </Text>
                  </Flex>
                </Box>

                {/* dynamic values */}
                {config.data.ProcessedGooglePassMessages.filter(
                  (f: any) => f
                ).map((iOSAdditionalField: any) =>
                  iOSAdditionalField.isDivider ? (
                    <Divider my="10px" />
                  ) : (
                    <Flex alignItems="center" my="10px">
                      <Box
                        flex="1"
                        key={iOSAdditionalField.Label}
                        cursor="pointer"
                        p="5px"
                        alignItems="start"
                        fontSize="14px"
                        lineHeight="18px"
                        _hover={EditableHoverStyle}
                        onClick={() =>
                          onSelectProps({
                            selectedField: "ProcessedGooglePassMessages",
                            fieldObject: iOSAdditionalField,
                            selectedFieldTitle: "Field Details",
                            canDelete: true,
                          })
                        }
                      >
                        <Flex>
                          <Text
                            flex="1"
                            mb="4px"
                            fontWeight="bold"
                            dangerouslySetInnerHTML={{
                              __html: processedString(iOSAdditionalField.Label),
                            }}
                          ></Text>
                          {iOSAdditionalField.isEachMember && (
                            <Box>
                              <Tooltip label="This field is generated based on Each Member's data">
                                <Box>
                                  <FaInfoCircle opacity={0.5} />
                                </Box>
                              </Tooltip>
                            </Box>
                          )}
                        </Flex>

                        <Text
                          dangerouslySetInnerHTML={{
                            __html: processedString(iOSAdditionalField.Value),
                          }}
                        ></Text>
                      </Box>
                    </Flex>
                  )
                )}
                <Divider my="10px" />

                {/* static values */}
                {/* <Tooltip label="Pass Expiry will be generated automatically based on the member record, and cannot be edited here.">
                  <Box cursor="not-allowed">
                    <Flex
                      flexDir="column"
                      my="10px"
                      p="5px"
                      alignItems="start"
                      fontSize="14px"
                      lineHeight="18px"
                    >
                      <Text mb="4px" fontWeight="bold" flex="1">
                        Pass Expiry
                      </Text>
                      <Text>2024-04-23T09:08:38 (UTC)</Text>
                    </Flex>
                  </Box>
                </Tooltip> */}

                <Box p="4px 4px">
                  <Flex alignItems="center" justifyContent="center" my="10px">
                    <Text fontStyle="italic">Links</Text>
                    <IconButton
                      variant="unstyled"
                      icon={
                        <FaPlusCircle color="var(--chakra-colors-cherryButton-500)" />
                      }
                      ml="10px"
                      title="Add New Link"
                      onClick={() => addAdditionalLinkGoogle()}
                      aria-label="Add"
                    />
                  </Flex>
                </Box>

                {/* dynamic values */}
                {config.data.ProcessedGooglePassLinks.filter((f: any) => f).map(
                  (iOSAdditionalField: any) =>
                    iOSAdditionalField.isDivider ? (
                      <Divider my="10px" />
                    ) : (
                      <Flex
                        key={iOSAdditionalField.Label}
                        flexDir="row"
                        cursor="pointer"
                        my="10px"
                        p="5px"
                        alignItems="start"
                        fontSize="14px"
                        lineHeight="18px"
                        _hover={EditableHoverStyle}
                        onClick={() =>
                          onSelectProps({
                            selectedField: "ProcessedGooglePassLinks",
                            fieldObject: iOSAdditionalField,
                            selectedFieldTitle: "Field Details",
                            canDelete: true,
                            labelKey: "Description",
                            valueKey: "UriValue",
                            isTokensForbidden: true,
                          })
                        }
                      >
                        {/* <a href={iOSAdditionalField.UriValue} target="_blank"> */}
                        <Box
                          me="10px"
                          fontSize="18px"
                          as={
                            iOSAdditionalField.UriValue.indexOf("tel:") > -1
                              ? PhoneIcon
                              : iOSAdditionalField.UriValue.indexOf("mailto:") >
                                -1
                              ? FaEnvelope
                              : FiGlobe
                          }
                        ></Box>
                        <Text
                          flex="1"
                          dangerouslySetInnerHTML={{
                            __html: processedString(
                              iOSAdditionalField.Description
                            ),
                          }}
                        ></Text>
                        {/* </a> */}
                        {/* <Text
                          dangerouslySetInnerHTML={{
                            __html: processedString(
                              iOSAdditionalField.UriValue
                            ),
                          }}
                        ></Text> */}
                      </Flex>
                    )
                )}
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
      {/* <Box>{style.toUpperCase()} Wallet Card Front / Details</Box> */}
    </Box>
  );
};
