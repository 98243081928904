import React, { useMemo } from "react";
import { Box } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import {
  GroupBase,
  MultiValue,
  Props,
  Select,
  SingleValue,
  useChakraSelectProps,
} from "chakra-react-select";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { cloneDeep } from "lodash";
import { SelectProps } from "@chakra-ui/select";

interface SelectFieldProps extends BaseFieldProps {
  options: { label: string; value: string | null }[];
  allowEmpty?: boolean;
}

export const ChakraReactSelectField = ({
  label,
  name,
  id,
  options,
  allowEmpty,
  helperText,
  isLoading,
  isMulti,
  ...inputProps
}: SelectFieldProps & Props & SelectProps) => {
  interface Option {
    value: string | null;
    label: string;
  }
  type OptionList = Array<Option>;
  const mergedOptions = (currentValue: any) => {
    if (isMulti == null || !isMulti) return mergeSingleOptions(currentValue);
    return mergeMultipleOptions(currentValue);
  };

  const mergeSingleOptions = (currentValue: string) => {
    return useMemo(() => {
      const combinedOptions: OptionList = [];
      if (allowEmpty) combinedOptions.push({ value: null, label: "-" });
      if (
        !!currentValue &&
        !options.find(({ value }) => value === currentValue)
      ) {
        return combinedOptions.concat(options).concat([
          {
            value: currentValue!,
            label: `${currentValue!} (deprecated)`,
          },
        ]);
      } else {
        return combinedOptions.concat(options);
      }
    }, [options]);
  };

  const mergeMultipleOptions = (currentValues: string[] | null) => {
    return useMemo(() => {
      if (
        currentValues === undefined ||
        currentValues === null ||
        !currentValues.length
      )
        return options;
      let result = cloneDeep(options);
      currentValues.forEach((currValue) => {
        if (!!currValue && !options.find(({ value }) => value === currValue)) {
          result = result.concat([
            {
              value: currValue!,
              label: `${currValue!} (deprecated)`,
            },
          ]);
        }
      });
      return result;
    }, [options]);
  };

  const selectProps = useChakraSelectProps(inputProps);

  return (
    <>
      {(isLoading && (
        <Box width="100%">
          <Skeleton height="24px" mt={4} />
          <Skeleton height="40px" mt={2} />
        </Box>
      )) || (
        <FieldWrapper name={name} id={id} label={label} helperText={helperText}>
          {({ field, form }) => (
            <Select<any, any, GroupBase<any>>
              {...selectProps}
              value={
                field.value && isMulti
                  ? mergedOptions(field.value).filter(
                      ({ value }) =>
                        value ==
                        (field.value as string[]).find(
                          (currValue) => currValue == value
                        )
                    )
                  : mergedOptions(field.value).find(
                      ({ value }) => value == field.value
                    )
              }
              isMulti={isMulti}
              placeholder={helperText}
              options={mergedOptions(field.value)}
              onChange={(newValue) => {
                return form.setFieldValue(
                  field.name,
                  isMulti
                    ? (newValue as MultiValue<Option>).map(
                        (value) => value.value
                      )
                    : (newValue as SingleValue<Option>)?.value
                );
              }}
            />
          )}
        </FieldWrapper>
      )}
    </>
  );
};
