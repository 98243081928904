import {
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/input";
import React from "react";
import { CheckIcon } from "../../../styles/icons";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface TextFieldProps extends BaseFieldProps {
  isValid?: boolean;
}

export const TextField = ({
  name,
  label,
  id,
  helperText,
  isValid,
  infoText,
  ...inputProps
}: TextFieldProps & InputProps) => {
  return (
    <FieldWrapper
      name={name}
      id={id}
      label={label}
      helperText={helperText}
      infoText={infoText}
    >
      {({ field }) => {
        return (
          <InputGroup backgroundColor="#fff" shadow="sm">
            <Input {...field} {...inputProps} />
            {isValid && (
              <InputRightElement children={<CheckIcon color="green.400" />} />
            )}
          </InputGroup>
        );
      }}
    </FieldWrapper>
  );
};
