import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Heading,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { useApiClient } from "../../hooks/useApiClient";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useToast } from "../../hooks/useToast";
import DateUtil from "../../util/DateUtil";

export const CherryPayDisbursementAccTransactions = () => {
  const business = useBusinessContext();
  const [reload, setReload] = useState<number>(0);
  const { userBusinessId } = useUserContext();
  const { showErrorToast, showSuccessToast } = useToast();

  const api = useApiClient();
  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getDisbursementAccTransactionsReport(business.BusinessId),
    [business, reload]
  );

  const run = () => {
    setReload(reload + 1);
  };

  const downloadCsv = async () => {
    const qresult = api.getDisbursementAccTransactionReportCsv(
      business.BusinessId
    );

    qresult.then((result) => {
      if (result.ok) {
        const blobData = new Blob([result.data]);
        const url = URL.createObjectURL(blobData);
        const link = document.createElement("a");
        const formattedDate = DateUtil.getFormattedDownloadDate();
        link.download = `cherrypay-disbursement-account-transactions-${formattedDate}.csv`;
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (result.statusCode == 404) showErrorToast(result.message);
      else {
        showErrorToast(`Failed to download the report : ${result.message}`);
      }
    });
  };

  const reportRows = useMemo(
    () =>
      report?.Results.map((data) => {
        const occurredAt = DateUtil.getLocalDateFormatFromString(
          data.OccurredAt
        );
        const amount = data.Amount?.toLocaleString("en-AU", {
          style: "currency",
          currency: data.AmountCurrency ?? "AUD",
        });
        const runningBalanceAmount = data.RunningBalanceAmount?.toLocaleString(
          "en-AU",
          {
            style: "currency",
            currency: data.RunningBalanceAmountCurrency ?? "AUD",
          }
        );
        const dataType = `Type: ${data.Type}`;
        return (
          <Tr key={data.TransactionId}>
            <Td>{data.AccountId}</Td>
            <Td>{data.TransactionId}</Td>
            <Td>{occurredAt}</Td>
            <Td>
              <Tooltip label={dataType}>{data.TypeDescription}</Tooltip>
            </Td>
            <Td>{amount}</Td>
            <Td>{runningBalanceAmount}</Td>
            <Td>{data.Reference}</Td>
          </Tr>
        );
      }),
    [report]
  );

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/reports`}
            >
              Reports
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>
          CherryPay Disbursement Account Transactions Report
        </PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack align="start" width="100%">
          <Card>
            <HStack>
              <Button colorScheme="cherryButton" onClick={run}>
                Run
              </Button>
              <Button onClick={downloadCsv}>Download</Button>
            </HStack>
          </Card>
          <Card>
            {reportLoading && (
              <Center>
                <Spinner margin="4" />
              </Center>
            )}
            {!reportError && (
              <Table width="100%" size="sm">
                <Thead>
                  <Tr>
                    <Td>Account ID</Td>
                    <Td>Transaction ID</Td>
                    <Td>Date</Td>
                    <Td>Description</Td>
                    <Td>Amount</Td>
                    <Td>Running Balance</Td>
                    <Td>Reference</Td>
                  </Tr>
                </Thead>
                <Tbody>{reportRows}</Tbody>
              </Table>
            )}
            {reportError && (
              <ErrorMessage>
                An error was encountered while getting report. {reportError}
              </ErrorMessage>
            )}
          </Card>
        </VStack>
      </PageContent>
    </>
  );
};
