import React, { useMemo } from "react";

import { Box, Text } from "@chakra-ui/layout";
import { CherryPayApi } from "../../api/models";
import {
  IconButton,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
  Tag,
  Wrap,
} from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/menu";
import { CircleIcon, MoreHorizontal } from "../../styles/icons";
import { useLayoutContext } from "../../context/LayoutContext";
interface RolesTableProps {
  roles: CherryPayApi.Role[];
  availablePermissions: CherryPayApi.Permission[];
  onClickRemove: (role: CherryPayApi.Role) => void;
  onClickEdit: (role: CherryPayApi.Role) => void;
}

export const RolesTable = ({
  roles,
  availablePermissions,
  onClickEdit,
  onClickRemove,
}: RolesTableProps) => {
  const { isDesktop } = useLayoutContext();

  const groupedPermissions = useMemo(() => {
    const groups = {
      administration: {
        label: "Administration",
        permissions: [] as CherryPayApi.Permission[],
      },
      members: {
        label: "Members",
        permissions: [] as CherryPayApi.Permission[],
      },
      memberInvitations: {
        label: "Member Invitations",
        permissions: [] as CherryPayApi.Permission[],
      },
      reports: {
        label: "Reports",
        permissions: [] as CherryPayApi.Permission[],
      },
      other: {
        label: "Other",
        permissions: [] as CherryPayApi.Permission[],
      },
    };

    availablePermissions.forEach((permission) => {
      const name = permission.Name.toLowerCase();
      if (name.startsWith("member.invite")) {
        groups.memberInvitations.permissions.push(permission);
      } else if (name.startsWith("member.")) {
        groups.members.permissions.push(permission);
      } else if (name.startsWith("reporting.")) {
        groups.reports.permissions.push(permission);
      } else if (name.endsWith(".management")) {
        groups.administration.permissions.push(permission);
      } else {
        groups.other.permissions.push(permission);
      }
    });

    return [
      groups.members,
      groups.memberInvitations,
      groups.administration,
      groups.reports,
      groups.other,
    ].filter((group) => group.permissions.length > 0);
  }, [availablePermissions]);

  const permissionsByName = availablePermissions.reduce<{
    [key: string]: CherryPayApi.Permission;
  }>((obj, item) => {
    obj[item.Name] = item;
    return obj;
  }, {});

  const roleItems = useMemo(
    () =>
      roles.map((role) => ({
        role,
        permissions: role.GrantedPermissions.map(
          (permissionId) => permissionsByName[permissionId].DisplayName
        ),
        permissionSet: new Set(role.GrantedPermissions),
        onClickEdit: () => onClickEdit(role),
        onClickRemove: () => onClickRemove(role),
      })),
    [roles]
  );

  return (
    <Box w="100%" overflowX="auto">
      <Table size="sm" textAlign={"center"}>
        <Thead>
          <Tr>
            <Th></Th>
            {roleItems.map(({ role }) => (
              <Th
                textAlign={"center"}
                key={role.Name}
                wordBreak="keep-all"
                whiteSpace="nowrap"
                minW="120px"
              >
                {role.Name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {groupedPermissions.map(({ label, permissions }, i) => (
            <React.Fragment key={`group-${label}`}>
              <Tr>
                <Th
                  backgroundColor="white"
                  borderBottom="none"
                  textTransform="none"
                  fontSize="xs"
                  lineHeight="2"
                >
                  <Text paddingTop={i === 0 ? undefined : "4"}>{label}</Text>
                </Th>
                {roleItems.map((role) => (
                  <Td
                    key={role.role.Name}
                    borderBottom="none"
                    borderLeft="1px solid #efefef"
                  />
                ))}
              </Tr>
              {permissions.map((permission) => (
                <Tr key={permission.Name}>
                  <Th
                    paddingLeft="8"
                    width="0.1%"
                    whiteSpace="nowrap"
                    backgroundColor="white"
                    borderBottom="none"
                    textTransform="none"
                    fontWeight="400"
                    fontSize="xs"
                    lineHeight="2"
                  >
                    {permission.DisplayName}
                  </Th>
                  {roleItems.map((role, i) => (
                    <Td
                      textAlign={"center"}
                      key={`${permission.Name}-${role.role.Name}`}
                      borderBottom="none"
                      borderLeft="1px solid #efefef"
                    >
                      {role.permissionSet.has(permission.Name) ? (
                        <CircleIcon color={"cherryUi.600"} boxSize="10px" />
                      ) : (
                        ""
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </React.Fragment>
          ))}

          <Tr>
            <Th backgroundColor="white" borderBottom="none"></Th>
            {roleItems.map(({ role, onClickEdit, onClickRemove }) => (
              <Td
                key={role.Name}
                textAlign="center"
                borderBottom="none"
                borderLeft="1px solid #efefef"
              >
                <Menu>
                  <MenuButton
                    icon={<MoreHorizontal />}
                    variant="ghost"
                    as={IconButton}
                    fontSize="sm"
                  ></MenuButton>
                  <MenuList>
                    <MenuItem onClick={onClickEdit}>
                      {role.IsApplicationDefault ? "View role" : "Edit role"}
                    </MenuItem>
                    {!role.IsApplicationDefault && (
                      <MenuItem
                        disabled={role.IsApplicationDefault}
                        onClick={onClickRemove}
                      >
                        Remove role
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};
