import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import cherryLogo from "assets/cherryplay_logo.svg";
import { Image } from "@chakra-ui/image";
import { useLayoutContext } from "../../context/LayoutContext";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";

import {
  Button,
  Checkbox,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { CogIcon } from "../../styles/icons";
import {
  FaArrowDown,
  FaArrowUp,
  FaCog,
  FaPalette,
  FaTrash,
} from "react-icons/fa";
import { arrayMove, convertARGBtoHex } from "../../util/passHelper";
import { PassConfig } from "../../pages/PassEditor/PassEditor";
import { TextField } from "../fields/TextField/TextField";
import { useApiClient } from "../../hooks/useApiClient";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks/useToast";

interface DetailProps {
  selectedField: string;
  selectedFieldTitle?: string;
  title?: string;
  fieldObject?: any;
  canDelete?: boolean;
  isSingleValue?: boolean;
  isDateFormatAvailable?: boolean;
  isTokensForbidden?: boolean;
  labelKey?: string;
  valueKey?: string;
}

interface RhsEditorParams {
  onClose: () => void;
  detailProps: DetailProps;
  config: PassConfig;
  reloadPass: (skipImages: boolean) => void;
  tokens?: any;
  unprocessPassConfig: (config: PassConfig) => PassConfig;
}

const AutoCompleteItem = (props: any) => {
  const { entity } = props;
  return <div>{`${entity}`}</div>;
};

export const RhsEditor = (props: RhsEditorParams) => {
  const {
    detailProps,
    onClose,
    config,
    reloadPass,
    tokens,
    unprocessPassConfig,
  } = props;
  const [label, setLabel] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [isDate, setIsDate] = useState<boolean>(false);
  const [dateFormat, setDateFormat] = useState<string>('MM"/"yy');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const apiClient = useApiClient();
  const { businessId, passConfigurationId } = useParams();
  const { showErrorToast, showSuccessToast } = useToast();

  const labelKey = detailProps?.labelKey ?? "Label";
  const valueKey = detailProps?.valueKey ?? "Value";

  const handleChange = (fieldName: string) => (e: ChangeEvent<any>) => {
    const field = config.data[detailProps.selectedField];
    if (fieldName === "label") {
      setLabel(e.target.value.replace(/\n/g, ""));
    }
    if (fieldName === "value") {
      setValue(e.target.value);
    }
    if (fieldName === "isDate") {
      setIsDate(e.target.checked);
    }
    if (fieldName === "dateFormat") {
      setDateFormat(e.target.value);
    }
  };

  useEffect(() => {
    if (detailProps?.selectedField) {
      const fieldParent = config.data[detailProps.selectedField];

      if (fieldParent.constructor.name === "Array" && detailProps.fieldObject) {
        const fieldItem = fieldParent.find(
          (f: any) => f === detailProps.fieldObject
        );
        setLabel(fieldItem[labelKey]);
        setValue(fieldItem[valueKey]);
      } else if (detailProps.isSingleValue) {
        // setLabel(fieldParent.Label);
        setValue(fieldParent);
      } else {
        setLabel(fieldParent[labelKey]);
        setValue(fieldParent[valueKey]);
      }

      if (detailProps?.fieldObject?.CustomDateFormat) {
        setIsDate(true);
        setDateFormat(detailProps?.fieldObject?.CustomDateFormat);
      }
    }
  }, [detailProps]);

  const doUpdate = async (updatedData: any) => {
    setIsLoading(true);
    const updatedPassConfig: PassConfig = {
      data: updatedData,
      passImages: config.passImages,
    };
    const newData = unprocessPassConfig(updatedPassConfig).data;
    const configData = await apiClient.updatePassConfig(
      businessId!,
      passConfigurationId!,
      newData
    );
    showSuccessToast("Pass updated");
    setIsLoading(false);
    reloadPass(true);
  };

  const removeAdditionalField = async () => {
    setIsDeleting(false);
    const fieldParent = config.data[detailProps.selectedField];

    if (fieldParent.constructor.name === "Array" && detailProps.fieldObject) {
      const updatedArray = fieldParent.filter(
        (f: any) => f !== detailProps.fieldObject
      );
      config.data[detailProps.selectedField] = updatedArray;

      const updatedData = {
        ...config.data,
        [detailProps.selectedField]: updatedArray,
      };

      await doUpdate(updatedData);
      onClose();
    }
  };

  const moveAdditionalField = useCallback(
    async (direction: string) => {
      const arr: any[] = config.data[detailProps.selectedField];
      const oldIdx = arr.indexOf(detailProps.fieldObject);
      const newIdx = direction === "up" ? oldIdx - 1 : oldIdx + 1;
      const updatedArray = arrayMove(arr, oldIdx, newIdx);
      const updatedData = {
        ...config.data,
        [detailProps.selectedField]: updatedArray,
      };

      await doUpdate(updatedData);
      onClose();
    },
    [config]
  );

  const save = async () => {
    let updatedData: any = {};

    const fieldParent = config.data[detailProps.selectedField];
    let shouldCloseAfterSave = false;

    // if it is amongst the array of fields
    if (fieldParent.constructor.name === "Array" && detailProps.fieldObject) {
      const updatedArray = [...fieldParent];
      const singleItem = updatedArray.find(
        (f: any) => f === detailProps.fieldObject
      );
      singleItem[labelKey] = label;
      singleItem[valueKey] = value;
      singleItem.CustomDateFormat =
        detailProps?.isDateFormatAvailable && isDate ? dateFormat : null;

      updatedData = {
        ...config.data,
        [detailProps.selectedField]: updatedArray,
      };
      shouldCloseAfterSave = true;

      // if it is a single value - like iOSLogoText
    } else if (detailProps.isSingleValue) {
      updatedData = {
        ...config.data,
        [detailProps.selectedField]: value,
      };
      shouldCloseAfterSave = true;

      // if it is a root property of field/value type
    } else {
      updatedData = {
        ...config.data,
        [detailProps.selectedField]: {
          [labelKey]: label,
          [valueKey]: value,
          CustomDateFormat:
            detailProps?.isDateFormatAvailable && isDate ? dateFormat : null,
        },
      };
    }
    await doUpdate(updatedData);
    if (shouldCloseAfterSave) {
      onClose();
    }
  };

  if (!detailProps) {
    return <Box></Box>;
  }

  return (
    <Box>
      <Flex p="11px" borderBottom="1px solid #ddd" alignItems="center">
        <Flex flex="1" alignItems="center">
          <Box>{detailProps?.selectedFieldTitle || "Details"}</Box>
        </Flex>

        {detailProps.canDelete && (
          <IconButton
            variant="unstyled"
            icon={<FaArrowUp color="var(--chakra-colors-cherryButton-500)" />}
            title="Move Up"
            onClick={() => moveAdditionalField("up")}
            aria-label="Move Up"
            me="10px"
            _focus={{ outline: 0 }}
          />
        )}
        {detailProps.canDelete && (
          <IconButton
            variant="unstyled"
            icon={<FaArrowDown color="var(--chakra-colors-cherryButton-500)" />}
            title="Move Up"
            onClick={() => moveAdditionalField("down")}
            aria-label="Move Up"
            me="10px"
            _focus={{ outline: 0 }}
          />
        )}
        {detailProps.canDelete && (
          <IconButton
            variant="unstyled"
            icon={<FaTrash color="var(--chakra-colors-cherryButton-500)" />}
            title="Remove"
            onClick={() => setIsDeleting(true)}
            aria-label="Remove"
            me="10px"
            _focus={{ outline: 0 }}
          />
        )}
        <CloseButton fontSize="12px" onClick={() => onClose()} />
      </Flex>

      {isDeleting && (
        <Box p="20px" bg="rgba(255,0,0,0.1)">
          <Text>Are you sure you want to remove this field?</Text>
          <Flex mt="20px">
            <Button
              mr="10px"
              colorScheme="cherryButton"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => removeAdditionalField()}
            >
              Remove
            </Button>

            <Button
              variant="unstyled"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => setIsDeleting(false)}
            >
              Cancel
            </Button>
          </Flex>
        </Box>
      )}

      <Box p="20px">
        {!detailProps.isSingleValue && (
          <FormControl mb="20px">
            <FormLabel mb="2px" fontSize="15px">
              {labelKey}
            </FormLabel>

            <ReactTextareaAutocomplete
              className="my-textarea1"
              rows={1}
              style={{
                height: "44px",
                fontSize: "14px",
                padding: "10px 15px",
                border: "1px solid var(--chakra-colors-gray-300)",
                borderRadius: "var(--chakra-radii-md)",
              }}
              onChange={handleChange("label")}
              loadingComponent={() => <span>Loading</span>}
              minChar={0}
              value={label}
              trigger={{
                "{": {
                  afterWhitespace: false,
                  dataProvider: (token) => {
                    return !detailProps.isTokensForbidden && tokens
                      ? Object.keys(tokens)
                      : [];
                    // [
                    //   { name: "smile", char: "🙂" },
                    //   { name: "heart", char: "❤️" },
                    // ];
                  },
                  component: AutoCompleteItem,
                  output: (item, trigger) => item.toString(),
                },
              }}
            />
          </FormControl>
        )}

        <FormControl mb="20px">
          <FormLabel mb="2px" fontSize="15px">
            {valueKey === "UriValue" ? "URI" : valueKey}
          </FormLabel>

          <ReactTextareaAutocomplete
            className="my-textarea2"
            // onChange={(e) => console.log(e.target.value)}
            style={{
              height: "100px",
              fontSize: "14px",
              padding: "10px 15px",
              border: "1px solid var(--chakra-colors-gray-300)",
              borderRadius: "var(--chakra-radii-md)",
            }}
            onChange={handleChange("value")}
            loadingComponent={() => <span>Loading</span>}
            minChar={0}
            value={value}
            trigger={{
              "{": {
                dataProvider: (token) => {
                  return !detailProps.isTokensForbidden && tokens
                    ? Object.keys(tokens)
                    : [];
                  // [
                  //   { name: "smile", char: "🙂" },
                  //   { name: "heart", char: "❤️" },
                  // ];
                },
                component: AutoCompleteItem,
                output: (item, trigger) => item.toString(),
              },
            }}
          />

          {valueKey === "UriValue" && (
            <FormHelperText>
              <strong>Supported formats:</strong>
              <br />
              mailto:xyz@abc.com
              <br />
              tel:+61000000000
              <br />
              https://www.example.com
            </FormHelperText>
          )}
        </FormControl>

        {detailProps?.isDateFormatAvailable && (
          <Box>
            <Text mb="5px" fontSize="15px" fontWeight="bold">
              Formatting Options
            </Text>
            <FormControl mb="20px">
              <Checkbox isChecked={isDate} onChange={handleChange("isDate")}>
                Apply date formatting
              </Checkbox>
            </FormControl>

            {isDate && (
              <FormControl mb="20px">
                <FormLabel mb="2px" fontSize="15px">
                  Date Format
                </FormLabel>

                <Input
                  onChange={handleChange("dateFormat")}
                  value={dateFormat}
                />

                <FormHelperText>
                  Note: the formatting will be ignored if there is any other
                  data in the Value field apart from a date field. The final
                  format will be applied at the time of issuing the pass, and
                  won't be visible in this view.
                </FormHelperText>
              </FormControl>
            )}
          </Box>
        )}

        <Button
          colorScheme="cherryButton"
          minW="150px"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => save()}
        >
          SAVE
        </Button>
      </Box>
      <Divider />

      <Box p="10px">
        {!detailProps.isTokensForbidden && (
          <Text>
            <strong>Fields support dynamic data:</strong>
            <br />
            Typing a curly brace {"( { )"} in the text boxes above will bring up
            a shortcut menu to insert dynamic data.
          </Text>
        )}

        {detailProps.isTokensForbidden && (
          <Text>
            <strong>Dynamic data is not supported for links.</strong>
          </Text>
        )}

        {/* <Box mt="15px">
          <Text fontSize="13px">SUPPORTED TOKENS:</Text>
        </Box>
        <Flex py="10px" flexWrap="wrap">
          {(tokens ? Object.keys(tokens) : []).map((token) => (
            <Box
              fontSize="13px"
              p="5px"
              borderRadius="10px"
              bg="rgba(232,243,253,1)"
              m="4px"
            >
              {token}
            </Box>
          ))}
        </Flex> */}
      </Box>
    </Box>
  );
};
