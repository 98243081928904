import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ApiClient, ApiResult } from "../api/apiClient";
import { useApiClient } from "./useApiClient";
import { useDidUnmount } from "./useDidUnmount";

type PaginatedApiRequestState<T> = {
  isLoading: boolean;
  isLoadingPage: boolean;
  /** List of ApiResult objects for each page that has been loaded */
  pages: ApiResult<{ Results: T[] }>[];
};

export const usePaginatedApiRequest = <TModelType>(
  fn: (apiClient: ApiClient) => Promise<ApiResult<{ Results: TModelType[] }>>,
  fnPage: (
    apiClient: ApiClient,
    continuationToken: string
  ) => Promise<ApiResult<{ Results: TModelType[] }>>,
  deps: any[]
) => {
  const didUnmount = useDidUnmount();
  const apiClient = useApiClient();

  const [refreshCount, setRefreshCount] = useState(0);
  const refresh = useCallback(
    () => setRefreshCount((count) => count + 1),
    [setRefreshCount]
  );

  const [apiState, setApiState] = useState<
    PaginatedApiRequestState<TModelType>
  >({
    isLoading: true,
    isLoadingPage: false,
    pages: [],
  });

  // Loading the first page
  useEffect(() => {
    setApiState((state) => ({ ...state, isLoading: true }));
    fn(apiClient)
      .then((result) => {
        if (didUnmount.current) {
          return;
        }
        setApiState((state) => ({
          ...state,
          isLoading: false,
          pages: [result],
        }));
      })
      .catch((error) => {
        if (didUnmount.current) {
          return;
        }
        setApiState((state) => ({
          ...state,
          isLoading: false,
          pages: [{ message: "Unknown Error", ok: false }],
        }));
      });
  }, [didUnmount, apiClient, setApiState, refreshCount, ...deps]);

  const fetchNextPage: (() => Promise<void>) | null = useMemo(() => {
    const page = apiState.pages[apiState.pages.length - 1];

    if (!page || !page.ok || !page.continuationToken) {
      return null;
    }

    return () => {
      setApiState((state) => ({ ...state, isLoadingPage: true }));
      return fnPage(apiClient, page.continuationToken!)
        .then((result) => {
          if (didUnmount.current) {
            return;
          }
          setApiState((state) => ({
            ...state,
            isLoadingPage: false,
            pages: [...state.pages, result],
          }));
        })
        .catch((error) => {
          if (didUnmount.current) {
            return;
          }
          setApiState((state) => ({
            ...state,
            isLoadingPage: false,
            pages: [...state.pages, { ok: false, message: "Unknown error." }],
          }));
        });
    };
  }, [didUnmount, apiClient, apiState.pages, setApiState]);

  const mergedData: TModelType[] = useMemo(() => {
    return apiState.pages.reduce<TModelType[]>((list, page) => {
      if (page.ok) {
        return list.concat(page.data.Results);
      }
      return list;
    }, []);
  }, [apiState.pages]);

  const error: string | null = useMemo(() => {
    const page = apiState.pages[apiState.pages.length - 1];
    if (page && !page.ok) {
      return page.message;
    } else {
      return null;
    }
  }, [apiState.pages]);

  return {
    refresh,
    items: mergedData,
    fetchNextPage,
    isLoading: apiState.isLoading,
    isLoadingPage: apiState.isLoadingPage,
    error,
  };
};
