class DateUtil {
  static getFormattedDownloadDate(): string {
    const currentDateTime = new Date();
    const day = currentDateTime.getDate().toString().padStart(2, "0");
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDateTime.getFullYear().toString();
    const hour = currentDateTime.getHours().toString().padStart(2, "0");
    const minute = currentDateTime.getMinutes().toString().padStart(2, "0");
    const second = currentDateTime.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${day}${month}${year}-${hour}${minute}${second}`;
    return formattedDate;
  }

  static setStartEndDate = (
    date: string | undefined,
    type: "start" | "end"
  ): string | undefined => {
    if (type == "start" && date) {
      return date + "T00:00:00";
    }
    if (type == "end" && date) {
      return date + "T23:59:59";
    }
    return undefined;
  };

  static setStartEndDatepicker = (
    date: string | undefined,
    type: "start" | "end"
  ): string | undefined => {
    if (!date) return undefined;
    let d = new Date(date);
    if (type === "start") {
      d.setHours(0, 0, 0, 0);
    } else {
      d.setHours(23, 59, 59, 999);
    }
    return this.convertToAtomDateTime(d);
  };

  static formatDate = (
    date: string | null | undefined,
    locales?: string | string[],
    options?: Intl.DateTimeFormatOptions | undefined
  ) => {
    if (locales === null || locales === undefined) {
      locales = "en-US";
    }
    if (options === null || options === undefined) {
      options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      };
    }
    const formatter = new Intl.DateTimeFormat(locales, options);

    const dateObj = date ? new Date(date) : undefined;
    if (dateObj === undefined) return undefined;
    const formattedDate = formatter.format(dateObj);
    return formattedDate;
  };

  static convertUtcToLocalTime = (
    utcDateTime: string | null | undefined
  ): string => {
    if (!utcDateTime) return "";

    // Check if the input date-time string contains a "Z" at the end
    if (!utcDateTime.endsWith("Z")) {
      utcDateTime += "Z";
    }

    // Convert the UTC Date object to the local browser time
    const localDate = new Date(utcDateTime.toLocaleString());

    return localDate.toString();
  };

  static padZero = (value: number): string => {
    return value.toString().padStart(2, "0");
  };

  static convertToAtomDateTime = (localDateTime: Date): string => {
    const year = localDateTime.getFullYear();
    const month = DateUtil.padZero(localDateTime.getMonth() + 1);
    const day = DateUtil.padZero(localDateTime.getDate());
    const hours = DateUtil.padZero(localDateTime.getHours());
    const minutes = DateUtil.padZero(localDateTime.getMinutes());
    const seconds = DateUtil.padZero(localDateTime.getSeconds());

    // Get the local timezone offset
    const timezoneOffsetMinutes = localDateTime.getTimezoneOffset();
    const timezoneOffsetHours = -Math.floor(timezoneOffsetMinutes / 60);
    const timezoneOffsetMinutesRemainder = Math.abs(timezoneOffsetMinutes % 60);
    const timezoneOffsetSign = timezoneOffsetHours >= 0 ? "+" : "-";
    const timezoneOffset = `${timezoneOffsetSign}${DateUtil.padZero(
      timezoneOffsetHours
    )}:${DateUtil.padZero(timezoneOffsetMinutesRemainder)}`;

    // Format the local datetime to the desired string format
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffset}`;

    return formattedDateTime;
  };

  static getLocalDateFormat = (
    utcDateTime: Date | null | undefined
  ): string => {
    if (!utcDateTime) return "";

    const formattedDate = utcDateTime.toLocaleString("en-AU", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
    return formattedDate;
  };

  static getLocalDateFormatFromString = (
    utcDateTimeString: string | null | undefined
  ): string => {
    if (!utcDateTimeString) return "";
    const date = new Date(utcDateTimeString);
    return DateUtil.getLocalDateFormat(date);
  };
}

export default DateUtil;
