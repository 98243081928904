import { Box, Collapse, HStack } from "@chakra-ui/react";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React, { useCallback, useMemo } from "react";
import { CurrencyField } from "../components/fields/CurrencyField/CurrencyField";
import { TextField } from "../components/fields/TextField/TextField";
import { FormStack } from "../components/FormStack/FormStack";
import { Button } from "@chakra-ui/button";
import { isEmpty } from "lodash-es";
import { ApiClient, ApiResult } from "../api/apiClient";
import { transformLocalAustralianMobileNumber } from "../util/transformLocalAustralianMobileNumber";
import { isLocalAustralianMobileNumber } from "../util/isLocalAustralianMobileNumber";
import { isEmptyStr } from "../util/isEmptyStr";
import { isValidEmail } from "../util/isValidEmail";
import { CherryPayApi } from "../api/models";

type CPCTypes = "instantgift" | "reloadable";

interface NonMemberInvitationFormProps {
  apiClient: ApiClient;
  businessID: string;
  cpcType: CPCTypes;
  configs: CherryPayApi.CardProgram[] | undefined;
  onCancel: () => void;
  onSuccess?: (message: string) => void;
  onFailure?: (message?: string) => void;
}

interface NonMemberInvitationFormValue {
  cpcType: CPCTypes;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailAddress: string;
  initialCardValue: number;
  offerExpiryDate?: string;
  plasticExpiryDate?: string;
  customInvitationSMSMessageBodyFormat?: string | null;
}

const InnerForm = (
  props: FormikProps<NonMemberInvitationFormValue> &
    NonMemberInvitationFormProps
) => {
  const {
    isSubmitting,
    isValid,
    cpcType,
    configs,
    onCancel,
    setFieldValue,
    values,
    errors,
  } = props;
  const onClickTransform = useCallback(() => {
    setFieldValue(
      "mobileNumber",
      transformLocalAustralianMobileNumber(values.mobileNumber),
      true
    );
  }, [setFieldValue, values.mobileNumber]);

  const canTransformNumber = useMemo(
    () => isLocalAustralianMobileNumber(values.mobileNumber),
    [values.mobileNumber]
  );

  const transformedNumber = useMemo(
    () => transformLocalAustralianMobileNumber(values.mobileNumber),
    [values.mobileNumber]
  );
  return (
    <FormStack>
      <TextField name="firstName" label="First name" placeholder="First name" />
      <TextField name="lastName" label="Last name" placeholder="Last name" />
      <TextField
        name="emailAddress"
        label="Email address"
        placeholder="Email address"
      />
      <Box width="100%">
        <TextField
          name="mobileNumber"
          label="Mobile number"
          placeholder="Mobile number"
        />
        <Collapse in={canTransformNumber} animateOpacity>
          <Button variant="link" onClick={onClickTransform} size="xs">
            Use international format: {transformedNumber}
          </Button>
        </Collapse>
      </Box>
      <CurrencyField
        name="initialCardValue"
        label="Initial balance"
        placeholder="0.00"
        aria-label="Initial balance"
      />
      <TextField
        name="offerExpiryDate"
        label="Offer expiry date"
        placeholder="MM-dd-yyyy"
        type="date"
        infoText='The recipient must activate their card by this date/time, else the
        "offer" will have expired, and it will not be possible for them to
        activate and download their card after this date.'
      />
      <TextField
        name="plasticExpiryDate"
        label="Plastic expiry date"
        placeholder="MM-dd-yyyy"
        type="date"
        infoText="The hard expiry date of the card. At this time, the card will become
        inactive and can no longer be used regardless of the available balance."
      />
      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={!isValid}
        >
          {cpcType === "instantgift" ? "Send SMS" : "Create Card"}
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const NonMemberInvitationForm = withFormik<
  NonMemberInvitationFormProps,
  NonMemberInvitationFormValue
>({
  mapPropsToValues(props) {
    const configType = props.configs?.find((item) => {
      return item.ProgramType?.toLowerCase() == props.cpcType.toLowerCase();
    });
    let initCardValue = 0;
    if (configType) {
      initCardValue = configType.MinimumInitialCardValue;
    } else {
      initCardValue = 10;
    }
    return {
      cpcType: props.cpcType,
      emailAddress: "",
      firstName: "",
      initialCardValue: initCardValue,
      lastName: "",
      mobileNumber: "",
      customInvitationSMSMessageBodyFormat: null,
      offerExpiryDate: "",
      plasticExpiryDate: "",
    };
  },
  handleSubmit: async (values, { props }) => {
    const apiClient = props.apiClient;
    values.emailAddress = values.emailAddress.toLowerCase();
    let result: ApiResult<any>;
    result = await apiClient.sendNonMemberCherryPayCardInvitation(
      props.businessID,
      values.firstName,
      values.lastName,
      values.mobileNumber,
      values.emailAddress,
      values.initialCardValue,
      props.cpcType,
      values.offerExpiryDate,
      values.plasticExpiryDate,
      values.customInvitationSMSMessageBodyFormat
    );
    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess("Invitation sent successfully");
      }
    } else {
      if (props.onFailure) {
        props.onFailure(result.message);
      }
    }
  },
  validate: (values, props) => {
    const configType = props.configs?.find((item) => {
      return item.ProgramType?.toLowerCase() == props.cpcType.toLowerCase();
    });
    let minCardValue = Number.MAX_SAFE_INTEGER;
    let maxCardValue = Number.MIN_SAFE_INTEGER;
    if (configType) {
      minCardValue = configType.MinimumInitialCardValue;
      maxCardValue = configType.MaximumInitialCardValue;
    } else {
      minCardValue = 10;
      maxCardValue = 50;
    }
    let errors: FormikErrors<NonMemberInvitationFormValue> = {};
    if (isEmptyStr(values.emailAddress)) {
      errors.emailAddress = "Email address is required.";
    }
    if (isEmptyStr(values.firstName)) {
      errors.firstName = "First name is required.";
    }
    if (isEmptyStr(values.lastName)) {
      errors.lastName = "Last name is required.";
    }
    if (isEmptyStr(values.mobileNumber)) {
      errors.mobileNumber = "Mobile number is required.";
    }
    if (
      !isEmptyStr(values.emailAddress) &&
      !isValidEmail(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address.";
    }
    if (
      !isEmpty(values.initialCardValue) &&
      values.initialCardValue > maxCardValue
    ) {
      errors.initialCardValue = `Initial balance cannot be greater than $${maxCardValue}.`;
    }
    if (
      !isEmpty(values.initialCardValue) &&
      values.initialCardValue < minCardValue
    ) {
      errors.initialCardValue = `Initial balance cannot be less than $${minCardValue}.`;
    }
    if (!isEmptyStr(values.mobileNumber)) {
      if (isLocalAustralianMobileNumber(values.mobileNumber)) {
        errors.mobileNumber = "Mobile number must include country code.";
      } else if (!/^\+[0-9]{8,13}$/.test(values.mobileNumber)) {
        errors.mobileNumber =
          "Invalid mobile number. Use full number with country code.";
      }
    }

    return errors;
  },
  validateOnBlur: false,
  validateOnChange: true,
})(InnerForm);
