import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Stat, StatLabel, StatNumber } from "@chakra-ui/stat";
import React from "react";
import { Link } from "react-router-dom";
import { CherryPayApi } from "../../api/models";
import { Card } from "../../components/Card/Card";
import { Members } from "../../components/Members/Members";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { BigNumber } from "bignumber.js";
import { usePermissions } from "../../hooks/usePermissions";

const VenueList = ({ venues }: { venues: CherryPayApi.VenueSummary[] }) => {
  return (
    <>
      {venues.map((venue) => {
        return (
          <Box width="100%" key={venue.VenueNo}>
            <HStack>
              <Stat>
                <StatLabel as="h3">Venue Name</StatLabel>
                <StatNumber color="cherryUi.600">
                  {venue.DisplayName}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Members</StatLabel>
                <StatNumber color="cherryUi.600">
                  {venue.MemberCount?.toLocaleString() ?? "-"}
                </StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Total Points Value</StatLabel>
                <StatNumber color="cherryUi.600">
                  $
                  {new BigNumber(
                    venue.TotalPointsBalanceDollarValue ?? 0
                  ).toFormat(2)}
                </StatNumber>
              </Stat>
            </HStack>
          </Box>
        );
      })}
    </>
  );
};

const BusinessSummary = ({
  business,
}: {
  business: CherryPayApi.BusinessSummary;
}) => {
  return (
    <Card w="100%">
      <VStack w="100%" spacing="4">
        {business.VenueSummaries && (
          <VenueList venues={business.VenueSummaries ?? []} />
        )}
      </VStack>
    </Card>
  );
};

export const Business = () => {
  const { userBusinessId } = useUserContext();
  const business = useBusinessContext();

  const [canViewMembers] = usePermissions("Member.Search");

  const pageTitle = canViewMembers ? "Members" : "Venues";

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>{pageTitle}</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <BusinessSummary business={business} />
        {canViewMembers && <Members businessId={business.BusinessId} />}
      </PageContent>
    </>
  );
};
