import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useCallback } from "react";
import { useRef } from "react";
import { CherryPayApi } from "../../api/models";
import { useBusinessContext } from "../../context/ModelContext";
import { NonMemberInvitationForm } from "../../forms/NonMemberInvitationForm";
import { useApiClient } from "../../hooks/useApiClient";
import { useToast } from "../../hooks/useToast";

type CPCTypes = "instantgift" | "reloadable";

interface NonMemberCardModalProps {
  cpcType: CPCTypes;
  isOpen: boolean;
  businessId: string;
  configs: CherryPayApi.CardProgram[] | undefined;
  onClose: () => void;
}

const modalTitle: { [P in CPCTypes]: string } = {
  instantgift: "New CherryPay Instant Gift Card",
  reloadable: "New CherryPay Reloadable Card",
};

export const NonMemberCardModal = ({
  businessId,
  cpcType,
  configs,
  isOpen,
  onClose,
}: NonMemberCardModalProps) => {
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const initialRef = useRef<HTMLInputElement>(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const onSuccess = useCallback(
    (message: string) => {
      showSuccessToast(message);
      onClose();
    },
    [onClose, showSuccessToast]
  );

  const onFailure = useCallback(
    (message?: string) => {
      showErrorToast(message ?? "Failed to send invitation");
    },
    [showErrorToast]
  );
  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle[cpcType]}</ModalHeader>
        <ModalBody pb="6">
          <NonMemberInvitationForm
            apiClient={apiClient}
            businessID={business.BusinessId}
            cpcType={cpcType}
            configs={configs}
            onCancel={onClose}
            onSuccess={onSuccess}
            onFailure={onFailure}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
