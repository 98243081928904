import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { CherryPayApi } from "../../api/models";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { PreviewButton } from "../../components/Utilities/PreviewButton";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import DateUtil from "../../util/DateUtil";
import { isEmptyStr } from "../../util/isEmptyStr";

export const MembershipRenewalRequest = () => {
  const business = useBusinessContext();
  const { query, debouncedQuery, onChange, isDebouncing, setQuery } =
    useSearchInputState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [renewal, setRenewal] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [rowNumber, setRowNumber] = useState(10);
  const [act, setAct] = useState(0);

  const run = () => {
    setAct((prev) => ++prev);
  };

  const {
    data: renewals,
    error: renewalsError,
    isLoading: renewalsLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getMembershipRenewalFormConfigurationSummaryItems(
        business.BusinessId
      ),
    [business]
  );

  const {
    items: membershipRenewalRequests,
    isLoading,
    isLoadingPage,
    error,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (apiClient) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipRenewalRequests(business.BusinessId, {
        includeDeleted: showDeleted,
        q: debouncedQuery,
        startDate: start,
        endDate: end,
        limit: rowNumber,
        membershipRenewalFormConfigurationId: renewal,
      });
    },
    (apiClient, continuationToken) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipRenewalRequests(business.BusinessId, {
        includeDeleted: showDeleted,
        q: debouncedQuery,
        startDate: start,
        endDate: end,
        limit: rowNumber,
        membershipRenewalFormConfigurationId: renewal,
      });
    },
    [act]
  );

  const renewalFormOptions = useMemo(
    () =>
      renewals?.map((p) => {
        return (
          <option value={p.Meta?.Id!} key={p.Meta?.Id}>
            {p.DisplayName}
          </option>
        );
      }),
    [renewals]
  );

  const requestRows = useMemo(
    () =>
      (membershipRenewalRequests.length > 0 &&
        membershipRenewalRequests?.map((item) => {
          const formattedDate = DateUtil.getLocalDateFormatFromString(
            item.RequestSubmittedDateTime
          );
          const paymentDate = DateUtil.getLocalDateFormatFromString(
            item.RenewalFeePaymentResponse?.PaymentDate
          );
          return (
            <Tr key={item.SessionId}>
              {renewals && renewals.length > 1 && (
                <Td whiteSpace="nowrap">
                  {item.MembershipRenewalFormConfigurationName}
                </Td>
              )}
              <Td whiteSpace="nowrap">{item.SessionId}</Td>
              <Td>{item.DisplayName}</Td>
              <Td>{formattedDate}</Td>
              <Td>{item.RenewalFeeFullFeeDescription}</Td>
              <Td>{item.SelectedRenewalFeeItemDescriptions}</Td>
              <Td title={item.Message ?? "-"}>
                {item.NextStep}&nbsp;
                {item.IsError && <WarningIcon color="cherryButton.500" />}
              </Td>
              <Td>
                {item.RenewalFeePaymentResponse &&
                  `${item.RenewalFeePaymentResponse.CurrencySymbol}${Number(
                    item.RenewalFeePaymentResponse
                      .DisplayTotalAmountPaidIncludingTax
                  ).toFixed(2)}`}
              </Td>
              <Td>{paymentDate}</Td>
              <Td>
                {item.RenewalFeePaymentResponse?.PaymentProviderPaymentId}
              </Td>
            </Tr>
          );
        })) || (
        <Tr>
          <Td colSpan={10} textAlign="center">
            <i>No membership renewal request</i>
          </Td>
        </Tr>
      ),
    [membershipRenewalRequests]
  );
  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={ReactRouterLink}
              to={`/businesses/${business.BusinessId}/membership-requests`}
            >
              Membership Renewal Requests
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Membership Renewal Requests</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack alignItems="start" w="100%">
          <Card w="100%">
            <Flex gridGap={6} className="form-filter-wrapper">
              <Box pt={8}>
                <SearchInput
                  value={query}
                  onChange={onChange}
                  isLoading={isLoading || isDebouncing}
                  placeholder="Search requests..."
                  setQuery={setQuery}
                />
              </Box>
              {renewals && renewals.length > 1 && (
                <Box>
                  <FormControl>
                    <FormLabel>Renewal Form</FormLabel>
                    <Select
                      placeholder="All renewal forms"
                      onChange={(e) => setRenewal(e.target.value)}
                      defaultValue={renewal}
                    >
                      {renewalFormOptions}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {renewalsLoading && (
                <Center>
                  <Spinner />
                </Center>
              )}
              {renewalsError && (
                <ErrorMessage>
                  An error was encountered while loading the program types.
                </ErrorMessage>
              )}
              <Box>
                <FormControl>
                  <FormLabel>Request Submitted from</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted from"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Request Submitted to</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted to"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel>Rows</FormLabel>
                  <Select
                    onChange={(e) => setRowNumber(parseInt(e.target.value))}
                    value={rowNumber}
                    width="20"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </Select>
                </FormControl>
              </Box>

              {/* <Box p="4" pt={12}>
              <Checkbox
                size="lg"
                onChange={() => setShowDeleted((prev) => !prev)}
                isChecked={showDeleted}
              >
                Show Deleted
              </Checkbox>
            </Box> */}
              <Box alignSelf="flex-end" className="form-filter-button-wrapper">
                <Button colorScheme="cherryButton" onClick={run}>
                  Search
                </Button>
              </Box>
            </Flex>
          </Card>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {!membershipRenewalRequests && isLoading && (
            <Center>
              <Spinner margin="4" />
            </Center>
          )}
          {membershipRenewalRequests && !isLoading && (
            <Card w="100%">
              <TableContainer overflowX="auto">
                <Table w="100%" size="sm">
                  <Thead>
                    <Tr>
                      {renewals && renewals.length > 1 && <Th>Renewal Form</Th>}
                      <Th>Session ID</Th>
                      <Th>Member Name</Th>
                      <Th>Date</Th>
                      <Th>Fee Description</Th>
                      <Th>Selected Fee Item</Th>
                      <Th>Status</Th>
                      <Th>Amount Paid</Th>
                      <Th>Payment Date</Th>
                      <Th>Payment ID</Th>
                    </Tr>
                  </Thead>
                  <Tbody>{requestRows}</Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
          {!isLoading && fetchNextPage && (
            <Card w="100%">
              {!isLoadingPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {isLoadingPage && <Spinner />}
            </Card>
          )}
        </VStack>
      </PageContent>
    </>
  );
};
