import { Alert, AlertIcon } from "@chakra-ui/alert";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { Button, IconButton } from "@chakra-ui/button";
import {
  Box,
  Center,
  Heading,
  HStack,
  LinkBox,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { CherryPayApi } from "../../api/models";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { UserModal } from "../../components/UserModal/UserModal";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useRoles } from "../../hooks/useRoles";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import { useToast } from "../../hooks/useToast";
import { EditIcon, TrashIcon, UserPlusIcon } from "../../styles/icons";

const getUserRole = (userAccount: CherryPayApi.UserAccount) => {
  const cherryPayRole = userAccount.CustomClaims["cherryPayRole"];
  if (typeof cherryPayRole === "string") {
    return cherryPayRole;
  }
  return "-";
};

const useUserModalState = () => {
  const [modalState, setModalState] = useState<{
    existingUser: CherryPayApi.UserAccount | null;
    isOpen: boolean;
  }>({ existingUser: null, isOpen: false });

  const onClose = useCallback(
    () => setModalState({ existingUser: null, isOpen: false }),
    [setModalState]
  );

  const onNewUser = useCallback(
    () => setModalState({ existingUser: null, isOpen: true }),
    [setModalState]
  );

  const onOpenUser = useCallback(
    (user: CherryPayApi.UserAccount) =>
      setModalState({ existingUser: user, isOpen: true }),
    [setModalState]
  );

  return {
    isOpen: modalState.isOpen,
    existingUser: modalState.existingUser,
    onClose,
    onNewUser,
    onOpenUser,
  };
};

export const UserList = ({
  businessId,
  onNewUser,
  onSelectUser,
  userModalIsOpen,
}: {
  businessId: string;
  onSelectUser: (user: CherryPayApi.UserAccount) => void;
  onNewUser: () => void;
  userModalIsOpen?: boolean;
}) => {
  const { query, debouncedQuery, onChange, isDebouncing, setQuery } =
    useSearchInputState("");

  const {
    items: users,
    isLoading,
    isLoadingPage,
    error,
    refresh,
    fetchNextPage,
  } = usePaginatedApiRequest(
    // Request the first page
    (apiClient) => apiClient.searchUsers(businessId, debouncedQuery),
    // Request subsequent pages
    (apiClient, continuationToken) =>
      apiClient.searchUsers(businessId, debouncedQuery, continuationToken),
    [businessId, debouncedQuery]
  );

  const { showErrorToast, showSuccessToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const deleteUserRequest = useApiUpdateRequest(
    (apiClient, userId: string, userEmail: string) =>
      apiClient.deleteUser(businessId, userId, userEmail),
    [businessId]
  );

  const onDeleteUser = useCallback(
    (user: CherryPayApi.UserAccount) => {
      showConfirmationDialog(
        "Delete User?",
        "Are you sure you wish to delete this user?",
        "Delete"
      ).then(({ ok }) => {
        if (ok) {
          deleteUserRequest.request(user.Uid, user.Email!);
        }
      });
    },
    [deleteUserRequest.request, showConfirmationDialog]
  );

  const listItems = useMemo(
    () =>
      (users ?? []).map((user) => ({
        user,
        onClickEdit: () => onSelectUser(user),
        onClickDelete: () => onDeleteUser(user),
      })),
    [onSelectUser, onDeleteUser, users, deleteUserRequest.isLoading]
  );

  useEffect(() => {
    if (deleteUserRequest.result) {
      if (deleteUserRequest.result.ok) {
        showSuccessToast("User deleted.");
        refresh();
      } else {
        showErrorToast(
          deleteUserRequest.result.message ?? "User delete failed."
        );
      }
    }
  }, [deleteUserRequest.result, refresh]);

  useEffect(() => {
    // Refresh user list after closing modal.
    if (!userModalIsOpen) {
      refresh();
    }
  }, [userModalIsOpen, refresh]);

  return (
    <Card width="100%">
      <VStack width="100%" spacing="4" alignItems="start">
        <HStack width="100%">
          <Box flex="1">
            <Box width="100%" maxWidth="300px">
              <SearchInput
                value={query}
                onChange={onChange}
                isLoading={isLoading || isDebouncing}
                placeholder="Search users..."
                setQuery={setQuery}
              />
            </Box>
          </Box>
          <Button
            leftIcon={<UserPlusIcon />}
            onClick={onNewUser}
            alignSelf="end"
            colorScheme="cherryButton"
          >
            New User
          </Button>
        </HStack>
        {!users && isLoading && (
          <Center>
            <Spinner margin="4" />
          </Center>
        )}
        {users && users.length > 0 && (
          <Box w="100%" overflowX="auto">
            <Table size="sm" minW="600px">
              <Thead>
                <Tr>
                  <Th>Email</Th>
                  <Th>Name</Th>
                  <Th>Role</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {listItems.map(({ user, onClickEdit, onClickDelete }) => (
                  <LinkBox as={Tr} key={user.Uid} transform="scale(1)">
                    <Td>{user.Email}</Td>
                    <Td>{user.DisplayName ?? "-"}</Td>
                    <Td>{getUserRole(user)}</Td>
                    <Td width="0.1%" whiteSpace="nowrap">
                      <IconButton
                        aria-label="Edit"
                        icon={<EditIcon />}
                        size="sm"
                        disabled={deleteUserRequest.isLoading}
                        onClick={onClickEdit}
                      />
                      <IconButton
                        marginLeft="1"
                        aria-label="Delete"
                        icon={<TrashIcon />}
                        size="sm"
                        disabled={deleteUserRequest.isLoading}
                        onClick={onClickDelete}
                      />
                    </Td>
                  </LinkBox>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
        {!isLoading && users && users.length === 0 && (
          <Text>No users found matching query "{debouncedQuery}"</Text>
        )}

        {fetchNextPage && (
          <Box>
            {!isLoadingPage && (
              <Button onClick={fetchNextPage}>Load more...</Button>
            )}
            {isLoadingPage && <Spinner />}
          </Box>
        )}

        {error != null && (
          <ErrorMessage>
            An error was encountered while searching members.
          </ErrorMessage>
        )}
      </VStack>
      {confirmationElement}
    </Card>
  );
};

export const Users = () => {
  const business = useBusinessContext();
  const userModal = useUserModalState();

  const { roles } = useRoles(business);

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/users`}
            >
              Users
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Users</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <UserList
          businessId={business.BusinessId}
          onNewUser={userModal.onNewUser}
          onSelectUser={userModal.onOpenUser}
          userModalIsOpen={userModal.isOpen}
        />
      </PageContent>
      <UserModal
        onClose={userModal.onClose}
        availableRoles={roles}
        existingUser={userModal.existingUser}
        isOpen={userModal.isOpen}
      />
    </>
  );
};
