import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import cherryLogo from "assets/cherryplay_logo.svg";
import { Image } from "@chakra-ui/image";
import { useLayoutContext } from "../../context/LayoutContext";
import {
  Button,
  Checkbox,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { CogIcon } from "../../styles/icons";
import {
  FaArrowDown,
  FaArrowUp,
  FaCog,
  FaFileUpload,
  FaPalette,
  FaTrash,
  FaUpload,
} from "react-icons/fa";
import {
  appleImageDimensions,
  appleImageTypes,
  arrayMove,
  convertARGBtoHex,
  convertHexToARGB,
  getPassImage,
  googleImageTypeDimensions,
  googleImageTypes,
} from "../../util/passHelper";
import { PassConfig } from "../../pages/PassEditor/PassEditor";
import { TextField } from "../fields/TextField/TextField";
import { useApiClient } from "../../hooks/useApiClient";
import { useParams } from "react-router-dom";
import { useToast } from "../../hooks/useToast";
import { ColorPicker } from "./ColorPicker";
import { FiDelete, FiTrash, FiUpload } from "react-icons/fi";

interface DetailProps {
  selectedField: string;
  selectedFieldTitle?: string;
  title?: string;
  fieldObject?: any;
  canDelete?: boolean;
}

interface ThemePanelParams {
  style: "apple" | "google";
  doClose: () => void;
  isOpen: boolean;
  config: PassConfig;
  activeTab?: number;
  reloadPass: (skipImages: boolean) => void;
  setIsSoftLoading: (isSoftLoading: boolean) => void;
  unprocessPassConfig: (config: PassConfig) => PassConfig;
}

const TabListAttributes = {
  borderBottom: "none",
  marginBottom: "2px",
  justifyContent: "center",
};

const TabPanelAttributes = {
  padding: "9px",
};

interface SelectedFile {
  key: string;
  file: File;
}

interface FileRef {
  key: string;
  input: HTMLInputElement | null;
}

export const ThemePanel = (props: ThemePanelParams) => {
  const {
    style,
    doClose,
    isOpen,
    config,
    reloadPass,
    setIsSoftLoading,
    activeTab,
    unprocessPassConfig,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>();
  const apiClient = useApiClient();
  const { businessId, passConfigurationId } = useParams();
  const { showErrorToast, showSuccessToast } = useToast();
  const styleImageTypes =
    style === "apple" ? appleImageTypes : googleImageTypes;
  const imageDimensions =
    style === "apple" ? appleImageDimensions : googleImageTypeDimensions;
  const [localData, setLocalData] = useState<any>(null);

  const fileRefs = useRef<FileRef[]>(
    Object.keys(styleImageTypes).map((imageType: string) => ({
      key: imageType,
      input: null,
    }))
  );

  const removeImage = (imageType: string) => async () => {
    if (
      !confirm(
        `Are you sure you want to remove the image: ${styleImageTypes[imageType]}?`
      )
    ) {
      return;
    }

    setIsSoftLoading(true);

    try {
      const result = await apiClient.removePassImage(
        businessId!,
        passConfigurationId!,
        imageType
      );

      console.log("result", result);
      showSuccessToast("Pass updated");
    } catch (e) {
      console.error(e);
      showErrorToast("Unknown Error");
    }

    setIsLoading(false);
    reloadPass(false);
  };

  const handleUploadClick = (imageType: string) => () => {
    // inputRef.current?.click();
    const refItem = fileRefs.current.find((ref) => ref.key === imageType);
    if (refItem) {
      refItem.input?.click();
    }
  };

  const setRef = (imageType: string) => (element: any) => {
    const currentRefIdx = fileRefs.current.findIndex(
      (ref) => ref.key === imageType
    );
    fileRefs.current[currentRefIdx] = {
      key: imageType,
      input: element,
    };
  };

  useEffect(() => {
    console.log("fileRefs", fileRefs);
  }, [fileRefs]);

  const handleFileChange =
    (imageType: string) => async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }

      const file = e.target.files[0];

      setSelectedFile({
        key: imageType,
        file,
      });

      setIsLoading(true);
      setIsSoftLoading(true);

      try {
        const result = await apiClient.savePassImage(
          businessId!,
          passConfigurationId!,
          imageType,
          file
        );

        showSuccessToast("Pass updated");
      } catch (e) {
        console.error(e);
        showErrorToast("Unknown Error");
      }

      setIsLoading(false);
      reloadPass(false);

      // 🚩 do the file upload here normally...
    };

  const canDelete = (imageType: string) => {
    return ["AppleIcon", "AppleLogo"].indexOf(imageType) === -1;
  };

  const handleChange = (fieldName: string) => (e: ChangeEvent<any>) => {};

  useEffect(() => {
    console.log("localData", localData);
  }, [localData]);

  const resetColors = async () => {
    setLocalData(null);
  };

  const checkIfDirty = () => {
    if (localData === null) return false;

    const newData = { ...localData };
    let isDirty = false;
    Object.keys(newData).map((key) => {
      const val =
        key.indexOf("Colour") === -1
          ? config.data[key]
          : convertARGBtoHex(config.data[key]);
      if (val !== newData[key]) {
        isDirty = true;
      }
    });
    return isDirty;
  };

  const saveColors = useCallback(async () => {
    const currentLocalData = { ...localData };
    [
      "BackgroundColour",
      "ForegroundColour",
      "GooglePayPassBackgroundColour",
    ].map((colorKey) => {
      if (currentLocalData[colorKey]) {
        currentLocalData[colorKey] = convertHexToARGB(
          currentLocalData[colorKey]
        );
      }
    });

    const updatedData: any = {
      ...config.data,
      ...currentLocalData,
    };

    const updatedPassConfig: PassConfig = {
      data: updatedData,
      passImages: config.passImages,
    };
    const newData = unprocessPassConfig(updatedPassConfig).data;

    setIsLoading(true);
    const configData = await apiClient.updatePassConfig(
      businessId!,
      passConfigurationId!,
      newData
    );
    showSuccessToast("Pass updated");
    setIsLoading(false);
    reloadPass(true);
    setLocalData(null);
  }, [localData]);

  const saveColor = useCallback(
    async (key: string, color: string | null, commit = false) => {
      const newData = { ...(localData || {}) };
      newData[key] = color;
      await setLocalData(newData);
      if (commit) {
        saveColors();
      }
    },
    [localData]
  );

  if (!config) {
    return <Box></Box>;
  }

  return (
    <Box
      width="300px"
      position="absolute"
      left="0px"
      top="0px"
      height="100%"
      overflowY="auto"
      bg="#ffffff"
      boxShadow="1px 3px 5px rgba(0,0,0,0.1)"
      className={isOpen ? "slideIn" : "slideOut"}
      zIndex={1}
    >
      <Flex p="11px" borderBottom="1px solid #ddd" alignItems="center">
        <Flex flex="1" alignItems="center">
          <Box as={FaPalette} fontSize="14px" mr="5px" />
          <Box>Theme</Box>
        </Flex>
        <CloseButton fontSize="12px" onClick={() => doClose()} />
      </Flex>

      <Box p="10px">
        <Tabs colorScheme="cherryUi" defaultIndex={activeTab ?? 0}>
          <TabList {...TabListAttributes}>
            <Tab _focus={{ outline: 0 }}>Colors</Tab>
            <Tab _focus={{ outline: 0 }}>Images</Tab>
          </TabList>

          <TabPanels>
            {/* colors */}
            <TabPanel {...TabPanelAttributes}>
              <Box>
                {style === "apple" && (
                  <Box mt="20px" mb="20px">
                    <Text>Background Color</Text>
                    <Box mt="5px">
                      <ColorPicker
                        initialColor={convertARGBtoHex(
                          localData && localData.BackgroundColour
                            ? localData.BackgroundColour
                            : config.data.BackgroundColour
                        )}
                        saveColor={(color: string) =>
                          saveColor("BackgroundColour", color)
                        }
                      >
                        <Box
                          borderRadius="5px"
                          border="1px solid #eee"
                          width="100%"
                          height="40px"
                          bg={
                            localData && localData.BackgroundColour
                              ? localData.BackgroundColour
                              : convertARGBtoHex(config.data.BackgroundColour)
                          }
                        ></Box>
                      </ColorPicker>
                    </Box>
                  </Box>
                )}

                {style === "apple" && (
                  <Box mt="20px" mb="20px">
                    <Text>Foreground Color</Text>
                    <Box mt="5px">
                      <ColorPicker
                        initialColor={convertARGBtoHex(
                          localData && localData.ForegroundColour
                            ? localData.ForegroundColour
                            : config.data.ForegroundColour
                        )}
                        saveColor={(color: string) =>
                          saveColor("ForegroundColour", color)
                        }
                      >
                        <Box
                          borderRadius="5px"
                          border="1px solid #eee"
                          width="100%"
                          height="40px"
                          bg={
                            localData && localData.ForegroundColour
                              ? localData.ForegroundColour
                              : convertARGBtoHex(config.data.ForegroundColour)
                          }
                        ></Box>
                      </ColorPicker>
                    </Box>
                  </Box>
                )}

                {style === "google" && (
                  <Box mt="20px" mb="20px">
                    <Text>Background Color</Text>
                    <Box mt="5px">
                      <Flex>
                        <Box flex="1">
                          <ColorPicker
                            initialColor={convertARGBtoHex(
                              localData &&
                                localData.GooglePayPassBackgroundColour
                                ? localData.GooglePayPassBackgroundColour
                                : config.data.GooglePayPassBackgroundColour
                            )}
                            saveColor={(color: string) =>
                              saveColor("GooglePayPassBackgroundColour", color)
                            }
                          >
                            <Box
                              borderRadius="5px"
                              border="1px solid #eee"
                              width="100%"
                              height="40px"
                              bg={
                                localData &&
                                localData.GooglePayPassBackgroundColour
                                  ? localData.GooglePayPassBackgroundColour
                                  : convertARGBtoHex(
                                      config.data.GooglePayPassBackgroundColour
                                    )
                              }
                            ></Box>
                          </ColorPicker>
                        </Box>
                        <Button
                          ms="10px"
                          variant="unstyled"
                          onClick={() => {
                            saveColor(
                              "GooglePayPassBackgroundColour",
                              null,
                              true
                            );
                          }}
                        >
                          Reset
                        </Button>
                      </Flex>
                    </Box>
                  </Box>
                )}

                <Divider my="10px" />

                <Flex mt="20px">
                  <Button
                    colorScheme="cherryButton"
                    minW="120px"
                    isLoading={isLoading}
                    disabled={isLoading || !checkIfDirty()}
                    onClick={() => saveColors()}
                  >
                    SAVE
                  </Button>
                  {checkIfDirty() && (
                    <Button
                      ms="10px"
                      variant="unstyled"
                      isLoading={isLoading}
                      disabled={isLoading}
                      onClick={() => resetColors()}
                    >
                      Cancel
                    </Button>
                  )}
                </Flex>
              </Box>
            </TabPanel>

            {/* images */}
            <TabPanel {...TabPanelAttributes}>
              <Box>
                {Object.keys(styleImageTypes).map((key) => (
                  <Box
                    mb="10px"
                    mt="10px"
                    borderRadius="10px"
                    bg="#fafafa"
                    padding="10px"
                    key={key}
                  >
                    <Flex>
                      <Text
                        flex="1"
                        fontSize="small"
                        fontWeight="bold"
                        mb="5px"
                      >
                        {styleImageTypes[key]}
                        {"  "}
                        <span style={{ color: "#aaa" }}>
                          {imageDimensions[key]}
                        </span>
                      </Text>

                      {getPassImage(config.passImages, key) && canDelete(key) && (
                        <Text
                          color="red"
                          textDecor="underline"
                          ms="10px"
                          onClick={removeImage(key)}
                          cursor="pointer"
                        >
                          <Box size="16px" as={FiTrash} />
                        </Text>
                      )}

                      {/* {!getPassImage(config.passImages, key) && ( */}
                      <Text
                        color="#2794eb"
                        fontWeight="bold"
                        textDecor="underline"
                        ms="10px"
                        onClick={handleUploadClick(key)}
                        cursor="pointer"
                      >
                        <Box size="16px" as={FiUpload} />
                      </Text>

                      {/* )} */}
                    </Flex>
                    <Box>
                      <input
                        type="file"
                        // ref={inputRef}
                        ref={setRef(key)}
                        onChange={handleFileChange(key)}
                        style={{ display: "none" }}
                      />

                      {getPassImage(config.passImages, key) && (
                        <Box>
                          <Box
                            cursor="pointer"
                            onClick={handleUploadClick(key)}
                          >
                            <Image
                              maxH="80px"
                              border="1px solid #ccc"
                              src={getPassImage(config.passImages, key)}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                    {/* <Box mt="5px">
                      <Text fontSize="small" fontWeight="bold">
                        Recommended Size: {imageDimensions[key]}
                      </Text>
                    </Box> */}
                  </Box>
                ))}
              </Box>

              {/* <Box>
                <Box mb="15px" mt="20px">
                  <Text mb="5px">Logo</Text>
                  <Box>
                    {getPassImage(config.passImages, "AppleLogo") && (
                      <Image
                        border="1px solid #ccc"
                        src={getPassImage(config.passImages, "AppleLogo")}
                      />
                    )}
                  </Box>
                </Box>

                <Box mb="15px">
                  <Text mb="5px">Logo 2x</Text>
                  <Box>
                    {getPassImage(config.passImages, "AppleLogo2X") && (
                      <Image
                        border="1px solid #ccc"
                        src={getPassImage(
                          config.passImages,
                          "AppleLogo2X"
                        )}
                      />
                    )}
                  </Box>
                </Box>

                <Box mb="15px">
                  <Text mb="5px">Strip</Text>
                  <Box>
                    {getPassImage(config.passImages, "AppleStrip") && (
                      <Image
                        border="1px solid #ccc"
                        src={getPassImage(
                          config.passImages,
                          "AppleStrip"
                        )}
                      />
                    )}
                  </Box>
                </Box>

                <Box mb="15px">
                  <Text mb="5px">Strip 2x</Text>
                  <Box>
                    {getPassImage(config.passImages, "AppleStrip") && (
                      <Image
                        border="1px solid #ccc"
                        src={getPassImage(
                          config.passImages,
                          "AppleStrip"
                        )}
                      />
                    )}
                  </Box>
                </Box>

                <Box mb="15px">
                  <Text mb="5px">Icon</Text>
                  <Box>
                    {getPassImage(config.passImages, "AppleIcon") && (
                      <Image
                        maxW="70px"
                        border="1px solid #ccc"
                        src={getPassImage(config.passImages, "AppleIcon")}
                      />
                    )}
                  </Box>
                </Box>

                <Box mb="15px">
                  <Text mb="5px">Icon 2x</Text>
                  <Box>
                    {getPassImage(config.passImages, "AppleIcon2X") && (
                      <Image
                        maxW="70px"
                        border="1px solid #ccc"
                        src={getPassImage(
                          config.passImages,
                          "AppleIcon2X"
                        )}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
               */}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
