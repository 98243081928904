import React from "react";
import { Box, Text, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import {
  BookIcon,
  CardIcon,
  CogIcon,
  FileIcon,
  ListIcon,
  RenewalIcon,
  UserLockIcon,
  UsersIcon,
} from "../../styles/icons";
import { Link, useParams } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { usePermissions } from "../../hooks/usePermissions";
import { IconContext } from "react-icons/lib";

interface MenuItemProps {
  title: string;
  target: string;
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const DesktopMenuItem = ({ title, target, icon }: MenuItemProps) => (
  <Box height="80px" w="100%">
    <Button
      as={Link}
      to={target}
      w="150px"
      height="76px"
      justifyContent="start"
      paddingTop="4"
      paddingBottom="4"
      paddingLeft="4"
      paddingRight="8"
      colorScheme="cherryButtonDark"
      color="#fff"
      position="absolute"
      borderRightRadius="full"
      borderLeftRadius="none"
      shadow="md"
      fontWeight="600"
    >
      <VStack as="span" w="100%">
        <Box as="span" display="block" color="cherryUi.800" fontSize="lg">
          {icon}
        </Box>
        <Text
          fontSize="sm"
          as="span"
          maxWidth="100px"
          overflowWrap="break-word"
          w="100%"
          whiteSpace="normal"
          textAlign="center"
        >
          {title}
        </Text>
      </VStack>
    </Button>
  </Box>
);

const MobileMenuItem = ({ title, target, icon }: MenuItemProps) => (
  <Button
    leftIcon={icon}
    as={Link}
    to={target}
    w="100%"
    variant="link"
    justifyContent="start"
    colorScheme="cherryButtonDark"
    color="#fff"
    padding="4"
  >
    {title}
  </Button>
);

const BusinessMenu = ({
  businessId,
  MenuItem,
}: {
  businessId: string;
  MenuItem: React.ComponentType<MenuItemProps>;
}) => {
  const [
    hasReadDetails,
    hasUserManagement,
    canSearchMembers,
    hasFinancialReportAccess,
    hasMembershipReportAccess,
    canInviteToCard,
  ] = usePermissions(
    "Member.ReadDetails",
    "User.Management",
    "Member.Search",
    "Reporting.Financial",
    "Reporting.Membership",
    "Member.InviteToCard"
  );

  const businessSummaryTitle = canSearchMembers ? "Members" : "Venues";

  return (
    <>
      {hasReadDetails && (
        <MenuItem
          target={`/businesses/${businessId}`}
          title={businessSummaryTitle}
          icon={<UsersIcon boxSize="20px" />}
        />
      )}
      {hasUserManagement && (
        <MenuItem
          target={`/businesses/${businessId}/users`}
          title="Users"
          icon={<UsersIcon boxSize="20px" />}
        />
      )}

      {hasUserManagement && (
        <MenuItem
          target={`/businesses/${businessId}/roles`}
          title="Roles"
          icon={<UserLockIcon boxSize="20px" />}
        />
      )}

      {canInviteToCard && (
        <MenuItem
          target={`/businesses/${businessId}/cards`}
          title="Cards"
          icon={<CardIcon boxSize="20px" />}
        />
      )}

      {hasMembershipReportAccess && hasFinancialReportAccess && (
        <MenuItem
          target={`/businesses/${businessId}/reports`}
          title="Reports"
          icon={<FileIcon boxSize="20px" />}
        />
      )}
      {hasMembershipReportAccess && (
        <MenuItem
          target={`businesses/${businessId}/membership-requests`}
          title="Membership"
          icon={<BookIcon />}
        />
      )}
      {hasMembershipReportAccess && (
        <MenuItem
          target={`businesses/${businessId}/membership-renewal-requests`}
          title="Renewals"
          icon={<RenewalIcon />}
        />
      )}
    </>
  );
};

export const AppMenu = ({ isDesktop }: { isDesktop: boolean }) => {
  const { businessId } = useParams();
  const { userBusinessId } = useUserContext();

  const MenuItem = isDesktop ? DesktopMenuItem : MobileMenuItem;

  return (
    <VStack
      as="nav"
      spacing="2"
      justifyContent="start"
      w="100%"
      paddingRight="2"
      paddingBottom="2"
    >
      {!userBusinessId && (
        <MenuItem
          target="/businesses"
          icon={<ListIcon boxSize="20px" />}
          title="Businesses"
        />
      )}

      {businessId && (
        <BusinessMenu businessId={businessId} MenuItem={MenuItem} />
      )}

      {businessId && !userBusinessId && (
        <MenuItem
          target={`/businesses/${businessId}/configuration`}
          icon={<CogIcon boxSize="20px" />}
          title="Business Configuration"
        />
      )}
    </VStack>
  );
};
