import React, { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/input";
import { Spinner } from "@chakra-ui/spinner";
import { InputLeftElement } from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";

export const SearchInput = (
  props: InputProps & {
    isLoading?: boolean;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
    setQuery?: React.Dispatch<React.SetStateAction<string>>;
  }
) => {
  const { isLoading, setQuery, inputRef, ...inputProps } = props;
  const clearInput = () => {
    setQuery!("");
  };

  return (
    <InputGroup backgroundColor="#fff" shadow="sm">
      <InputLeftElement children={<SearchIcon />} />
      <Input {...inputProps} ref={inputRef} />
      {(props.isLoading && <InputRightElement children={<Spinner />} />) ||
        (props.value && setQuery && (
          <InputRightElement
            children={<CloseIcon cursor="pointer" onClick={clearInput} />}
          />
        ))}
    </InputGroup>
  );
};
