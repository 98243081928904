import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useApiClient } from "../../hooks/useApiClient";
import { Card } from "../../components/Card/Card";
import { PreviewButton } from "../../components/Utilities/PreviewButton";
import DateUtil from "../../util/DateUtil";
import { useToast } from "../../hooks/useToast";

export const CherryPayPassesByMember = () => {
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const { userBusinessId } = useUserContext();
  const [filter, setFilter] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const { showErrorToast, showSuccessToast } = useToast();

  const {
    items: cards,
    isLoading: reportLoading,
    isLoadingPage: reportLoadingPage,
    error: reportError,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (apiClient) => {
      return apiClient.getPassesByMembers(business.BusinessId, {
        limit,
      });
    },
    (apiClient, continuationToken) => {
      return apiClient.getPassesByMembers(business.BusinessId, {
        limit,
        continuationToken,
      });
    },
    [business, filter]
  );

  const cardRows = useMemo(
    () =>
      cards?.map((c, cIdx) => {
        const dob = DateUtil.formatDate(c.BirthDate, undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return (
          <Tr key={cIdx}>
            <Td whiteSpace="nowrap">
              <PreviewButton
                hover={true}
                linkProps={{ target: "_self" }}
                url={`/businesses/${c.BusinessId}/members/${c.MemberId}`}
              >
                {c.MembershipNumber}
              </PreviewButton>
            </Td>
            <Td whiteSpace="nowrap">{c.FirstName}</Td>
            <Td whiteSpace="nowrap">{c.LastName}</Td>
            <Td whiteSpace="nowrap">{dob}</Td>
            <Td whiteSpace="nowrap">{c.MembershipType}</Td>
            <Td whiteSpace="nowrap">{c.MemberLevel}</Td>
            <Td whiteSpace="nowrap">{c.EmailAddress}</Td>
            <Td whiteSpace="nowrap">{c.MobileNumber}</Td>
            <Td whiteSpace="nowrap">{c.PhoneNumber}</Td>
          </Tr>
        );
      }),
    [cards]
  );

  const run = () => {
    setFilter(filter + 1);
  };

  const downloadCsv = async () => {
    const qresult = apiClient.getPassesByMembersCsv(business.BusinessId);
    qresult.then((result) => {
      if (result.ok) {
        const blobData = new Blob([result.data]);
        const url = URL.createObjectURL(blobData);
        const link = document.createElement("a");
        link.download = `cherrypay-passes-by-member-${business.BusinessId}.csv`;
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showSuccessToast("The report has been downloaded successfully");
      } else if (result.statusCode == 404) showErrorToast(result.message);
      else showErrorToast("Failed to download the report");
    });
  };

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/reports`}
            >
              Reports
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>Passes By Member Report</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack alignItems="start" width="100%">
          <Card>
            <Flex gridGap={6} className="form-filter-wrapper">
              <Box>
                <FormControl>
                  <FormLabel>Rows</FormLabel>
                  <Select
                    onChange={(e) => setLimit(parseInt(e.target.value))}
                    value={limit}
                    width="20"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </Select>
                </FormControl>
              </Box>
              <Box alignSelf="flex-end" className="form-filter-button-wrapper">
                <Button colorScheme="cherryButton" onClick={run}>
                  Run
                </Button>
                <Button marginLeft="4" onClick={downloadCsv}>
                  Download
                </Button>
              </Box>
            </Flex>
          </Card>
          <Card width="100%">
            {!cards && reportLoading && (
              <Center>
                <Spinner margin="4" />
              </Center>
            )}
            <Box width="100%">
              <TableContainer alignSelf="start" width="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th whiteSpace="nowrap">Membership Number</Th>
                      <Th whiteSpace="nowrap">First Name</Th>
                      <Th whiteSpace="nowrap">Last Name</Th>
                      <Th whiteSpace="nowrap">Birth Date</Th>
                      <Th whiteSpace="nowrap">Membership Type</Th>
                      <Th whiteSpace="nowrap">Member Level</Th>
                      <Th whiteSpace="nowrap">Email Address</Th>
                      <Th whiteSpace="nowrap">Mobile Number</Th>
                      <Th whiteSpace="nowrap">Phone Number</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {(cards?.length && cardRows) || (
                      <Tr>
                        <Td colSpan={9} fontStyle="italic" textAlign="center">
                          No passes data found
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
          {fetchNextPage && (
            <Box>
              {!reportLoadingPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {reportLoadingPage && <Spinner />}
            </Box>
          )}
          {reportError != null && (
            <ErrorMessage>
              An error was encountered while getting report. {reportError}
            </ErrorMessage>
          )}
        </VStack>
      </PageContent>
    </>
  );
};
