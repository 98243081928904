import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, LinkProps } from "@chakra-ui/react";
import { Tag, TagLabel, TagProps, TagRightIcon } from "@chakra-ui/tag";
import React, { useState } from "react";

interface PreviewButtonProps {
  children: React.ReactNode;
  url: string;
  linkProps?: LinkProps;
  adjustMargin?: boolean;
  hover?: boolean;
}

export const PreviewButton = ({
  children,
  url,
  linkProps,
  adjustMargin,
  hover,
  ...tagProps
}: PreviewButtonProps & TagProps) => {
  const [isHovering, setIsHovering] = useState<boolean>();

  return (
    <Tag
      as={Link}
      href={url}
      target="_blank"
      _hover={hover === true ? { backgroundColor: "gray.100" } : {}}
      backgroundColor={hover === true ? "transparent" : ""}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={
        adjustMargin === true
          ? { marginLeft: "-0.5rem", marginRight: "0.5rem" }
          : {}
      }
      {...tagProps}
      {...linkProps}
    >
      <TagLabel>{children}</TagLabel>
      <TagRightIcon
        as={ExternalLinkIcon}
        display={hover == true ? (isHovering ? "inline-block" : "none") : ""}
      />
    </Tag>
  );
};
