import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  VStack,
  HStack,
  Button,
  Heading,
  Text,
  Tag,
  Wrap,
} from "@chakra-ui/react";
import React, { useCallback, useRef } from "react";
import { CherryPayApi } from "../../api/models";
import { RoleForm } from "../../forms/RoleForm";

interface RoleModalProps {
  existingRole: CherryPayApi.Role | null;
  availablePermissions: CherryPayApi.Permission[];
  onUpdateRole: (role: CherryPayApi.Role) => void;
  onCreateRole: (role: CherryPayApi.Role) => void;
  isOpen: boolean;
  onClose: () => void;
}

const GrantedPermissions = ({
  permissions,
  availablePermissions,
}: {
  permissions: string[];
  availablePermissions: CherryPayApi.Permission[];
}) => {
  const permissionsByName = availablePermissions.reduce<{
    [key: string]: CherryPayApi.Permission;
  }>((obj, item) => {
    obj[item.Name] = item;
    return obj;
  }, {});

  return (
    <Wrap w="100%" as="ul">
      {permissions.map((permission) => (
        <Tag
          key={permission}
          as="li"
          title={permissionsByName[permission].Description}
        >
          {permissionsByName[permission].DisplayName}
        </Tag>
      ))}
    </Wrap>
  );
};

const Role = ({
  role,
  onClose,
  availablePermissions,
}: {
  role: CherryPayApi.Role;
  onClose: () => void;
  availablePermissions: CherryPayApi.Permission[];
}) => {
  return (
    <VStack spacing="2" w="100%">
      <Heading as="h3" fontSize="md" w="100%">
        Name
      </Heading>
      <Text w="100%">{role.Name}</Text>
      <Heading as="h3" fontSize="md" w="100%">
        Description
      </Heading>
      <Text w="100%">{role.Description}</Text>
      <Heading as="h3" fontSize="md" w="100%">
        Granted Permissions
      </Heading>
      <GrantedPermissions
        permissions={role.GrantedPermissions}
        availablePermissions={availablePermissions}
      />
      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button onClick={onClose}>Close</Button>
      </HStack>
    </VStack>
  );
};

export const RoleModal = ({
  existingRole,
  isOpen,
  onClose,
  availablePermissions,
  onUpdateRole,
  onCreateRole,
}: RoleModalProps) => {
  const initialRef = useRef<HTMLInputElement>(null);

  const onSaveRole = useCallback(
    (role: CherryPayApi.Role) => {
      if (existingRole) {
        onUpdateRole(role);
      } else {
        onCreateRole(role);
      }
      onClose();
    },
    [onUpdateRole, onCreateRole, onClose, existingRole]
  );

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{existingRole ? "Role" : "New Role"}</ModalHeader>
        <ModalBody pb="6">
          {existingRole?.IsApplicationDefault ? (
            <Role
              role={existingRole}
              availablePermissions={availablePermissions}
              onClose={onClose}
            />
          ) : (
            <RoleForm
              existingRole={existingRole}
              onSave={onSaveRole}
              onCancel={onClose}
              availablePermissions={availablePermissions}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
