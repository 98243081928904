import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link as ChLink,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { ReportSummary } from "../../components/ReportSummary/ReportSummary";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { usePermissions } from "../../hooks/usePermissions";

export const Reports = () => {
  const business = useBusinessContext();
  const { userBusinessId } = useUserContext();

  const [hasFinancialReportAccess] = usePermissions("Reporting.Financial");
  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>Reports</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Text alignSelf="start" fontWeight="bold" fontSize="2xl">
          Summary
        </Text>
        <Card width="100%" alignItems="start">
          <ReportSummary businessId={business.BusinessId} />
        </Card>
        <Text alignSelf="start" fontWeight="bold" fontSize="2xl">
          Reports
        </Text>
        <VStack width="100%" alignItems="start" bgColor="white">
          <Card>
            {hasFinancialReportAccess && (
              <>
                <ChLink
                  as={Link}
                  to={`/businesses/${business.BusinessId}/reports/passes-by-member`}
                  fontSize="xl"
                  fontWeight="bold"
                >
                  CherryPass by Member
                </ChLink>
                <Text fontSize="md">
                  A list of cherrypasses downloaded by Members
                </Text>
              </>
            )}
          </Card>
          <Card>
            {hasFinancialReportAccess && (
              <>
                <ChLink
                  as={Link}
                  to={`/businesses/${business.BusinessId}/reports/cherrypay-cards-by-member`}
                  fontSize="xl"
                  fontWeight="bold"
                >
                  CherryPay Cards by Member
                </ChLink>
                <Text fontSize="md">
                  A list of cherrypay cards issued to Members
                </Text>
              </>
            )}
          </Card>
          <Card>
            {hasFinancialReportAccess && (
              <>
                <ChLink
                  as={Link}
                  to={`/businesses/${business.BusinessId}/reports/cherrypay-disbursement-account-transactions`}
                  fontSize="xl"
                  fontWeight="bold"
                >
                  CherryPay Disbursement Account Transactions
                </ChLink>
                <Text fontSize="md">
                  A list of disbursement account transactions
                </Text>
              </>
            )}
          </Card>
          <Card>
            <>
              <ChLink
                as={Link}
                to={`/businesses/${business.BusinessId}/reports/membership-applications`}
                fontSize="xl"
                fontWeight="bold"
              >
                Membership Application
              </ChLink>
              <Text fontSize="md">
                A list of digital membership applications
              </Text>
            </>
          </Card>
          <Card>
            <>
              <ChLink
                as={Link}
                to={`/businesses/${business.BusinessId}/reports/membership-renewal-applications`}
                fontSize="xl"
                fontWeight="bold"
              >
                Membership Renewal Application
              </ChLink>
              <Text fontSize="md">
                A list of membership renewal applications
              </Text>
            </>
          </Card>
        </VStack>
      </PageContent>
    </>
  );
};
