import { Select, SelectProps } from "@chakra-ui/select";

import React, { useMemo } from "react";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface SelectFieldProps extends BaseFieldProps {
  options: { label: string; value: string }[];
  allowEmpty?: boolean;
}

const Options = ({
  options,
  allowEmpty,
  currentValue,
}: {
  currentValue: any;
  allowEmpty?: boolean;
  options: { label: string; value: string }[];
}) => {
  // Ensure the current value is always displayed in the select options, even if it is no longer a valid option.
  const mergedOptions = useMemo(() => {
    if (
      !!currentValue &&
      !options.find(({ value }) => value === currentValue)
    ) {
      return options.concat([
        {
          value: currentValue as string,
          label: `${currentValue as string} (deprecated)`,
        },
      ]);
    } else {
      return options;
    }
  }, [options, currentValue]);

  return (
    <>
      {allowEmpty && <option value=""> - </option>}
      {mergedOptions.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </>
  );
};

export const SelectField = ({
  label,
  name,
  id,
  options,
  allowEmpty,
  helperText,
  ...inputProps
}: SelectFieldProps & SelectProps) => {
  return (
    <FieldWrapper name={name} id={id} label={label} helperText={helperText}>
      {({ field }) => (
        <Select {...field} {...inputProps}>
          <Options
            allowEmpty={allowEmpty}
            options={options}
            currentValue={field.value}
          />
        </Select>
      )}
    </FieldWrapper>
  );
};
