import React, { useEffect, useState } from "react";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import cherryLogo from "assets/cherryplay_logo.svg";
import { Image } from "@chakra-ui/image";
import { useLayoutContext } from "../../context/LayoutContext";
import {
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { CogIcon } from "../../styles/icons";
import { FaCog, FaPalette } from "react-icons/fa";
import { convertARGBtoHex, getPassImage } from "../../util/passHelper";
import { RhsEditor } from "./RhsEditor";
import { PassPreview } from "./PassPreview";
import { PassConfig } from "../../pages/PassEditor/PassEditor";
import { ThemePanel } from "./ThemePanel";
import { PassSettingsPanel } from "./PassSettingsPanel";

interface PassEditorParams {
  style: "apple" | "google";
  config: PassConfig;
  reloadPass: (skipImages: boolean) => void;
  setIsSoftLoading: (isSoftLoading: boolean) => void;
  unprocessPassConfig: (config: PassConfig) => PassConfig;
  tokens: any;
}

export const GoogleWalletEditor = (props: PassEditorParams) => {
  const { isDesktop } = useLayoutContext();
  const {
    style,
    config,
    reloadPass,
    setIsSoftLoading,
    tokens,
    unprocessPassConfig,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenRhs,
    onOpen: onOpenRhs,
    onClose: onCloseRhs,
  } = useDisclosure();
  const {
    isOpen: isOpenSettings,
    onOpen: onOpenSettings,
    onClose: onCloseSettings,
  } = useDisclosure();
  const [isLhsShown, setIsLhsShown] = useState<Boolean>(false);
  const [isLhsSettingsShown, setIsLhsSettingsShown] = useState<Boolean>(false);

  const [lhsActiveTab, setLhsActiveTab] = useState<number>(0);

  const [isRhsShown, setIsRhsShown] = useState<Boolean>(false);
  const [rhsPanelProps, setRhsPanelProps] = useState<any>(null);

  const doClose = () => {
    onClose();
    setTimeout(() => {
      setIsLhsShown(false);
    }, 100);
  };

  const doOpen = (idx: number = 0) => {
    window.scrollTo(0, 0);
    setLhsActiveTab(idx);
    setIsLhsShown(true);
    onCloseSettings();
    setTimeout(() => {
      onOpen();
    }, 100);
  };

  const doCloseRhs = () => {
    onCloseRhs();
    setTimeout(() => {
      setIsRhsShown(false);
    }, 100);
  };

  const doOpenRhs = () => {
    window.scrollTo(0, 0);
    setIsRhsShown(true);
    setTimeout(() => {
      onOpenRhs();
    }, 100);
  };

  const doCloseSettings = () => {
    onCloseSettings();
    setTimeout(() => {
      setIsLhsSettingsShown(false);
    }, 100);
  };

  const doOpenSettings = (idx: number = 0) => {
    onClose();
    window.scrollTo(0, 0);

    setIsLhsSettingsShown(true);
    setTimeout(() => {
      onOpenSettings();
    }, 100);
  };

  useEffect(() => {
    if (rhsPanelProps) {
      doOpenRhs();
    } else {
      doCloseRhs();
    }
  }, [rhsPanelProps]);

  const GrayShadowContainerAttributes = {
    background: "#f3f3f3",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
  };

  return (
    <Flex flex="1" minH="900px">
      {/* Theme and settings buttons */}
      <Box mt="80px" fontSize="12px">
        <Flex
          position="sticky"
          zIndex="1"
          mb="5px"
          direction="column"
          w="70px"
          h="70px"
          alignItems="center"
          justifyContent="center"
          bg={isOpenSettings ? "#fff" : "#f3f3f3"}
          boxShadow="-2px 1px 2px rgb(0 0 0 / 10%)"
          borderTopLeftRadius="14px"
          borderBottomLeftRadius="14px"
          onClick={() => doOpenSettings()}
          cursor="pointer"
        >
          <Box as={FaCog} fontSize="14px" mb="5px" />
          <Text>Settings</Text>
        </Flex>
        <Flex
          position="sticky"
          zIndex="1"
          direction="column"
          w="70px"
          h="70px"
          alignItems="center"
          justifyContent="center"
          bg={isOpen ? "#fff" : "#f3f3f3"}
          boxShadow="-2px 1px 2px rgb(0 0 0 / 10%)"
          borderTopLeftRadius="14px"
          borderBottomLeftRadius="14px"
          onClick={() => doOpen()}
          cursor="pointer"
        >
          <Box as={FaPalette} fontSize="14px" mb="5px" />
          <Text>Theme</Text>
        </Flex>
      </Box>

      {/* Grey container */}
      <Box
        {...GrayShadowContainerAttributes}
        flex="1"
        position="relative"
        fontSize="14px"
      >
        {/* Theme panel */}
        {isLhsShown && (
          <ThemePanel
            unprocessPassConfig={unprocessPassConfig}
            activeTab={lhsActiveTab}
            style={style}
            config={config}
            isOpen={isOpen}
            doClose={doClose}
            reloadPass={reloadPass}
            setIsSoftLoading={setIsSoftLoading}
          />
        )}

        {isLhsSettingsShown && (
          <PassSettingsPanel
            unprocessPassConfig={unprocessPassConfig}
            tokens={tokens}
            activeTab={lhsActiveTab}
            style={style}
            config={config}
            isOpen={isOpenSettings}
            doClose={doCloseSettings}
            reloadPass={reloadPass}
            setIsSoftLoading={setIsSoftLoading}
          />
        )}

        {/* middle card panel */}
        <Flex p="10px" justifyContent="center">
          <Box flex="1" maxW="900px">
            <PassPreview
              openThemePanel={(idx: number) => doOpen(idx)}
              openSettingsPanel={() => doOpenSettings()}
              onSelectProps={(params) => setRhsPanelProps(params)}
              style={style}
              config={config}
              tokens={tokens}
            />
          </Box>
        </Flex>

        {/* Theme panel */}
        {isRhsShown && (
          <Box
            width="450px"
            position="absolute"
            right="0px"
            top="0px"
            height="100%"
            bg="#ffffff"
            boxShadow="1px 3px 5px rgba(0,0,0,0.1)"
            className={isOpenRhs ? "slideIn" : "slideOutToRight"}
          >
            <RhsEditor
              unprocessPassConfig={unprocessPassConfig}
              tokens={tokens}
              config={config}
              reloadPass={reloadPass}
              onClose={() => {
                setRhsPanelProps(null);
              }}
              detailProps={rhsPanelProps}
            />
          </Box>
        )}
      </Box>
    </Flex>
  );
};
