import {
  Box,
  Stack,
  VStack,
  Image,
  Divider,
  Text,
  Center,
  Spinner,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useApiRequest } from "../../hooks/useApiRequest";
import { PreviewButton } from "../Utilities/PreviewButton";
import { ViewControl } from "../ViewControl/ViewControl";

export const MemberCardList = ({
  businessId,
  memberId,
}: {
  businessId: string;
  memberId: string;
}) => {
  const { data: cardList, isLoading } = useApiRequest(
    (apiClient) => apiClient.getMemberCardList(businessId, memberId),
    [businessId, memberId]
  );

  const cardListView = useMemo(() => {
    return cardList?.map((card, cardIdx) => (
      <>
        <Stack
          w="100%"
          direction={["column", "row"]}
          p={4}
          spacing={12}
          key={cardIdx}
        >
          <Box alignSelf="center" flexShrink={1}>
            {card?.CardArtUrl && (
              <Image
                maxH="200px"
                maxW="350px"
                objectFit="contain"
                src={card?.CardArtUrl}
                borderRadius="lg"
                boxShadow="lg"
              />
            )}
          </Box>
          <Box alignSelf="center" flex={1}>
            <VStack align="start" w="100%" spacing={6}>
              <ViewControl label="External Account Id:">
                <Box flex={3}>
                  <PreviewButton
                    url={`/businesses/${businessId}/cards/${card.CardId}`}
                    linkProps={{ target: "_self" }}
                    hover={true}
                    adjustMargin
                  >
                    <Text variant="view-value">
                      {card.ExternalAccountId ?? "-"}
                    </Text>
                  </PreviewButton>
                </Box>
              </ViewControl>

              <ViewControl
                label="Card Type:"
                value={card.CardProgramType ?? "-"}
              />

              <ViewControl
                label="Program Name:"
                value={card.CardProgramDescription ?? "-"}
              />

              <ViewControl label="Status:" value={card.AccountStatus ?? "-"} />
            </VStack>
          </Box>
        </Stack>
        {cardIdx != cardList.length - 1 && (
          <Divider key={`${cardIdx}-divider`} />
        )}
      </>
    ));
  }, [cardList, isLoading]);

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (!cardList?.length) {
    return (
      <Center>
        <Text fontStyle="italic">No Cards found</Text>
      </Center>
    );
  }

  return <>{cardListView}</>;
};
