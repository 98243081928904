export const appleImageTypes: any = {
  AppleLogo: "Logo",
  AppleLogo2X: "Logo Hi-Res",
  AppleStrip: "Strip",
  AppleStrip2X: "Strip Hi-Res",
  AppleIcon: "Icon",
  AppleIcon2X: "Icon Hi-Res",
};

export const appleImageDimensions: any = {
  AppleLogo: "200x200",
  AppleLogo2X: "400x400",
  AppleStrip: "300x150",
  AppleStrip2X: "600x300",
  AppleIcon: "32x32",
  AppleIcon2X: "64x64",
};

export const googleImageTypes: any = {
  GoogleProgramLogo: "Logo",
  GoogleHeroImage: "Strip",
};

export const googleImageTypeDimensions: any = {
  GoogleProgramLogo: "300x200",
  GoogleHeroImage: "600x200",
};

export const convertHexToARGB = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        R: parseInt(result[1], 16),
        G: parseInt(result[2], 16),
        B: parseInt(result[3], 16),
        A: 255,
      }
    : null;
};

const colorToHex = (color: number) => {
  color = color || 0;
  var hexadecimal = color.toString(16);
  return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
};

export const convertARGBtoHex = (value: any) => {
  value = value || {
    R: 255,
    G: 255,
    B: 255,
    A: 255,
  };
  return "#" + colorToHex(value.R) + colorToHex(value.G) + colorToHex(value.B);
};

export const convertBufferToImage = (buffer: ArrayBuffer) => {
  const data = new Uint8Array(buffer);
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  let binary = "";
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const getProcessedStringFn = (allTokens: any) => (val: string) => {
  if (!allTokens) return val;

  let initialVal = val || "";
  const replaces: any = {
    "{Member.FirstName}": "BEN",
    "{Member.LastName}": "SMITH",
    "{Member.BadgeNumber}": "0123",
    "{Member.PointsBalance}": "120",
    "{Member.MembershipTypeDescription}": "GOLD",
    "{Member.DateFinancial}": "09/24",
  };

  // let replaces = {};
  Object.keys(allTokens).map((token) => {
    let sampleValue = allTokens[token].SampleValue;
    if (sampleValue.indexOf("0000000Z") > -1) {
      const dt = new Date(sampleValue);
      sampleValue =
        padTo2Digits(dt.getMonth()) +
        "/" +
        dt.getFullYear().toString().substring(-2, 2);
    }
    initialVal = initialVal.replace(new RegExp(token, "ig"), sampleValue);
  });
  // Object.keys(replaces).map((key) => {
  //   initialVal = initialVal.replace(new RegExp(key, "ig"), replaces[key]);
  // });
  return initialVal.replace(/\n/g, "<br>");
};

export const arrayMove = (original: any[], oIdx: number, nIdx: number) => {
  const arr = [...original];
  if (nIdx >= arr.length) {
    var k = nIdx - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(nIdx, 0, arr.splice(oIdx, 1)[0]);
  return arr;
};

export const getPassImage = (arr: any[], imageType: string) => {
  const passImage = arr.find((img) => img.imageType === imageType);
  if (passImage) {
    if (passImage.imageData.status === 404) {
      return "";
    }
    const base64 = convertBufferToImage(passImage.imageData.data);
    const imgUrl = `data:image/png;base64,${base64}`;
    return imgUrl;
  }
  return "";
};

export const isLightColor = (color: any) => {
  if (!color) {
    return true;
  }
  const r = color.R;
  const g = color.G;
  const b = color.B;

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return true;
  } else {
    return false;
  }
};
