import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useApiClient } from "../../hooks/useApiClient";
import { Card } from "../../components/Card/Card";
import DateUtil from "../../util/DateUtil";
import { WarningIcon } from "@chakra-ui/icons";
import { useToast } from "../../hooks/useToast";

export const CherryPayMembershipRenewalApplication = () => {
  const apiClient = useApiClient();
  const business = useBusinessContext();
  const { userBusinessId } = useUserContext();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [renewal, setRenewal] = useState<string>();
  const [filter, setFilter] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const { showErrorToast, showSuccessToast } = useToast();

  const {
    data: renewals,
    error: renewalsError,
    isLoading: renewalsLoading,
  } = useApiRequest(
    (apiClient) =>
      apiClient.getMembershipRenewalFormConfigurationSummaryItems(
        business.BusinessId
      ),
    [business]
  );

  const {
    items: cards,
    isLoading: reportLoading,
    isLoadingPage: reportLoadingPage,
    error: reportError,
    fetchNextPage,
  } = usePaginatedApiRequest(
    (apiClient) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipRenewalApplicationReports(
        business.BusinessId,
        {
          limit,
          membershipRenewalFormConfigurationId: renewal,
          startDate: start,
          endDate: end,
        }
      );
    },
    (apiClient, continuationToken) => {
      const start = DateUtil.setStartEndDate(startDate, "start");
      const end = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getMembershipRenewalApplicationReports(
        business.BusinessId,
        {
          limit,
          membershipRenewalFormConfigurationId: renewal,
          startDate: start,
          endDate: end,
          continuationToken: continuationToken,
        }
      );
    },
    [business, filter]
  );

  const renewalFormOptions = useMemo(
    () =>
      renewals?.map((p) => {
        return (
          <option value={p.Meta?.Id!} key={p.Meta?.Id}>
            {p.DisplayName}
          </option>
        );
      }),
    [renewals]
  );

  const cardRows = useMemo(
    () =>
      cards?.map((c) => {
        const requestSubmittedDateTime = DateUtil.getLocalDateFormatFromString(
          c.RequestSubmittedDateTime
        );
        const paymentDate = DateUtil.getLocalDateFormatFromString(
          c.PaymentDate
        );
        const dob = DateUtil.formatDate(c.BirthDate, undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return (
          <Tr key={c.SessionId}>
            <Td whiteSpace="nowrap">{c.SessionId}</Td>
            <Td whiteSpace="nowrap">{requestSubmittedDateTime}</Td>
            {renewals && renewals.length > 1 && (
              <Td whiteSpace="nowrap">
                {c.MembershipRenewalFormConfigurationName}
              </Td>
            )}
            <Td whiteSpace="nowrap">{c.FirstName}</Td>
            <Td whiteSpace="nowrap">{c.LastName}</Td>
            <Td whiteSpace="nowrap">{dob}</Td>
            <Td whiteSpace="nowrap">{c.MobileNumber}</Td>
            <Td whiteSpace="nowrap">{c.EmailAddress}</Td>
            <Td whiteSpace="nowrap" title={c.ErrorMessage ?? "-"}>
              {c.NextStep}&nbsp;
              {c.ErrorMessage && <WarningIcon color="cherryButton.500" />}
            </Td>
            <Td whiteSpace="nowrap">{c.RenewalFeeFullFeeDescription}</Td>
            <Td whiteSpace="nowrap">{c.SelectedRenewalFeeItemDescriptions}</Td>
            <Td whiteSpace="nowrap">{c.TotalAmountPaid}</Td>
            <Td whiteSpace="nowrap">{c.TotalTaxAmount}</Td>
            <Td whiteSpace="nowrap">{c.TotalAmountPaidIncludingTax}</Td>
            <Td whiteSpace="nowrap">{paymentDate}</Td>
            <Td whiteSpace="nowrap">{c.PaymentProviderPaymentId}</Td>
          </Tr>
        );
      }),
    [cards]
  );

  const run = () => {
    setFilter(filter + 1);
  };

  const downloadCsv = async () => {
    const start = DateUtil.setStartEndDate(startDate, "start");
    const end = DateUtil.setStartEndDate(endDate, "end");
    const qresult = apiClient.getMembershipRenewalApplicationReportCsv(
      business.BusinessId,
      renewal,
      start,
      end
    );

    qresult.then((result) => {
      if (result.ok) {
        const blobData = new Blob([result.data]);
        const url = URL.createObjectURL(blobData);
        const link = document.createElement("a");
        link.download = `cherrypay-membership-renewal-application-${
          business.BusinessId
        }-${renewal ?? ""}-${startDate ?? ""}-${endDate ?? ""}.csv`;
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (result.statusCode == 404) showErrorToast(result.message);
      else {
        showErrorToast("Failed to download the report");
      }
    });
  };

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/businesses/${business.BusinessId}`}>
              {business.DisplayName}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.BusinessId}/reports`}
            >
              Reports
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageHeading.Title>
          Membership Renewal Application Report
        </PageHeading.Title>
      </PageHeading>
      <PageContent>
        <VStack alignItems="start" width="100%">
          <Card>
            <Flex gridGap={6} className="form-filter-wrapper">
              {renewals && renewals.length > 1 && (
                <Box>
                  <FormControl>
                    <FormLabel>Renewal Form</FormLabel>
                    <Select
                      placeholder="All renewal forms"
                      onChange={(e) => setRenewal(e.target.value)}
                      defaultValue={renewal}
                    >
                      {renewalFormOptions}
                    </Select>
                  </FormControl>
                </Box>
              )}

              {renewalsLoading && (
                <Center>
                  <Spinner />
                </Center>
              )}

              {renewalsError && (
                <ErrorMessage>
                  An error was encountered while loading the program types.
                </ErrorMessage>
              )}

              <Box>
                <FormControl>
                  <FormLabel>Request Submitted from</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted from"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>
              </Box>

              <Box>
                <FormControl>
                  <FormLabel>Request Submitted to</FormLabel>
                  <Input
                    type="date"
                    placeholder="Request Submitted to"
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </FormControl>
              </Box>

              <Box>
                <FormControl>
                  <FormLabel>Rows</FormLabel>
                  <Select
                    onChange={(e) => setLimit(parseInt(e.target.value))}
                    value={limit}
                    width="20"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                    <option value="500">500</option>
                  </Select>
                </FormControl>
              </Box>

              <Box alignSelf="flex-end" className="form-filter-button-wrapper">
                <Button colorScheme="cherryButton" onClick={run}>
                  Run
                </Button>
                <Button marginLeft="4" onClick={downloadCsv}>
                  Download
                </Button>
              </Box>
            </Flex>
          </Card>
          <Card width="100%">
            {!cards && reportLoading && (
              <Center>
                <Spinner margin="4" />
              </Center>
            )}
            <Box width="100%">
              <TableContainer alignSelf="start" width="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th whiteSpace="nowrap">Session Id</Th>
                      <Th whiteSpace="nowrap">Request Submitted Date Time</Th>
                      {renewals && renewals.length > 1 && (
                        <Th whiteSpace="nowrap">Renewal Form</Th>
                      )}
                      <Th whiteSpace="nowrap">First Name</Th>
                      <Th whiteSpace="nowrap">Last Name</Th>
                      <Th whiteSpace="nowrap">Birth Date</Th>
                      <Th whiteSpace="nowrap">Mobile Number</Th>
                      <Th whiteSpace="nowrap">Email Address</Th>
                      <Th whiteSpace="nowrap">Status</Th>
                      <Th whiteSpace="nowrap">Fee Description</Th>
                      <Th whiteSpace="nowrap">Selected Fee Item</Th>
                      <Th whiteSpace="nowrap">Total Amount</Th>
                      <Th whiteSpace="nowrap">Tax Amount</Th>
                      <Th whiteSpace="nowrap">Total inc. Tax</Th>
                      <Th whiteSpace="nowrap">Payment Date</Th>
                      <Th whiteSpace="nowrap">Payment ID</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {(cards?.length && cardRows) || (
                      <Tr>
                        <Td colSpan={12} fontStyle="italic" textAlign="center">
                          No membership renewal application data found
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
          {fetchNextPage && (
            <Box>
              {!reportLoadingPage && (
                <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                  Load more...
                </Button>
              )}
              {reportLoadingPage && <Spinner />}
            </Box>
          )}
          {reportError != null && (
            <ErrorMessage>
              An error was encountered while getting report. {reportError}
            </ErrorMessage>
          )}
        </VStack>
      </PageContent>
    </>
  );
};
