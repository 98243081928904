import { useToast as useChakraToast } from "@chakra-ui/react";
import { useCallback } from "react";

export const useToast = () => {
  const chakraToast = useChakraToast();

  const showSuccessToast = useCallback(
    (description: string) =>
      chakraToast({
        position: "top",
        status: "success",
        duration: 1000,
        description: description,
      }),
    [chakraToast]
  );

  const showErrorToast = useCallback(
    (description: string) =>
      chakraToast({
        position: "top",
        status: "error",
        duration: 1000,
        description: description,
      }),
    [chakraToast]
  );

  return { showErrorToast, showSuccessToast };
};
